import React, { Component } from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom';
import FooterParceiros from '../../FooterParceiros';
import HeaderParceiros from '../../HeaderParceiros';
import RequestContact from '../../../RequestContact';
import ParceirosSimuladorFormPredictable from './ParceirosSimuladorFormPredictable';


class ParceirosSimuladorPredictable extends Component {


  constructor(props) {
    super(props);
    this.state = {
      listaPaises: [],
      tiposViagem: [],
      loadingState: false,
    }
  }

  componentDidMount() {
    const dev_token = 'f386ee39a5e46a13a460709d0beaf4dd0fff1d90'
    const prod_token = ''

    axios.get(`https://api.nacionalgest.pt/api/protocolos/predictable/obter_access_token/`, {
      headers:{
        'Authorization':`Token ${dev_token}`
    }
  }).then(response => {
    if (response.status === 200) {
      const predictable_token = response.data.items.token;
      sessionStorage.setItem('predictable_token', JSON.stringify(predictable_token));
      axios.get(`https://api.nacionalgest.pt/api/protocolos/predictable/obter_lista_paises/`, {
        headers:{
          'Authorization':`Token ${dev_token}`,
          'Token':`Bearer ${predictable_token}`
      }
    }).then(response => {
      const data = response.data.items;
      this.setState({listaPaises: data})
  })
      axios.get(`https://api.nacionalgest.pt/api/protocolos/predictable/obter_lista_tipos_viagem/`, {
        headers:{
          'Authorization':`Token ${dev_token}`,
          'Token':`Bearer ${predictable_token}`
      }
    }).then(response => {
      const data = response.data.items;
      this.setState({tiposViagem: data})
    })
  }},
  this.setState({loadingBrands: false}))
  .catch(err => console.log(err))
}

  render() {
    const loadingBrands = this.state.loadingBrands;
    return (
    <div>
    <div className="content-wrapper">
          <HeaderParceiros />
          {/* /header */}
          <section className="wrapper bg-light wrapper-border">
            <div className="container py-14 pt-12 pb-4">
              <h2 className="fs-18 text-uppercase text-green mb-3">SIMULADOR</h2>
              <div className="row gx-lg-8 mb-10 gy-5">
                <div className="col-lg-5">
                  <h3 className="display-5 mb-0">Seguro de Viagem Internacional Select</h3>
                </div>
                {/* /column */}
                <div className="col-lg-7">
                  <p className="lead mb-5">Um Seguro de Viagem Internacional barato e completo que lhe oferece uma proteção abrangente para a maioria dos imprevistos que possam surgir no decorrer de uma viagem. Com uma vasta gama de soluções que mais se adaptam às suas necessidades e com uma rede de coberturas abrangente e flexível, o Seguro de Viagem Select é o Seguro de Viagem internacional certo para si e para toda a família. Agora com coberturas por COVID-19, assim como pré-existências e terrorismo.</p>
                </div>
                {/* /column */}
              </div>
            </div>
          </section>
          <ParceirosSimuladorFormPredictable data={this.state} />
          <RequestContact />
            {loadingBrands === true && (
              <div className="loadingio-spinner-ellipsis-9w2r0d892j full-spinner"><div className="ldio-o8u2rr79tje">
              <div></div><div></div><div></div><div></div><div></div>
              </div></div>
            )}
        </div>
        {/* /.content-wrapper */}
        <FooterParceiros />
        <div className="progress-wrap">
          <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
          </svg>
        </div>
        </div>
  )
}
};

export default ParceirosSimuladorPredictable;
