import React from 'react';
import ReactDOM from 'react-dom';


class CoberturasAP extends React.Component {
  render() {
    return (
      <div className="row mb-6">
        <div className="col-md-12">
          <div className="pricing card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover" style={{fontSize:"14px"}}>
                  <thead className="table-light">
                    <tr>
                      <th>Coberturas</th>
                      <th style={{textAlign:'center'}}>Garantias</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Morte ou Invalidez Permanente</th>
                      <td style={{padding:'0.1rem 0.1rem', borderLeft:'1px solid rgb(222 226 230)', textAlign:'center', verticalAlign:'middle'}}>6.000,00€</td>
                    </tr>
                    <tr>
                      <th scope="row">Despesas Tratamento e Repatriamento</th>
                      <td style={{padding:'0.1rem 0.1rem', borderLeft:'1px solid rgb(222 226 230)', textAlign:'center', verticalAlign:'middle'}}>1.500,00€</td>
                    </tr>
                    <tr>
                      <th scope="row">Responsabilidade Civil Privada (25.000€)</th>
                      <td style={{padding:'0.1rem 0.1rem', borderLeft:'1px solid rgb(222 226 230)', textAlign:'center'}}><i style={{fontSize:'23px'}} className="text-green uil uil-check"></i></td>
                    </tr>
                    <tr>
                      <th scope="row">Assistência Médica em Portugal</th>
                      <td style={{padding:'0.1rem 0.1rem', borderLeft:'1px solid rgb(222 226 230)', textAlign:'center'}}><i style={{fontSize:'23px'}} className="text-green uil uil-check"></i></td>
                    </tr>
                    <tr>
                      <th scope="row">Assistência Viagem no Estrangeiro</th>
                      <td style={{padding:'0.1rem 0.1rem', borderLeft:'1px solid rgb(222 226 230)', textAlign:'center'}}><i style={{fontSize:'23px'}} className="text-green uil uil-check"></i></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="swipe-left-d-none">
                <div className="swipe-left-table">
                  <div className="indicator" style={{textAlign:'center'}}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <p className="mb-0" style={{textAlign:'center'}}>Arraste para o lado para saber mais.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CoberturasAP;
