import React, { Component } from 'react';
import Footer from './Footer';
import Header from './Header';
import RequestContact from './RequestContact';
import SimulatorForm from './SimulatorForm';


class Simulador extends Component {
  isTop = false;
  constructor(props) {
    super(props);
    this.state = {
      isTop: false,
    }
    this.onScroll = this.onScroll.bind(this);
  }

   componentDidMount() {
     document.addEventListener('scroll', () => {
       const isTop = window.scrollY > 250;
       if (isTop !== this.state.isTop) {
         this.onScroll(isTop);
       }
     });
   }

   onScroll(isTop) {
     this.setState({ isTop });
   }
   componentWillUnmount() {
    this.isTop = false;
  }


  render() {
    return (
    <div>
    <div className="content-wrapper">
          <Header />
          {this.state.isTop && (
          <div className="button-right">
            <a type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modal-03">Solicitar Contacto</a>
          </div>
          )}
          {/* /header */}
          <section className="wrapper bg-light wrapper-border">
            <div className="container py-14 pt-12 pb-4">
              <h2 className="fs-18 text-uppercase text-green mb-3">SIMULADOR</h2>
              <div className="row gx-lg-8 mb-10 gy-5">
                <div className="col-lg-5">
                  <h3 className="display-5 mb-0">Seguro Motos</h3>
                  <p className="lead mb-0  text-green pe-xxl-5">Conheça as vantagens dos dois Planos desta Solução que comercializamos a pensar em si.</p>
                </div>
                {/* /column */}
                <div className="col-lg-7">
                  <p className="lead mb-5">Com esta solução tem a possibilidade de garantir não só a Responsabilidade Civil, complementada com a Assistência em Viagem e a Proteção Jurídica, como também os danos no seu motociclo com as coberturas de Choque, Colisão e Capotamento; Incêndio, Raio e Explosão e Furto ou Roubo com ou sem franquia.</p>
                </div>
                {/* /column */}
              </div>
            </div>
          </section>
          <SimulatorForm />
          <RequestContact />
        </div>
        {/* /.content-wrapper */}
        <Footer />
        <div className="progress-wrap">
          <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
          </svg>
        </div>
        </div>
  )
}
};

export default Simulador;
