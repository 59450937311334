import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Footer from '../Footer';
import Header from '../Header';
import RequestContact from '../RequestContact';

import seguroImage from '../../assets/img/ng/particulares.jpeg';
import seguroImageEmpresa from '../../assets/img/ng/empresas.jpeg';
import {
  Link
} from "react-router-dom";

class AgradecimentoDekuple extends Component {

  render() {
  return (
    <div>
    <div className="content-wrapper">
        <Header />
          <section className="wrapper bg-gray">
  			<div className="container pt-12 pt-md-14 pb-14 pb-md-16">
  				<div className="row gy-10 gy-md-13 gy-lg-0 align-items-center">
  					<div className="col-md-8 col-lg-5 d-flex position-relative mx-auto">
  						<div className="img-blob"><img src={seguroImage} srcSet={{seguroImage} + " 2x"} alt="" /></div>
  					</div>
  					<div className="col-lg-6 offset-lg-1 col-xxl-5 text-center text-lg-start">
  						<h1 className="display-1 mb-5">Obrigado por confiar na NacionalGest há mais de 20 anos.</h1>
              <p class="lead fs-md">Entraremos em contacto brevemente para lhe entregar o vale da sua experiência!</p>
  						<div className="d-flex justify-content-center justify-content-lg-start">
  							<span><a href="https://www.nacionalgest.pt" className="btn btn-lg btn-primary me-2 rounded-0">Visitar Website</a></span>
  						</div>
  					</div>
  				</div>
  			</div>
  		</section>
      <RequestContact />
    </div>
    {/* /.content-wrapper */}
    <Footer />
    <div className="progress-wrap">
      <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
      </svg>
    </div>
    </div>
  )
}
}

export default AgradecimentoDekuple;
