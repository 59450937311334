import React from 'react';
import ReactDOM from 'react-dom';
import MoreInfo from './MoreInfo';


class CoberturasSaude extends React.Component {
  render() {
    return (
      <>
      <div className="row mb-6">
        <div className="col-md-12">
          <div className="pricing card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover" style={{fontSize:"14px"}}>
                  <thead className="table-light">
                    <tr>
                      <th>Coberturas</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Acesso à Rede<a data-bs-toggle="modal" data-bs-target="#modal1" style={{paddingLeft: '10px',fontSize: '18px'}}><i className="uil uil-info-circle"></i></a></th>
                      <td style={{padding:'0.1rem 0.1rem', verticalAlign:'middle', textAlign:'center'}}>Sem limite</td>
                    </tr>
                    <tr>
                      <th scope="row">Médico Online<a data-bs-toggle="modal" data-bs-target="#modal4" style={{paddingLeft: '10px',fontSize: '18px'}}><i className="uil uil-info-circle"></i></a></th>
                      <td style={{padding:'0.1rem 0.1rem', verticalAlign:'middle', textAlign:'center'}}>Sem limite</td>
                    </tr>
                    <tr>
                      <th scope="row">Médico ao Domicílio<a data-bs-toggle="modal" data-bs-target="#modal2" style={{paddingLeft: '10px',fontSize: '18px'}}><i className="uil uil-info-circle"></i></a></th>
                      <td style={{padding:'0.1rem 0.1rem', verticalAlign:'middle', textAlign:'center'}}>Sem limite</td>
                    </tr>
                    <tr>
                      <th scope="row">Acesso a Rede Estomatologia - Dentinet</th>
                      <td style={{padding:'0.1rem 0.1rem', verticalAlign:'middle', textAlign:'center'}}><i style={{fontSize:'23px'}} className="text-green uil uil-check"></i></td>
                    </tr>
                    <tr>
                      <th scope="row">Subsídio Diário por Internamento Hospitalar<a data-bs-toggle="modal" data-bs-target="#modal3" style={{paddingLeft: '10px',fontSize: '18px'}}><i className="uil uil-info-circle"></i></a></th>
                      <td style={{padding:'0.1rem 0.1rem', verticalAlign:'middle', textAlign:'center'}}>25€ /dia</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="swipe-left-d-none">
                <div className="swipe-left-table">
                  <div className="indicator" style={{textAlign:'center'}}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <p className="mb-0" style={{textAlign:'center'}}>Arraste para o lado para saber mais.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MoreInfo />
      </>
    )
  }
}

export default CoberturasSaude;
