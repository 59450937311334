import React from 'react';
import axios from 'axios';
import {
  Link
} from "react-router-dom";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {email: '', subscribed: false};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }
  async handleSubmit(event) {
    event.preventDefault();
    axios( {
    url: 'https://api.nacionalgest.pt/api/subscribe_newsletter/',
      method: 'POST',
      data: {
        email: this.state.email
      }
    }).then((response) => {
      if (response.status === 200) {
        this.setState({subscribed: true});
    }
    })
  }
    render() {

    const currentYear = new Date().getFullYear()
    return (
      <footer className="bg-dark text-inverse">
        <div className="container py-13 py-md-11">
          <div className="row gy-6 gy-lg-0">
            <div className="col-md-4 col-lg-3">
              <div className="widget">
                <img className="mb-4" src="https://www.nacionalgest.pt/wp-content/uploads/2018/03/nacionalgest_logo_footer.png" srcSet="https://www.nacionalgest.pt/wp-content/uploads/2018/03/nacionalgest_logo_footer.png 2x" alt="" style={{maxWidth:'165px'}}/>
                <p className="mb-4">© {currentYear} NacionalGest - Consultores de Seguros, Lda.</p>
                <nav className="nav social social-white">
                  <a href="https://www.facebook.com/nacionalgestseguros" target="_blank" rel="noreferrer"><i className="uil uil-facebook-f" /></a>
                  <a href="https://www.instagram.com/nacionalgest/" target="_blank" rel="noreferrer"><i className="uil uil-instagram" /></a>
                  <a href="https://www.linkedin.com/company/nacional-gest-consultores-de-seguros/" target="_blank" rel="noreferrer"><i className="uil uil-linkedin" /></a>
                  <a href="https://www.youtube.com/channel/UCPHK6D-5z-2A_pUqSPmlJag" target="_blank" rel="noreferrer"><i className="uil uil-youtube" /></a>
                </nav>
                {/* /.social */}
              </div>
              {/* /.widget */}
            </div>
            {/* /column */}
            <div className="col-md-4 col-lg-3">
              <div className="widget" style={{paddingTop: '38px'}}>
                <h4 className="widget-title text-white mb-3">Contactos</h4>
                <a href="mailto:geral@nacionalgest.pt">geral@nacionalgest.pt</a><br /><a href="tel:+351211341202" className="link-white">211 341 202</a>
              </div>
              {/* /.widget */}
            </div>
            {/* /column */}
            <div className="col-md-4 col-lg-3">
              <div className="widget" style={{paddingTop: '38px'}}>
                <h4 className="widget-title text-white mb-3">Informação</h4>
                <ul className="list-unstyled mb-0">
                  <li><a href="https://www.nacionalgest.pt/termos-e-condicoes/" target="_blank" ref="noreferrer">Política de Privacidade</a></li>
                  <li><a href="https://www.nacionalgest.pt/wp-content/uploads/2021/01/nacionalgest-relatorio-contas2019.pdf" target="_blank" ref="noreferrer">Informação Legal</a></li>
                  <li><Link to="/parceiros">Parceiros</Link></li>
                </ul>
              </div>
              {/* /.widget */}
            </div>
            {/* /column */}
            <div className="col-md-12 col-lg-3">
              <div className="widget" style={{paddingTop: '38px'}}>
                <h4 className="widget-title text-white mb-3">Newsletter</h4>
                {this.state.subscribed === true && (
                  <p className="mb-5">Obrigado por subscrever a nossa newsletter mensal!</p>
                )}
                {this.state.subscribed === false && (
                  <div>
                  <p className="mb-5">Receba toda a informação em primeira mão. Subscreva a nossa newsletter</p>
                  <div className="newsletter-wrapper">
                    {/* Begin Mailchimp Signup Form */}
                    <div id="mc_embed_signup2">
                      <form method="post" onSubmit={this.handleSubmit} id="mc-embedded-subscribe-form2" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                        <div id="mc_embed_signup_scroll2">
                          <div className="mc-field-group input-group form-label-group">
                            <input type="email" name="email" className="required email form-control rounded-0" placeholder="E-mail" id="mce-EMAIL2" onChange={this.handleChange} required style={{paddingLeft:'0.5rem'}}/>
                            <label htmlFor="mce-EMAIL2" style={{paddingLeft:'0.5rem'}}>E-mail</label>
                            <button type="submit" name="subscribe" id="mc-embedded-subscribe2" className="btn btn-secondary rounded-0" style={{paddingLeft:'0.5rem',paddingRight:'0.5rem'}}>Subscrever</button>
                          </div>
                          <div id="mce-responses2" className="clear">
                            <div className="response" id="mce-error-response2" style={{display: 'none'}} />
                            <div className="response" id="mce-success-response2" style={{display: 'none'}} />
                          </div>
                          <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_ddc180777a163e0f9f66ee014_4b1bcfa0bc" tabIndex={-1} defaultValue /></div>
                          <div className="clear" />
                        </div>
                      </form>
                    </div>
                    {/*End mc_embed_signup*/}
                  </div>
                  </div>
                )}

                {/* /.newsletter-wrapper */}
              </div>
              {/* /.widget */}
            </div>
            {/* /column */}
            <hr className="mt-7 mt-md-7 mb-7 text-white" />
              <div className="d-md-flex align-items-center justify-content-between">
                <p className="mb-2 mb-lg-0" style={{fontSize:'0.75rem', lineHeight:'1.3'}}><small>A <strong>NacionalGest Consultores de Seguros Lda</strong>, com sede na Avenida Dr.Francisco Sá Carneiro, Edf Satélite, Loja 4, 8125-154 Quarteira, Pessoa Coletiva nº 504939424,  com um Capital Social de €260.000,00, está registada na ASF – Autoridade de Supervisão de Seguros e Fundos de Pensões com a categoria de corretor de seguros, sob o nº 607114161, com autorização para os ramos Vida e Não Vida, desde 27/01/2007, verificável em www.asf.com.pt. As reclamações dos clientes e outras partes interessadas devem ser apresentadas junto da Autoridade de Supervisão de Seguros e Fundos de Pensões (ASF), diretamente ou através do no Livro de Reclamações, em formato físico ou disponível em https://www.livroreclamacoes.pt/inicio, ou através do e-mail reclamacoes@nacionalgest.ptEm caso de litígio pode também recorrer ao Portal do Consumidor em www.consumidor.pt. Na qualidade de corretor de seguros a NacionalGest está mandatada para celebrar contratos em nome das empresas de seguros e autorizada a receber o valor dos prémios para serem entregues às mesmas mas não assume a cobertura de riscos. A apresentação de propostas e respetivos prémios, bem como a aceitação de riscos estão sujeitos à aceitação por parte das seguradoras sendo que nenhuma apólice produz efeitos sem que o primeiro recibo se encontre pago. Todas as informações relativas aos produtos de seguros não dispensam a consulta das informações pré-contratuais e contratuais legalmente exigidas. Os conteúdos publicados neste site podem conter erros e inexatidões, não podendo a NacionalGest ser responsabilizada por esse motivo sendo que todas as informações publicadas deverão ser confirmadas com os serviços da NacionalGest.</small></p>
                {/* /.social */}
              </div>
          </div>
          {/*/.row */}
        </div>
        {/* /.container */}
      </footer>
    )

    }
  }

  export default Footer;
