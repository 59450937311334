import React from 'react';
import { useFormState } from 'react-final-form';
import axios from 'axios';
import ReactDOM from 'react-dom';
import Footer from '../Footer';
import Header from '../Header';
import RequestContact from '../RequestContact';
import { Form, Field, FormSpy } from 'react-final-form';
import {withRouter} from 'react-router-dom'
import {
  Link
} from "react-router-dom";
import teambuildingImage from '../../assets/img/teambuilding.jpeg';
import teambuildingImage1 from '../../assets/img/teambuilding1.jpeg';
import teambuildingImage2 from '../../assets/img/teambuilding2.jpeg';
import teambuildingImage3 from '../../assets/img/teambuilding3.jpeg';


const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
export const required = value => (value ? undefined : 'Campo Obrigatório')

const normalizeDate = (value) => {
if (!value) return value;

const onlyNums = value.replace(/[^\d]/g, "");
if (onlyNums.length <= 2) return onlyNums;
if (onlyNums.length <= 4) return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}`;
if (onlyNums.length <= 8) return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(4, 8)}`;

return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(4, 8)}`;
};

function Spinner() {
  return (
      <div className="loadingio-spinner-ellipsis-9w2r0d892j full-spinner"><div className="ldio-o8u2rr79tje">
      <div></div><div></div><div></div><div></div><div></div>
      </div></div>
  )
}

class InscricaoTeambuildingAgentes extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      selectedMonth: '',
      loading: false,
      formSubmitted: false,
      error: null,
    }
  }


  onSubmit = async values => {
    await sleep(300)
    const dev_token = 'f386ee39a5e46a13a460709d0beaf4dd0fff1d90';
    // const dev_token = '0ffc42cbd9ab17ea97ba8eca77a455ea519cdbb5';
    this.setState({loading: true});
    const comp = values.comparencia === "Sim" ? true : false;
    const restri = values.restricoes === "Sim" ? true : false;
    const hasCap = values.hasCap === "yes" ? true : false;
    // await axios.post(`https://api.nacionalgest.pt/api/eventos/inscricao_convencao_2023/`, {
    await axios.post(`https://api.nacionalgest.pt/api/eventos/teambuilding_inscricao_agentes/`, {
      nome: values.nome,
      telemovel: values.telemovel,
      email: values.email,
      zona: values.zona,
      comparencia: comp,
      restricoes: restri,
      dimensao_percurso: values.percurso,
      tipo_restricoes: values.tipo_restricoes,
      hasCap: hasCap,
      tshirtSize: values.tshirtSize,
      percurso: values.percurso,
      numero_cc: values.numero_cc,
      validade: values.validade
    }, {
      headers:{
        'Authorization':`Token ${dev_token}`,
  }}).then((response) => {
    console.log(response.data);
    if (response.status === 200) {
      if (response.data.error) {
        this.setState({
          error:response.data.error,
          loading: false
        })
      } else {
      this.setState({
        loading:false,
        formSubmitted: true
      })
    }
  }
  }).catch((error) => {
    this.setState({
      loading: false,
    });
    alert(error);
  });
  }

  handleSubmit = values  => {
      return this.onSubmit(values)
  }



  render() {


    return (
      <div>
        <div className="content-wrapper">
          <Header />
          <header>
            <section className="wrapper bg-soft-primary">
              <div className="container pt-10 pb-19 pt-md-14 pb-md-20 text-center">
                <div className="row">
                  <div className="col-md-9 col-lg-8 col-xl-7 col-xxl-6 mx-auto mb-11">
                    <h1 className="display-1 mb-3">Teambuilding NacionalGest 2023</h1>
                    <p className="lead px-lg-7 px-xl-7 px-xxl-6">
                      Contamos consigo para mais um dia inesquecível, repleto de convívio e atividades desafiantes que fortalecem a nossa união!
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </header>
          <section className="wrapper bg-light angled upper-end">
            <div className="container pb-14 pb-md-16">
              <div className="row mb-8">
                <div className="col-12 mt-n20">
                  <figure className="rounded">
                    <img
                      src={teambuildingImage}
                      srcSet={`${teambuildingImage} 2x`}
                      alt=""
                    />
                  </figure>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <h2 className="display-6 mb-4">Camino de Hierro: Rota dos Túneis e Pontes La Fregeneda</h2>
                  <div className="row gx-0">
                    <div className="col-md-9 text-justify">
                      <p class="mb-6">Está preparado(a) para uma aventura inesquecível e repleta de diversão? Temos a caminhada ideal para si e toda a equipa!</p>
                      <p class="mb-6">Camino de Hierro, é este o nome da histórica Rota dos Túneis e Pontes da Linha do Douro, um extraordinário trilho pedestre pela abandonada linha férrea entre La Fregeneda (Espanha) e Barca d’Alva (Portugal), em pleno Parque Natural das Arribas do Douro, o congénere espanhol do Douro Internacional.</p>
                <h6>Detalhes que precisa de saber:</h6>
            <ul>
                <li><strong>Início do passeio:</strong> La Fregeneda, Espanha. (30 de Setembro às 08:00h)</li>
                <li><strong>Fim do passeio:</strong> Barca D'Alva, Portugal.</li>
                <li><strong>Distância:</strong> São 17 kms ao todo. Existe possibilidade de iniciar aos 10 kms.</li>
            </ul>

            <h5>E depois da caminhada?</h5>
            <p>Temos um almoço à sua espera! "Porco no Espeto" e outras iguarias estarão à sua disposição em Barca D'Alva.</p>

            <h6>Mas a diversão não termina com a comida!</h6>
            <p>Na antiga estação ferroviária de Barca D'Alva, arranjámos um espaço fantástico, ao ar livre e também com zonas cobertas, onde teremos jogos, atividades e muita animação.</p>
            <h6>Concurso da Melhor Fotografia:</h6>
            <p>Se é amante de fotografia, este é o momento de levar a sua máquina! Mas se preferir, o seu telemóvel com bateria carregada também serve. Vamos ter um concurso para a melhor fotografia tirada durante o percurso.</p>
            <h6>Confirmação de Presença:</h6>
            <p>Precisamos da sua confirmação até ao dia 10 de Setembro para se poder organizar toda a logística.</p>

            <p>Então, que diz? Venha desfrutar da natureza, da boa comida e de excelente companhia!<br />Inscreva-se e junte-se a nós nesta experiência memorável!</p>

                    </div>
                    <div className="col-md-2 ms-auto">
                      <ul className="list-unstyled">
                        <li>
                          <h5 className="mb-1">Data</h5>
                          <p>30 de Setembro 2023</p>
                        </li>
                        <li>
                          <h5 className="mb-1">Local</h5>
                          <p>Figueira de Castelo Rodrigo</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5 gy-10 gy-sm-13 gx-md-8 gx-xl-12 align-items-center">
                <div className="col-lg-6">
                  <div className="row gx-md-5 gy-5">
                    <div className="col-12">
                      <figure className="rounded mx-5">
                        <img
                          src={teambuildingImage1}
                          srcSet={`${teambuildingImage1} 2x`}
                          alt=""
                        />
                      </figure>
                    </div>
                    {/*/column */}
                    <div className="col-md-6">
                      <figure className="rounded">
                        <img
                          src={teambuildingImage2}
                          srcSet={`${teambuildingImage2} 2x`}
                          alt=""
                        />
                      </figure>
                    </div>
                    {/*/column */}
                    <div className="col-md-6">
                      <figure className="rounded">
                        <img
                          src={teambuildingImage3}
                          srcSet={`${teambuildingImage3} 2x`}
                          alt=""
                        />
                      </figure>
                    </div>
                    {/*/column */}
                  </div>
                  {/*/.row */}
                </div>
                {/*/column */}
                <div className="col-lg-6">
                  <h2 className="fs-16 text-uppercase text-muted mb-3">
                    Formulário de Inscrição
                  </h2>
                  <div className="row gx-0">
                      <div>
                      {this.state.error === 1 && (
                        <>
                        <h1>Já respondeu a este formulário!</h1>
                        <p>Uma resposta com este e-mail já foi submetida neste formulário. </p>
                        <p>Se acredita que esta situação possa estar errada, entre em contacto connosco. </p>
                        </>
                      )}
                      {this.state.formSubmitted && (
                        <>
                        <h1>Obrigado por responder!</h1>
                        <p>Mais perto da data voltaremos a entrar em contacto com mais pormenores. </p>
                        </>

                      )}
                      {!this.state.formSubmitted && this.state.error === null && (
                      <Form
                      onSubmit={this.handleSubmit}
                      initialValues={{
                       }}
                      render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-md-12">
                            <h6 className="mb-3">Zona</h6>
                              <Field id="zona" name="zona">
                                {({ input, meta }) => (
                                  <div className="form-label-group mb-4">
                                    <select {...input} className="form-select" type="text" component="select" required>
                                        <option disabled selected value="">Seleccione uma opção</option>
                                        <option value="norte">Norte</option>
                                        <option value="beira_alta">Beira Alta</option>
                                        <option value="centro">Centro</option>
                                        <option value="ribatejo">Ribatejo</option>
                                        <option value="lisboa">Grande Lisboa</option>
                                        <option value="alto_alentejo">Alto Alentejo</option>
                                        <option value="alentejo_central">Alentejo Central</option>
                                        <option value="sotavento">Algarve Sotavento</option>
                                        <option value="barlavento">Algarve Barlavento</option>
                                    </select>
                                    {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                  </div>
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <Field id="nome" name="nome" validate={required}>
                                {({ input, meta }) => (
                                  <div className="form-label-group mb-4">
                                    <input {...input} className="form-control" type="text" component="input" placeholder="nome" />
                                    <label htmlFor="nome">Nome</label>
                                    {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                  </div>
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <Field id="email" name="email" validate={required}>
                                {({ input, meta }) => (
                                  <div className="form-label-group mb-4">
                                    <input {...input} className="form-control" type="email" component="input" placeholder="E-mail" />
                                    <label htmlFor="email">E-mail</label>
                                    {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                  </div>
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <Field id="telemovel" name="telemovel" validate={required}>
                                {({ input, meta }) => (
                                  <div className="form-label-group mb-4">
                                    <input {...input} className="form-control" type="text" component="input" placeholder="Telemóvel" />
                                    <label htmlFor="telemovel">Telemóvel</label>
                                    {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                  </div>
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-label-group">
                              <div className="form-check">
                                <label className="form-check-label" htmlFor="comparencia" style={{fontSize: '18px'}}>
                                <Field
                                  className="form-check-input"
                                  name="comparencia"
                                  component="input"
                                  type="radio"
                                  value="Sim"
                                  required
                                />{' '}
                                Vou ao Teambuilding
                              </label>
                              </div>
                              <div className="form-check mb-4">
                                <label className="form-check-label" htmlFor="comparencia" style={{fontSize: '18px'}}>
                                <Field
                                  className="form-check-input"
                                  name="comparencia"
                                  component="input"
                                  type="radio"
                                  value="Nao"
                                  required
                                />{' '}
                                Não vou ao Teambuilding
                              </label>
                              </div>
                            </div>
                          </div>
                          {values.comparencia === "Sim" && (
                            <>
                            <div className="row">
                              <div className="form-label-group">
                                <h6 className="mb-3">Estadia</h6>
                                <div className="form-check">
                                  <label className="form-check-label" htmlFor="hotel" style={{fontSize: '18px'}}>
                                  <Field
                                    className="form-check-input"
                                    name="hotel"
                                    component="input"
                                    type="radio"
                                    value="Sim"
                                    required
                                  />{' '}
                                  Durmo no hotel
                                </label>
                                </div>
                                <div className="form-check mb-4">
                                  <label className="form-check-label" htmlFor="hotel" style={{fontSize: '18px'}}>
                                  <Field
                                    className="form-check-input"
                                    name="hotel"
                                    component="input"
                                    type="radio"
                                    value="Nao"
                                    required
                                  />{' '}
                                  Não durmo no hotel
                                </label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-label-group">
                                <h6 className="mb-3">Distância do Percurso</h6>
                                <div className="form-check">
                                  <label className="form-check-label" htmlFor="percurso" style={{fontSize: '18px'}}>
                                  <Field
                                    className="form-check-input"
                                    name="percurso"
                                    component="input"
                                    type="radio"
                                    value="17"
                                    required
                                  />{' '}
                                  17km <small>Altamente recomendado!</small>
                                </label>
                                </div>
                                <div className="form-check mb-4">
                                  <label className="form-check-label" htmlFor="percurso" style={{fontSize: '18px'}}>
                                  <Field
                                    className="form-check-input"
                                    name="percurso"
                                    component="input"
                                    type="radio"
                                    value="7"
                                    required
                                  />{' '}
                                  7km <small>Os 7 kms são como um episódio piloto, mas os 17 kms são a temporada completa. Quem fica só no piloto, nunca saberá a história completa!</small>
                                </label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="mb-3">Tamanho T-Shirt</h6>
                                <Field id="tshirtSize" name="tshirtSize">
                                  {({ input, meta }) => (
                                    <div className="form-label-group mb-4">
                                      <select {...input} className="form-select" type="text" component="select" required>
                                        <option disabled selected value="">Seleccionar tamanho</option>
                                        <option value="xs">XS</option>
                                        <option value="s">S</option>
                                        <option value="m">M</option>
                                        <option value="l">L</option>
                                        <option value="xl">XL</option>
                                        <option value="xxl">XXL</option>
                                      </select>
                                      {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                    </div>
                                  )}
                                </Field>
                              </div>
                            </div>
                            <div className="row">
                              <h6 className="mb-3">Cartão de Cidadão</h6>
                              <div className="col-md-8">
                                <Field id="numero_cc" name="numero_cc" validate={required}>
                                  {({ input, meta }) => (
                                    <div className="form-label-group mb-4">
                                      <input {...input} className="form-control" type="text" component="input" placeholder="Número CC" />
                                      <label htmlFor="numero_cc">Número Cartão de Cidadão</label>
                                      {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                    </div>
                                  )}
                                </Field>
                              </div>
                              <div className="col-md-4">
                                <Field id="validade" name="validade" validate={required} parse={normalizeDate}>
                                  {({ input, meta }) => (
                                    <div className="form-label-group mb-4">
                                      <input {...input} className="form-control" type="text" component="input" placeholder="Validade" />
                                      <label htmlFor="validade">Validade</label>
                                      {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                      <small>Formato DD/MM/AAAA</small>
                                    </div>
                                  )}
                                </Field>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="mb-3">Boné NacionalGest</h6>
                                <Field id="hasCap" name="hasCap" type="radio" value="yes">
                                  {({ input, meta }) => (
                                    <div className="form-check form-check-inline">
                                      <input {...input} className="form-check-input" />
                                      <label className="form-check-label" htmlFor="hasCap">Sim, tenho um</label>
                                    </div>
                                  )}
                                </Field>
                                <Field id="noCap" name="hasCap" type="radio" value="no">
                                  {({ input, meta }) => (
                                    <div className="form-check form-check-inline">
                                      <input {...input} className="form-check-input" />
                                      <label className="form-check-label" htmlFor="noCap">Não, ainda não tenho</label>
                                    </div>
                                  )}
                                </Field>
                                <div className="mt-2">
                                  <small>Se tiver, deve levá-lo consigo. Se não tiver, não se preocupe, temos um para si.</small>
                                </div>
                              </div>
                            </div>


                            <div className="row">
                              <div className="form-label-group">
                                <h6 className="mb-3">Restrições Alimentares</h6>
                                <div className="form-check">
                                  <label className="form-check-label" htmlFor="restricoes" style={{fontSize: '18px'}}>
                                  <Field
                                    className="form-check-input"
                                    name="restricoes"
                                    component="input"
                                    type="radio"
                                    value="Sim"
                                    required
                                  />{' '}
                                  Sim
                                </label>
                                </div>
                                <div className="form-check mb-4">
                                  <label className="form-check-label" htmlFor="restricoes" style={{fontSize: '18px'}}>
                                  <Field
                                    className="form-check-input"
                                    name="restricoes"
                                    component="input"
                                    type="radio"
                                    value="Nao"
                                    required
                                  />{' '}
                                  Não
                                </label>
                                </div>
                              </div>
                            </div>
                            </>
                          )}
                          {values.comparencia === "Sim" && values.restricoes === "Sim" && (
                            <div className="row mb-4">
                              <div className="col-md-12">
                              <Field id="tipo_restricoes" name="tipo_restricoes" className="form-control" component="textarea" placeholder="Restrições">
                              </Field>
                            </div>
                            </div>
                          )}
                          <div className="row">
                            <div className="col-md-12" style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                              <button type="submit" style={{width:'100%'}} className="btn btn-primary rounded-0 btn-send mb-3" disabled={submitting || pristine}>
                                Submeter
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    />)}
                </div>
                  </div>
                </div>
                {/*/column */}
              </div>
              {/*/.row */}
            </div>
          </section>
      <RequestContact />
      <Footer />
        {this.state.loading && (
          <Spinner />
        )}
        <div className="progress-wrap">
          <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
          </svg>
        </div>
          </div>
        </div>

  )
}
}

export default withRouter(InscricaoTeambuildingAgentes);
