import React, { Component, useState, useEffect } from 'react';
import LoginParceiros from '../../LoginParceiros';
import axios from 'axios';
import useToken from '../../../useToken';
import FooterParceiros from '../../FooterParceiros';
import HeaderParceiros from '../../HeaderParceiros';
import SimuladorFormAP from './SimuladorFormAP';


function setToken(userToken) {
  sessionStorage.setItem('token', JSON.stringify(userToken));
  this.setState({token:JSON.stringify(userToken)})
}

function getToken() {
  const tokenString = sessionStorage.getItem('token');
  const userToken = JSON.parse(tokenString);
  return userToken?.token
}


function SimuladorAP() {

  const { token, setToken } = useToken();
  const [isLoading, setLoading ] = useState(true);
  const [ nomeParceiro, setNomeParceiro ] = useState();
  const [ emailParceiro, setEmailParceiro ] = useState();

  function setParceiro(dadosParceiro) {
    sessionStorage.setItem('nomeParceiro', dadosParceiro.nome);
    sessionStorage.setItem('emailParceiro', dadosParceiro.email);
    setNomeParceiro(dadosParceiro.nome);
    setEmailParceiro(dadosParceiro.email);
  }

  if(!token) {
    return <LoginParceiros setToken={setToken} />
  }
  // OBTER DETALHES PARCEIRO ATRAVES DO TOKEN DE AUTH
  if(token) {
    axios.post(`https://api.nacionalgest.pt/api/obter_nome_user`, {
      'token': token
    },{
      'headers':{
        'Authorization':`Token ${token}`,
      }
    }).then(response =>  {
      setParceiro(response.data)
    })
    .catch(function(error) {
      console.log(error);
    });
  }

  return (
    <div>
    <HeaderParceiros
      nomeParceiro={nomeParceiro}
      emailParceiro={emailParceiro}
    />
      <section className="wrapper">
  			<div className="container pt-10 pb-12 pt-md-14 pb-md-14 text-center">
  				<div className="row">
  					<div className="col-lg-8 mx-auto">
              <h4>{nomeParceiro} - {emailParceiro}</h4>
  						<h1 className="display-1 mb-3">Parceiros NacionalGest</h1>
  					</div>
  				</div>
  			</div>
  		</section>
      <SimuladorFormAP
        token={token}
        nomeParceiro={nomeParceiro}
        emailParceiro={emailParceiro}
        />
      <FooterParceiros />
      </div>
  );
}


export default SimuladorAP;
