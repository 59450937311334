import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Footer from '../Footer';
import Header from '../Header';
import RequestContact from '../RequestContact';

import seguroImage from '../../assets/img/ng/particulares.jpeg';
import seguroImageEmpresa from '../../assets/img/ng/empresas.jpeg';
import {
  Link
} from "react-router-dom";

class Base extends Component {

  render() {
    return (
    <div>
      <div className="content-wrapper">
        <Header />
          <section className="wrapper bg-light">
      			<div className="container pt-10 pt-md-11 pb-8 pb-md-6 pb-lg-8 text-center">
      				<div className="row">
      					<div className="col-lg-12mx-auto">
      						<h1 className="display-1 fs-60 mb-4 px-md-15 px-lg-0">Loja Online</h1>
      						<p className="lead fs-24 lh-sm mx-md-13 mx-lg-10">Soluções online com todo o apoio presencial.</p>
      					</div>
      				</div>
      			</div>
      		</section>
          <section className="wrapper bg-light wrapper-border">
    			<div className="container py-8 pt-md-2">
    				<div className="card bg-soft-primary mb-10">
    					<div className="card-body p-12 pb-0">
    						<div className="row" style={{display:'flex', justifyContent: 'space-around'}}>
    							<div className="col-lg-5 pb-12">
                    <figure><Link to="seguros-particulares"><img className="img-fluid" src={seguroImage} srcSet={{seguroImage} + "2x"} alt="" /></Link></figure>
    								<div className="post-category mt-2 mb-3 text-green">Seguros</div>
    								<h3 className="h1 post-title mb-3">Particulares</h3>
    								<p>Conheça as soluções de seguros particulares que temos para o proteger. </p>
    								<Link to="seguros-particulares" className="more hover link-green">Saber mais</Link>
    							</div>
    							<div className="col-lg-5 offset-lg-1">
    								<figure><Link to="seguros-empresas"><img className="img-fluid" src={seguroImageEmpresa} srcSet={{seguroImageEmpresa} + "2x"} alt="" /></Link></figure>
                      <div className="post-category mt-2 mb-3 text-green">Seguros</div>
      								<h3 className="h1 post-title mb-3">Empresas</h3>
      								<p>Conheça as soluções de seguros empresariais que temos para o proteger.</p>
      								<Link to="seguros-empresas" className="more hover link-green">Saber Mais</Link>
    							</div>
    						</div>
    					</div>
    				</div>
    			</div>
    		</section>
        </div>
        <RequestContact />
        <Footer />
      <div className="progress-wrap">
        <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>
    </div>
    )
  }
}

export default Base;
