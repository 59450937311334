import React from 'react';
import axios from 'axios';

class RequestContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nome: '',
      telefone: '',
      email: '',
      requestedContact:false,
      awaitingPost:false};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  async handleSubmit(event) {
    this.setState({ awaitingPost: true });
    event.preventDefault();
    axios({
      method: 'POST',
      url: 'https://api.nacionalgest.pt/api/solicitar_contacto/',
      data: {
        name: this.state.nome,
        phone_number: this.state.telefone,
        email: this.state.email
      },
      headers: {
        Authorization: 'Token f386ee39a5e46a13a460709d0beaf4dd0fff1d90',
      },
    }).then((response) => {
      this.setState({requestedContact:true})
    })
  }
  render() {
  return (
    <div className="modal fade" id="modal-03" tabIndex={-1}>
      <div className="modal-dialog modal-dialog-centered modal-sm">
        <div className="modal-content text-center">
          {this.state.requestedContact === false && (
            <div className="modal-body">
              <button className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              <h3 className="mb-4">Solicitar Contacto</h3>
              <form className="text-start mb-3" onSubmit={this.handleSubmit}>
                <div className="form-label-group mb-4">
                  <input type="text" value={this.state.nome} onChange={this.handleChange} className="form-control" placeholder="Nome" id="nome" name="nome" required/>
                  <label htmlFor="nome">Nome</label>
                </div>
                <div className="form-label-group mb-4">
                  <input type="text" value={this.state.telefone} onChange={this.handleChange} className="form-control" placeholder="Contacto Telefónico" id="contacto" name="telefone" required/>
                  <label htmlFor="contacto">Contacto Telefónico</label>
                </div>
                <div className="form-label-group mb-4">
                  <input type="email" value={this.state.email} onChange={this.handleChange} className="form-control" placeholder="Email" id="loginEmail" name="email" required/>
                  <label htmlFor="loginEmail">E-mail</label>
                </div>
                {this.state.awaitingPost === true && (
                  <div style={{textAlign:'center'}}>
                  <div className="loadingio-spinner-ellipsis-9w2r0d892j"><div className="ldio-o8u2rr79tje">
                  <div></div><div></div><div></div><div></div><div></div>
                  </div></div>
                  </div>
                )}
                {this.state.awaitingPost === false && (
                  <input className="btn btn-primary rounded-0 btn-login w-100 mb-2" value="Submeter Pedido" type="submit" />
                )}
              </form>
              {/* /form */}
            </div>
          )}
          {this.state.requestedContact === true && (
            <div className="modal-body">
              <button className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              <h3 className="mb-4">Recebemos o seu pedido</h3>

              <p>Recebemos os seus contactos e entraremos em contacto consigo por e-mail ou telefone o mais rápido possível.</p>
              {/* /form */}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
}

export default RequestContact;
