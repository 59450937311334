import React from 'react';
import {
  Link
} from "react-router-dom";

const Header = () => {
    return (
      <header className="wrapper bg-light">
        <div className="bg-primary text-white fw-bold fs-15">
				<div className="container d-md-flex flex-md-row">
					<div className="d-flex flex-row align-items-center">
						<div className="icon text-white fs-22 mt-1 me-2"><a href="https://www.facebook.com/nacionalgestseguros" target="_blank" rel="noreferrer"><i className="uil uil-facebook-f text-white" /></a></div>
						<div className="icon text-white fs-22 mt-1 me-2"><a href="https://www.instagram.com/nacionalgest/" target="_blank" rel="noreferrer"><i className="uil uil-instagram text-white" /></a></div>
						<div className="icon text-white fs-22 mt-1 me-2"><a href="https://www.linkedin.com/company/nacional-gest-consultores-de-seguros/" target="_blank" rel="noreferrer"><i className="uil uil-linkedin text-white" /></a></div>
						<div className="icon text-white fs-22 mt-1 me-2"><a href="https://www.youtube.com/channel/UCPHK6D-5z-2A_pUqSPmlJag" target="_blank" rel="noreferrer"><i className="uil uil-youtube text-white" /></a></div>
					</div>
					<div className="d-flex flex-row align-items-center me-6 ms-auto">
						<div className="icon text-white fs-22 mt-1 me-2"> <i className="uil uil-phone-alt"></i></div>
						<p className="mb-0"><a href="tel:+351211341202" className="link-white hover">+351 211 341 202</a></p>
					</div>
					<div className="d-flex flex-row align-items-center">
						<div className="icon text-white fs-22 mt-1 me-2"> <i className="uil uil-envelope"></i></div>
						<p className="mb-0"><a href="mailto:apoio.cliente@nacionalgest.pt" className="link-white hover">apoio.cliente@nacionalgest.pt</a></p>
					</div>
				</div>
			</div>
        <nav className="navbar center-nav transparent navbar-expand-lg navbar-light">
          <div className="container flex-lg-row flex-nowrap align-items-center">
            <div className="navbar-brand w-25"><Link to ="/"><img src="https://api.nacionalgest.pt/static/assets/logo/nacionalgest_logo.png" srcSet="https://api.nacionalgest.pt/static/assets/logo/nacionalgest_logo.png 2x" alt="" /></Link></div>
            <div className="navbar-collapse offcanvas-nav">
              <div className="offcanvas-header d-lg-none d-xl-none">
                <Link to="/"><img src="https://api.nacionalgest.pt/static/assets/logo/nacionalgest_logo.png" srcSet="https://api.nacionalgest.pt/static/assets/logo/nacionalgest_logo.png 2x" alt="" /></Link>
                <button type="button" className="btn-close offcanvas-close offcanvas-nav-close" aria-label="Close" />
              </div>
              <ul className="navbar-nav">
                <li className="nav-item"><Link to="/seguros-particulares" className="nav-link">Soluções Particulares</Link>
                </li>
                <li className="nav-item"><Link to="/seguros-empresas" className="nav-link">Soluções Empresas</Link>
                </li>
                <li className="nav-item"><a className="nav-link" target="_blank" href="https://www.nacionalgest.pt/lojas/" rel="noreferrer">Lojas NacionalGest</a>
                </li>
                <li className="nav-item"><a className="nav-link" target="_blank" href="https://www.nacionalgest.pt/lojas/" rel="noreferrer">Contactos</a>
                </li>
              </ul>
              {/* /.navbar-nav */}
            </div>
            {/* /.navbar-collapse */}
            <div className="navbar-other w-25 d-flex ms-auto">
              <ul className="navbar-nav flex-row align-items-center ms-auto" data-sm-skip="true">
                <li className="nav-item d-none d-md-block">
                  <a className="btn btn-sm btn-primary rounded-0" data-bs-toggle="modal" data-bs-target="#modal-03">Solicitar Contacto</a>
                </li>
                <li className="nav-item d-lg-none">
                  <div className="navbar-hamburger"><button className="hamburger animate plain" data-toggle="offcanvas-nav"><span /></button></div>
                </li>
              </ul>
              {/* /.navbar-nav */}
            </div>
            {/* /.navbar-other */}
          </div>
          {/* /.container */}
        </nav>
        {/* /.navbar */}
      </header>
    )
  }

  export default Header;
