import React from 'react';
import axios from 'axios';

class RequestContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nome: '',
      telefone: '',
      email: '',
      requestedContact:false,
      awaitingPost:false};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  async handleSubmit(event) {
    this.setState({ awaitingPost: true });
    event.preventDefault();
    axios({
      method: 'POST',
      url: 'https://api.nacionalgest.pt/api/solicitar_contacto/',
      data: {
        name: this.state.nome,
        phone_number: this.state.telefone,
        email: this.state.email
      },
      headers: {
        Authorization: 'Token f386ee39a5e46a13a460709d0beaf4dd0fff1d90',
      },
    }).then((response) => {
      this.setState({requestedContact:true})
    })
  }
  render() {
  return (
    <>
    <div className="modal fade" id="modal1" tabIndex={-1}>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content text-center">
          <div className="modal-body">
            <button className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              <h4 className="mb-2 text-center">Acesso à Rede</h4>
              <div className="table-responsive">
                <table className="table table-hover" style={{fontSize:"14px"}}>
                  <thead className="table-light">
                    <tr>
                      <th>Ambulatório Consultas</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Consulta Programada</th>
                      <td>Valor referência de 35€</td>
                    </tr>
                    <tr>
                      <th scope="row">Consulta de Urgência</th>
                      <td>Valor referência de 45€</td>
                    </tr>
                    <tr>
                      <th scope="row">Restantes Serviços</th>
                      <td>Preço de Rede</td>
                    </tr>
                  </tbody>
                </table>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div className="modal fade" id="modal2" tabIndex={-1}>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content text-center">
            <div className="modal-body">
              <button className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                <h4 className="mb-2 text-center">Médico ao Domicílio</h4>
                <div className="table-responsive">
                  <table className="table table-hover" style={{fontSize:"14px"}}>
                    <thead className="table-light">
                      <tr>
                        <th>Cobertura</th>
                        <th>Co-pagamento (por sinistro)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Envio de Médico ao Domicílio em Caso de Urgência</th>
                        <td>15,00€</td>
                      </tr>
                      <tr>
                        <th scope="row">Envio de um Profissional de Enfermagem ao Domicílio</th>
                        <td>10,00€ hora / serviço</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
            </div>
        </div>
      </div>
    </div>
    <div className="modal fade" id="modal3" tabIndex={-1}>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content text-center">
            <div className="modal-body">
              <button className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                <h4 className="mb-2 text-center">Assistência Animal</h4>
                <div className="table-responsive">
                  <table className="table table-hover" style={{fontSize:"14px"}}>
                    <thead className="table-light">
                      <tr>
                        <th>Cobertura</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Serviços de assistência, informação e organização de serviços relacionados com o animal.</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
            </div>
        </div>
      </div>
    </div>
    <div className="modal fade" id="modal4" tabIndex={-1}>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content text-center">
            <div className="modal-body">
              <button className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                <h4 className="mb-2 text-center">Médico Online</h4>
                <div className="table-responsive">
                  <table className="table table-hover" style={{fontSize:"14px"}}>
                    <thead className="table-light">
                      <tr>
                        <th>Cobertura</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Médico Online</th>
                        <td>15,00€</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
            </div>
        </div>
      </div>
    </div>
    </>
  )
}
}

export default RequestContact;
