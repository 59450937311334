import React from 'react';
import ReactDOM from 'react-dom';


class CoberturasRCCredito extends React.Component {
  render() {
    return (
      <>
      <div className="row mb-6">
        <div className="col-md-12">
          <div className="pricing card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover" style={{fontSize:"14px"}}>
                  <thead className="table-light">
                    <tr>
                      <th>Coberturas</th>
                      <th>Franquia</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Responsabilidade Civil</th>
                        <td rowSpan="3" style={{padding:'0.1rem 0.1rem', verticalAlign:'middle', textAlign:'center'}}>10% mín. 500€</td>
                    </tr>
                    <tr>
                      <th scope="row">Assistência a clientes (Repatriamento e prestação de assistência)</th>
                    </tr>
                    <tr>
                      <th scope="row">Assistência Médica e Medicamentação necessária</th>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="swipe-left-d-none">
                <div className="swipe-left-table">
                  <div className="indicator" style={{textAlign:'center'}}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <p className="mb-0" style={{textAlign:'center'}}>Arraste para o lado para saber mais.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }
}

export default CoberturasRCCredito;
