import React, { Component, useState, useEffect } from 'react';
import LoginParceiros from './LoginParceiros';
import axios from 'axios';
import useToken from '../useToken';
import FooterParceiros from './FooterParceiros';
import HeaderParceiros from './HeaderParceiros';
import BaseParceiros from './BaseParceiros';
import {
  Link
} from "react-router-dom";
import { Form, Field } from 'react-final-form'


const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))


function setToken(userToken) {
  sessionStorage.setItem('token', JSON.stringify(userToken));
  this.setState({token:JSON.stringify(userToken)})
}

function getToken() {
  const tokenString = sessionStorage.getItem('token');
  const userToken = JSON.parse(tokenString);
  return userToken?.token
}




function NotificarClientesParceiros() {

  const { token, setToken } = useToken();
  const [isLoading, setLoading ] = useState(true);
  const [ nomeParceiro, setNomeParceiro ] = useState();
  const [ emailParceiro, setEmailParceiro ] = useState();

  function setParceiro(dadosParceiro) {
    sessionStorage.setItem('nomeParceiro', dadosParceiro.nome);
    sessionStorage.setItem('emailParceiro', dadosParceiro.email);
    setNomeParceiro(dadosParceiro.nome);
    setEmailParceiro(dadosParceiro.email);
  }

  function getToken() {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token
  }

  const onSubmitRGPD = async values => {
    await sleep(300)
    console.log(values);
    axios( {
    url: 'https://api.nacionalgest.pt/api/enviar_mail_rgpd/',
      method: 'POST',
      data: {
        email: values.emailRGPD
      },
      headers: {
        'Authorization':`Token ${getToken()}`
      }
    }).then((response) => {
      if (response.status === 200) {
        window.alert("Email Enviado com sucesso.")
    }
    })
  }

  const onSubmitNPS = async values => {
    await sleep(300)
    console.log(values);
    axios( {
    url: 'https://api.nacionalgest.pt/api/enviar_mail_nps/',
      method: 'POST',
      data: {
        email: values.emailNPS
      },
      headers: {
        'Authorization':`Token ${getToken()}`
      }
    }).then((response) => {
      if (response.status === 200) {
        window.alert("Email NPS enviado com sucesso.")
    }
    })
  }

  const onSubmitSegmentacao = async values => {
    await sleep(300)
    console.log(values);
    axios( {
    url: 'https://api.nacionalgest.pt/api/enviar_mail_segmentacao/',
      method: 'POST',
      data: {
        email: values.emailSegmentacao
      },
      headers: {
        'Authorization':`Token ${getToken()}`
      }
    }).then((response) => {
      if (response.status === 200) {
        window.alert("Email Segmentação enviado com sucesso.")
    }
    })
  }



  if(!token) {
    return <LoginParceiros setToken={setToken} />
  }
  // OBTER DETALHES PARCEIRO ATRAVES DO TOKEN DE AUTH
  if(token) {
    // axios.post(`https://api.nacionalgest.pt/api/obter_nome_user`, {
    axios.post(`https://api.nacionalgest.pt/api/obter_nome_user`, {
      'token': token
    },{
      'headers':{
        'Authorization':`Token ${token}`,
      }
    }).then(response =>  {
      setParceiro(response.data)
    })
    .catch(function(error) {
      console.log(error);
    });
  }

  return (
    <div>
    <HeaderParceiros
      nomeParceiro={nomeParceiro}
      emailParceiro={emailParceiro}
    />
      <section className="wrapper">
  			<div className="container pt-10 pb-12 pt-md-14 pb-md-14 text-center">
  				<div className="row">
  					<div className="col-lg-8 mx-auto">
              <h4>{nomeParceiro} - {emailParceiro}</h4>
  						<h1 className="display-1 mb-3">Parceiros NacionalGest</h1>
  					</div>
  				</div>
  			</div>
  		</section>
      <section className="wrapper bg-light wrapper-border">
        <div className="container py-8 pt-md-2">
          <div className="card bg-soft-primary mb-10">
            <div className="card-body p-12 pb-0">
              <div className="row" style={{display:'flex', justifyContent: 'space-around'}}>
                <div className="col-lg-5 pb-12">
                  <div className="post-category mt-2 mb-3 text-green">Clientes</div>
                  <h3 className="h1 post-title mb-3">Enviar Email RGPD</h3>
                    <Form
              onSubmit={onSubmitRGPD}
              render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit}>
                    <div className="form-label-group mb-4">
                    <Field
                      name="emailRGPD"
                      class="form-control"
                    >
                    {({ input, meta }) => (
                      <div className="form-label-group mb-4">
                        <input {...input} className="form-control" type="text" component="input" placeholder="Email" />
                        <label htmlFor="emailRGPD">E-mail</label>
                        {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                      </div>
                    )}
                  </Field>
                  </div>
                  <div>
                  </div>
                  <div className="col-12">
                    <button type="submit" className="btn btn-primary rounded-0 btn-send mb-3" disabled={submitting || pristine} >Enviar Email RGPD</button>
                  </div>
                </form>
              )}
            />
                </div>
                <div className="col-lg-5 offset-lg-1">
                    <div className="post-category mt-2 mb-3 text-green">Clientes</div>
                      <h3 className="h1 post-title mb-3">Enviar Email NPS</h3>

                      <Form
                onSubmit={onSubmitNPS}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                  <form onSubmit={handleSubmit}>
                      <div className="form-label-group mb-4">
                      <Field
                        name="emailNPS"
                        class="form-control"
                      >
                      {({ input, meta }) => (
                        <div className="form-label-group mb-4">
                          <input {...input} className="form-control" type="text" component="input" placeholder="Email" />
                          <label htmlFor="emailNPS">E-mail</label>
                          {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                        </div>
                      )}
                    </Field>
                    </div>
                    <div>
                    </div>
                    <div className="col-12">
                      <button type="submit" className="btn btn-primary rounded-0 btn-send mb-3" disabled={submitting || pristine} >Enviar Email NPS</button>
                    </div>
                  </form>
                )}
              />
                </div>
              </div>
              <div className="row" style={{display:'flex', justifyContent: 'space-around'}}>
                <div className="col-lg-5 pb-12">
                  <div className="post-category mt-2 mb-3 text-green">Clientes</div>
                  <h3 className="h1 post-title mb-3">Enviar Email Segmentação</h3>
                    <Form
              onSubmit={onSubmitSegmentacao}
              render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit}>
                    <div className="form-label-group mb-4">
                    <Field
                      name="emailSegmentacao"
                      class="form-control"
                    >
                    {({ input, meta }) => (
                      <div className="form-label-group mb-4">
                        <input {...input} className="form-control" type="text" component="input" placeholder="Email" />
                        <label htmlFor="emailSegmentacao">E-mail</label>
                        {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                      </div>
                    )}
                  </Field>
                  </div>
                  <div>
                  </div>
                  <div className="col-12">
                    <button type="submit" className="btn btn-primary rounded-0 btn-send mb-3" disabled={submitting || pristine} >Enviar Email Segmentação</button>
                  </div>
                </form>
              )}
            />
                </div>
                <div className="col-lg-5 offset-lg-1">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterParceiros />
    </div>
  );
}


export default NotificarClientesParceiros;
