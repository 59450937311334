import React, { Component } from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom';
import { Form, Field, FormSpy } from 'react-final-form';
import { FORM_ERROR } from 'final-form'
import {withRouter, Redirect} from 'react-router-dom'
import Footer from '../../Footer';
import Header from '../../Header';
import RequestContact from '../../RequestContact';
import SimulacaoFormPredictable from './SimuladorFormPredictable';
import seguroViagem from '../../../assets/img/seguros/seguro-viagem-barato-ferias.jpeg';

import {
  required,
  normalizeNIF,
  nifSuccess,
  Condition,
  } from './Utils';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)



const verifyPhoneNumber = (value) => {
  return value.length === 9 ? undefined : "Formato Incorreto";
}

const normalizePhoneNumber = (value) => {
  if (!value) return value;
  var onlyNums = value.replace(/[^\d]/g, "");
  return onlyNums;
};

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}



class SimulacaoPredictable extends Component {
  listaPessoasSeguras = [];

  constructor(props) {
    super(props);
    this.state = {
      listaPaises: [],
      tiposViagem: [],
      loadingState: false,
      fromCountryInvalid: false,
      showForm: false,
      predictable: {
        warranty: []
      }
    }
    for (var i = 2; i < this.props.location.state.data.paxQty + 1; i++) {
      this.listaPessoasSeguras.push({ i });
    }
  }

  shouldShowErrorMessage = (values) => {
    const { data } = this.props.location.state;
    const { fromCountry, toCountry } = data;

    const isFromCountryInvalid =
      fromCountry[0].code !== '00000040' &&
      toCountry.some((country) => country.code !== '00000040');
      console.log(fromCountry[0].code);

    const hasInvalidCondition = this.listaPessoasSeguras.some((number) => {
      const paisResidencia = values[`paisResidencia${number.i}`];
      const isResidenceInvalid = paisResidencia !== '00000040';
      return isFromCountryInvalid && isResidenceInvalid;
    });

    return hasInvalidCondition;
  };

  componentDidMount() {
    const dev_token = 'f386ee39a5e46a13a460709d0beaf4dd0fff1d90'
    const prod_token = ''
    this.setState({
      loading: true})
    const { state } = this.props.location;
    if (state) {
      const listaPaises = state.listaPaises;
    }
    axios.get(`https://api.nacionalgest.pt/api/protocolos/predictable/obter_access_token/`, {
      headers:{
        'Authorization':`Token ${dev_token}`
    }
  }).then(response => {
    if (response.status === 200) {
      const predictable_token = response.data.items.token;
      sessionStorage.setItem('predictable_token', JSON.stringify(predictable_token));
      axios.get(`https://api.nacionalgest.pt/api/protocolos/predictable/obter_info_detalhada/select/`, {
        headers:{
          'Authorization':`Token ${dev_token}`,
          'Token':`Bearer ${predictable_token}`
      }
    }).then(response => {
      const data = response.data.items;
      this.setState({
        predictable: data,
        loading: false})
    })
  }})
}

handleShowForm() {
  this.setState({showForm:true});
  window.scrollTo({
      top: 300,
      behavior: "smooth"
    });
  document.getElementById("nome").focus();
}

onSubmit = async (values) => {

  await sleep(300)

  const dev_token = 'f386ee39a5e46a13a460709d0beaf4dd0fff1d90';
  const prod_token = '';
  const predictable_token = JSON.parse(sessionStorage.getItem('predictable_token'));
  var listaPassageiros = []
  listaPassageiros.push({
    "name":`${values.nomePessoaSegura1} ${values.apelidoPessoaSegura1}`,
    "firstName":`${values.nomePessoaSegura1}`,
    "lastName":`${values.apelidoPessoaSegura1}`,
    "country":`${values.paisResidencia1}`,
    "dob":"",
  })
  console.log(values.nomePessoaSegura2);
  for (var i=2; i < this.props.location.state.data.paxQty+1; i++) {
    var nomePessoaSegura = `nomePessoaSegura${i}`;
    var apelidoPessoaSegura = `apelidoPessoaSegura${i}`;
    var paisResidencia = `paisResidencia${i}`;
    console.log(apelidoPessoaSegura);
    console.log(values[apelidoPessoaSegura]);
    listaPassageiros.push({
      "name":`${values[nomePessoaSegura]} ${values[apelidoPessoaSegura]}`,
      "firstName":values[nomePessoaSegura],
      "lastName": values[apelidoPessoaSegura],
      "dob":"",
      "country":values[paisResidencia]
    });
  }

  const { fromCountry, toCountry } = this.props.location.state.data;

  const isFromCountryValid = fromCountry[0].code === '00000040';
  const isToCountryValid = toCountry.some((country) => country.code === '00000040');

  if (isFromCountryValid || isToCountryValid) {
    // Valid condition, no error
  } else {
    const hasInvalidCondition = listaPassageiros.every((passageiro) => passageiro.country !== '00000040');

    if (hasInvalidCondition) {
      return { [FORM_ERROR]: 'A viagem e a residência de pelo menos uma das pessoas seguras não cumprem os requisitos regulamentares da localização do risco para a sua devida cobertura.' };
    }
  }


  var dataPartidaFormatada = formatDate(values.dataPartida);
  var dataChegadaFormatada = formatDate(values.dataChegada);
  this.setState({loading: true});
  await axios.post(`https://api.nacionalgest.pt/api/protocolos/predictable/emitir_seguro_select/`, {
    lista_paises : this.props.location.state.data.toCountry,
    data_partida : this.props.location.state.data.fromDate,
    data_chegada : this.props.location.state.data.toDate,
    valor_alojamento : this.props.location.state.valorAlojamento,
    pais_origem : this.props.location.state.data.fromCountry[0].code,
    subtipo_viagem : this.props.location.state.subtipo_viagem,
    num_pessoas : this.props.location.state.data.paxQty,
    pre_existencias : + this.props.location.state.terrorismo,
    valor_total : this.props.location.state.data.totalAmount,
    nome_completo_faturacao : values.nome,
    nome_faturacao : values.nome,
    apelido_faturacao : values.apelidos,
    nif : values.nif,
    telemovel : values.contacto,
    morada : values.morada,
    cod_postal : values.codigoPostal,
    localidade : values.localidade,
    pais_faturacao : values.pais,
    email_faturacao : values.email,
    passageiros: listaPassageiros,
    marketing: values.marketing
  }, {
    headers:{
      'Authorization':`Token ${dev_token}`,
      'Token':`Bearer ${predictable_token}`
  }}).then((response) => {
    if (response.status === 200) {
      const paymentId = response.data.items.id;
      var link = `https://www.predictable.pt/order/${paymentId}/payment`;
      sleep(1000)
      this.setState({
        loading:false,
        paymentButtonClicked:true,
        paymentIdLink: link
      })
      // const a = document.createElement("a");
      // a.setAttribute('href', this.state.paymentIdLink);
      // a.setAttribute('target', '_blank');
      // a.click();
      // document.body.removeChild(a);
      document.getElementById("pagarSeguro").click();
  }
}).catch((error) => {
    this.setState({loading: false});
  alert(error);
});
}
handleSubmit = (values) => {
  // Proceed with form submission logic
  return this.onSubmit(values);
};




  render() {
    const loading = this.state.loading;
    const listaPessoasSeguras = []
    for (var i=2; i < this.props.location.state.data.paxQty+1; i++) {
        listaPessoasSeguras.push({i});
    }
    console.log(listaPessoasSeguras);

    var listaPaises = [];
    var listaPaisesState = this.state.listaPaises;

    listaPaisesState.forEach((entry) => {
      var singleCountry =  {};
      singleCountry['label'] = entry.name;
      singleCountry['value'] = entry.code;
      listaPaises.push(singleCountry);
    });



    return (
    <div>
    <div className="content-wrapper">
          <Header />
          {/* /header */}
          <section className="wrapper bg-light wrapper-border">
            <div className="container py-14 pt-12 pb-4">
              <h2 className="fs-18 text-uppercase text-green mb-3">SIMULAÇÃO</h2>
              <div className="row gx-lg-8 mb-10 gy-5">
                <div className="col-lg-5">
                  <h3 className="display-5 mb-0">Seguro de Viagem Internacional Select</h3>
                </div>
                {/* /column */}
                <div className="col-lg-7">
                  <p className="lead mb-5">Um Seguro de Viagem Internacional barato e completo que lhe oferece uma proteção abrangente para a maioria dos imprevistos que possam surgir no decorrer de uma viagem. Com uma vasta gama de soluções que mais se adaptam às suas necessidades e com uma rede de coberturas abrangente e flexível, o Seguro de Viagem Select é o Seguro de Viagem internacional certo para si e para toda a família. Agora com coberturas por COVID-19, assim como pré-existências e terrorismo.</p>
                </div>
                {/* /column */}
              </div>
            </div>
          </section>
          <section className="wrapper bg-gray">
            <div className="container inner pt-8 pb-0">
              <div className="flex-row" id="documentacao">
                  <a href={'https:' + this.state.predictable.informationPdf} target="_blank" className="btn btn-sm btn-soft-ash rounded-0 btn-icon btn-icon-start mb-0 me-1 informacao">Informação Prévia <i className="uil uil-file-download-alt" /> </a>
                  <a href={'https:' + this.state.predictable.generalConditionsPdf} target="_blank" className="btn btn-sm btn-soft-ash rounded-0 btn-icon btn-icon-end mb-0">Condições do Seguro <i className="uil uil-file-download-alt" /></a>
              </div>
              {/*/.row */}
            </div>
        <div className="container py-14 py-md-10">
          <div className="row gx-lg-8 gx-xl-12 coberturas">
            <div className="col-lg-8">
              <div className="blog single">
                {this.state.showForm && (
                <div className="card mb-6">
                  <div className="card-body">
                    <div className="classic-view">
                      <article className="post">
                        <h2 className="mb-3">Contrate o seu seguro em <span className="text-green">2 passos</span></h2>
                        <span>Preencha os campos com os dados pessoais e de seguida proceda ao pagamento para receber a documentação</span>
                        <div className="post-content mb-5">
                            <Form
                              initialValues={{
                              }}
                            onSubmit={this.handleSubmit}
                            render={({ submitError, handleSubmit, form, submitting, pristine, values }) => (
                              <form onSubmit={handleSubmit}>
                          <h4 className="h4 mt-8 mb-4">Tomador do seguro</h4>
                          <div className="row">
                            <div className="col-lg-6">
                              <Field name="nome" validate={required}>
                                {({ input, meta }) => (
                                  <div className="form-label-group mb-4">
                                    <input {...input} id="nome" className="form-control" type="text" component="input" placeholder="Nome" autoFocus/>
                                    <label htmlFor="nome">Nome</label>
                                    {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                  </div>
                                )}
                              </Field>
                            </div>
                            <div className="col-lg-6">
                              <Field id="apelidos" name="apelidos" validate={required}>
                                {({ input, meta }) => (
                                  <div className="form-label-group mb-4">
                                    <input {...input} className="form-control" type="text" component="input" placeholder="Apelidos" />
                                    <label htmlFor="apelidos">Apelidos</label>
                                    {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                  </div>
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <Field id="nif" name="nif" validate={composeValidators(required, nifSuccess)} parse={normalizeNIF}>
                                {({ input, meta }) => (
                                  <div className="form-label-group mb-4">
                                    <input {...input} className="form-control" type="text" component="input" placeholder="NIF" />
                                    <label htmlFor="nifInput">NIF</label>
                                    {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                  </div>
                                )}
                              </Field>
                            </div>
                            <div className="col-lg-6">
                              <Field id="contacto" name="contacto"  validate={composeValidators(required, verifyPhoneNumber)} parse={normalizePhoneNumber}>
                                {({ input, meta }) => (
                                  <div className="form-label-group mb-4">
                                    <input {...input} className="form-control" type="text" component="input" placeholder="contacto" maxLength="9"/>
                                    <label htmlFor="contacto">Telefone / Contacto</label>
                                    {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                  </div>
                                )}
                              </Field>
                            </div>
                          </div>
                          <hr className="m-1"/>
                          <h4 className="h4 mt-8 mb-4">Morada de faturação</h4>
                          <div className="row">
                            <div className="col-lg-12">
                              <Field id="moradaInput" name="morada" validate={required}>
                                {({ input, meta }) => (
                                  <div className="form-label-group mb-4">
                                    <input {...input} className="form-control" type="text" component="input" placeholder="Morada" />
                                    <label htmlFor="moradaInput">Morada</label>
                                    {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                  </div>
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4">
                              <Field id="codigoPostal" name="codigoPostal" validate={required}>
                                {({ input, meta }) => (
                                  <div className="form-label-group mb-4">
                                    <input {...input} className="form-control" type="text" component="input" placeholder="Código Postal" />
                                    <label htmlFor="codigoPostal">Código Postal</label>
                                    {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                  </div>
                                )}
                              </Field>
                            </div>
                            <div className="col-lg-4">
                              <Field id="localidade" name="localidade" validate={required}>
                                {({ input, meta }) => (
                                  <div className="form-label-group mb-4">
                                    <input {...input} className="form-control" type="text" component="input" placeholder="Localidade" />
                                    <label htmlFor="localidade">Localidade</label>
                                    {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                  </div>
                                )}
                              </Field>
                            </div>
                            <div className="col-lg-4">
                              <Field id="pais" name="pais" validate={required}>
                                {({ input, meta }) => (
                                  <div className="form-label-group mb-4">
                                    <select {...input} className="form-select" type="text" component="select">
                                      <option value={"ND"}>País</option>
                                        {this.props.location.state.listaPaises.map((option) => (
                                        <option value={option.code} key={option.code}>{option.name}</option>
                                        ))}
                                    </select>
                                  {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                  </div>
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <Field id="email" name="email" validate={required}>
                                {({ input, meta }) => (
                                  <div className="form-label-group mb-4">
                                    <input {...input} className="form-control" type="email" component="input" placeholder="Email" />
                                    <label htmlFor="email">Email</label>
                                    {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                  </div>
                                )}
                              </Field>
                            </div>
                          </div>
                          <hr className="m-1"/>
                          <h4 className="h4 mt-8 mb-4">Pessoas Seguras</h4>
                            <div className="row mt-6">
                              <h6>Pessoa segura 1</h6>
                              <div className="col-lg-6">
                                <Field id="nomePessoaSegura1" name="nomePessoaSegura1" validate={required}>
                                  {({ input, meta }) => (
                                    <div className="form-label-group mb-4">
                                      <input {...input} className="form-control" type="text" component="input" placeholder="Nome" />
                                      <label htmlFor="nomePessoaSegura1">Nome</label>
                                      {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                    </div>
                                  )}
                                </Field>
                              </div>
                              <div className="col-lg-6">
                                <Field id="apelidoPessoaSegura1" name="apelidoPessoaSegura1" validate={required}>
                                  {({ input, meta }) => (
                                    <div className="form-label-group mb-4">
                                      <input {...input} className="form-control" type="text" component="input" placeholder="Morada" />
                                      <label htmlFor="apelidoPessoaSegura1">Apelidos</label>
                                      {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                    </div>
                                  )}
                                </Field>
                              </div>
                              <div className="col-lg-6">
                                <Field id="paisResidencia" name="paisResidencia" validate={composeValidators(required)}>
                                  {({ input, meta }) => (
                                    <div className="form-label-group mb-4">
                                      <select {...input} className="form-select" type="text" component="select">
                                        <option value={"ND"}>País de Residência</option>
                                          {this.props.location.state.listaPaises.map((option) => (
                                          <option value={option.code} key={option.code}>{option.name}</option>
                                          ))}
                                      </select>
                                    {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                    </div>
                                  )}
                                </Field>
                              </div>
                            </div>
                          {listaPessoasSeguras.map((number) => (
                            <div className="row mt-6" key={`nomePessoaSegura${number.i}`}>
                              <h6>Pessoa segura {number.i}</h6>
                              <div className="col-lg-6">
                                <Field id={`nomePessoaSegura${number.i}`} name={`nomePessoaSegura${number.i}`} validate={required}>
                                  {({ input, meta }) => (
                                    <div className="form-label-group mb-4">
                                      <input {...input} className="form-control" type="text" component="input" placeholder="Nome" />
                                      <label htmlFor={`nomePessoaSegura${number.i}`}>Nome</label>
                                      {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                    </div>
                                  )}
                                </Field>
                              </div>
                              <div className="col-lg-6">
                                <Field id={`apelidoPessoaSegura${number.i}`} name={`apelidoPessoaSegura${number.i}`} validate={required}>
                                  {({ input, meta }) => (
                                    <div className="form-label-group mb-4">
                                      <input {...input} className="form-control" type="text" component="input" placeholder="Morada" />
                                      <label htmlFor={`apelidoPessoaSegura${number.i}`}>Apelidos</label>
                                      {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                    </div>
                                  )}
                                </Field>
                              </div>
                              <div className="col-lg-6">
                                <Field id={`paisResidencia${number.i}`} name={`paisResidencia${number.i}`} validate={composeValidators(required)}>
                                  {({ input, meta }) => (
                                    <div className="form-label-group mb-4">
                                      <select {...input} className="form-select" type="text" component="select">
                                        <option value={"ND"}>País de Residência</option>
                                          {this.props.location.state.listaPaises.map((option) => (
                                          <option value={option.code} key={option.code}>{option.name}</option>
                                          ))}
                                      </select>
                                    {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                    </div>
                                  )}
                                </Field>
                              </div>
                            </div>
                          ))}
                          <div className="row mt-6">
                            <div className="col-lg-6">
                              <Field name="informacaoContratual" type="checkbox" validate={required}>
                                {({ input, meta }) => (
                                  <div className="form-check mb-2">
                                    <input {...input} id="informacaoContratual" className="form-check-input" defaultValue="false"/>
                                    <label htmlFor="informacaoContratual" style={{ lineHeight: 1.3, fontSize: '12px' }}>Li e aceito a informação contratual do <a href={this.state.predictable.informationPdf} target="_blank"><strong>Seguro de Viagem</strong> Internacional Select</a></label>
                                    {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                  </div>
                                )}
                              </Field>
                              <Field name="politicaPrivacidade" type="checkbox" validate={required}>
                                {({ input, meta }) => (
                                  <div className="form-check mb-2">
                                    <input {...input} id="politicaPrivacidade" className="form-check-input" defaultValue="false"/>
                                    <label htmlFor="politicaPrivacidade" style={{ lineHeight: 1.3, fontSize: '12px' }}>Li e aceito a <a href="https://www.nacionalgest.pt/termos-e-condicoes/" target="_blank">Política de Privacidade</a></label>
                                    {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                  </div>
                                )}
                              </Field>

                            </div>
                            <div className="col-lg-6">
                              <Field name="condicoesGerais" type="checkbox" validate={required}>
                                {({ input, meta }) => (
                                  <div className="form-check mb-2">
                                    <input {...input} id="condicoesGerais" className="form-check-input" defaultValue="false"/>
                                    <label htmlFor="condicoesGerais" style={{ lineHeight: 1.3, fontSize: '12px' }}>Li e aceito as condições gerais do <a href={this.state.predictable.generalConditionsPdf}><strong>Seguro de Viagem</strong> Internacional Select</a></label>
                                    {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                  </div>
                                )}
                              </Field>
                              <Field name="marketing" type="checkbox" >
                                {({ input, meta }) => (
                                  <div className="form-check mb-2">
                                    <input {...input} id="marketing" className="form-check-input" defaultValue="false"/>
                                    <label htmlFor="marketing" style={{ lineHeight: 1.3, fontSize: '12px' }}>Autorizo que a NacionalGest utilize os meus dados para ofertas comerciais</label>
                                    {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                  </div>
                                )}
                              </Field>
                            </div>
                          </div>
                          <h1>{submitError && <div className="help-block with-errors">{submitError}</div>}</h1>
                          <div className="row mt-6">
                            <div className="col-lg-12">
                              <button className="btn btn-primary rounded-0 btn-send" style={{ width: '100%' }} disabled={submitting || pristine || this.state.fromAnotherCountry}>Continuar para pagamento</button>
                            </div>
                          </div>
                          </form>
                          )}
                      />
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
              )}
                <div className="card">
                  <div className="card-body">
                    <div className="classic-view">
                      <article className="post">
                        <h2 className="mb-3">Coberturas</h2>
                        <div className="post-content mb-5">
                        {this.state.predictable.warranty.map((option) =>
                          <>
                          <h4 className="h4 mt-8 mb-4 text-green">{option.warrantyGroupName}</h4>
                          {option.items.map((option_2) => (
                            <>
                            <div className="d-flex flex-row-coberturas">
                							<div className="flex-column">
                								<span style={{fontSize:'14px'}}>{option_2.name} </span>
                							</div>
                							<div className="flex-column">
    								            <span style={{fontSize:'14px'}}>€ {option_2.value} </span>
                							</div>
                						</div>
                            <hr className="mt-2 mb-2"/>
                            </>
                          ))}
                      </>)}

                        </div>
                        {/* /.post-content */}

                        {/* /.post-footer */}
                        <button onClick={() => (window.location.assign(this.state.paymentIdLink))} id="pagarSeguro" style={{display:'none'}}>Pagar</button>
                      </article>
                      {/* /.post */}
                    </div>
                    {/* /.comment-form */}
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
              </div>
              {/* /.blog */}
            </div>
            {/* /column */}
            <aside className="col-lg-4 sidebar mt-lg-6 mb-6">
								<div className="pricing card">
									<div className="card-body pb-4">
										<div className="prices text-dark">
                      <h2 className="card-title mt-2 mb-4">Resumo da sua viagem </h2>
                    </div>
                    <div className="d-flex flex-row">
        							<div className="flex-column-viagem">
                        <span style={{fontSize: '11px'}}>Tipo de Férias: </span>
                        {this.props.location.state.subtipo_viagem === '' ? (
                          <span>Lazer - Avião + Hotel / Só Avião</span>
                        ) : (
        								<span>Lazer - {this.props.location.state.subtipo_viagem}</span>
                        )}
        							</div>
        						</div>
                    <hr className="m-2"/>
                    <div className="d-flex flex-row">
        							<div className="flex-column-viagem">
                        <span style={{fontSize: '11px'}}>Número de Pessoas: </span>
        								<span>{this.props.location.state.data.paxQty} </span>
        							</div>
        						</div>
                    <hr className="m-2"/>
                    <div className="d-flex flex-row">
        							<div className="flex-column-viagem">
                        <span style={{fontSize: '11px'}}>Partida de: </span>
        								<span style={{fontSize:'14px'}}>{this.props.location.state.data.fromCountry[0].name} </span>
        							</div>
        							<div className="flex-column-viagem">
                        <span style={{fontSize: '11px'}}>Destino(s): </span>
                          {this.props.location.state.data.toCountry.map((option) => (
								            <span style={{fontSize:'14px'}}>{option.name} </span>
                          ))}
        							</div>
        						</div>
                    <hr className="m-2"/>
                    <div className="d-flex flex-row">
        							<div className="flex-column-viagem">
                        <span style={{fontSize: '11px'}}>Data de partida: </span>
        								<span>{this.props.location.state.data.fromDate} </span>
        							</div>
        							<div className="flex-column-viagem">
                        <span style={{fontSize: '11px'}}>Data de chegada: </span>
        								<span>{this.props.location.state.data.toDate} </span>
        							</div>
        						</div>
                    <hr className="m-2"/>
                        {this.props.location.state.terrorismo && (
                          <>
                        <div className="d-flex flex-row">
            							<div className="flex-column-viagem">
                            <span style={{fontSize: '11px'}}>Inclui: </span>
            								<span style={{fontSize:'14px'}}>Cobertura de pré-existências médica e terrorismo </span>
            							</div>
            						</div>
                    <hr className="m-2" />
                    </>
                        )}
                    {this.props.location.state.data.paxQty > 1 ? (
                      <h6>Valor final para {this.props.location.state.data.paxQty} pessoas:</h6>
                    ) : (
                      <h6>Valor final para {this.props.location.state.data.paxQty} pessoa:</h6>
                    )}
                    <h2 className="text-green">€{this.props.location.state.data.totalAmount}</h2>
                    { !this.state.showForm && (
                      <button className="btn btn-primary rounded-0 btn-send" style={{width:'100%'}} onClick={()=>{this.handleShowForm()}}>Contratar seguro</button>
                    )}
									</div>
							</div>
            </aside>
            {/* /column .sidebar */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
          <RequestContact />
            {loading === true && (
              <div className="loadingio-spinner-ellipsis-9w2r0d892j full-spinner"><div className="ldio-o8u2rr79tje">
              <div></div><div></div><div></div><div></div><div></div>
              </div></div>
            )}
        </div>
        {/* /.content-wrapper */}
        <Footer />
        <div className="progress-wrap">
          <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
          </svg>
        </div>
        </div>
  )
}
};

export default withRouter(SimulacaoPredictable);
