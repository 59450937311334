import React from 'react';
import { useFormState } from 'react-final-form';
import axios from 'axios';
import { Field, FormSpy } from 'react-final-form';
import WizardSaude from '../../wizard/WizardSaude';
import CoberturasSaude from './CoberturasSaude';

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";


export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const verifyPoliticaPrivacidade = value => (value === true ? undefined : "Campo Obrigatório")

export const onSubmit = async values => {
  await sleep(300)
  window.alert(JSON.stringify(values, 0, 2))
}

export const Error = ({ name }) => (
  <Field
    name={name}
    subscription={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? <span>{error}</span> : null
    }
  />
)
// NORMALIZAR O TELEFONE
export const normalizePhoneNumber = (value) => {
  if (!value) return value;
  var onlyNums = value.replace(/[^\d]/g, "");
  return onlyNums;
};

const verifyPhoneNumber = (value) => {
  return value.length === 9 ? undefined : "Formato Incorreto";
}


// NORMALIZAR O CODIGO POSTAL
export const normalizePostalCode = (value) => {
  if (!value) return value;
  var onlyNums = value.replace(/[^\d\-]/g, "");
  return onlyNums;
};

export const verifyPostalCode = (value) => {
  let regexp = /(?<fourDigit>[0-9]{4})-(?<threeDigit>[0-9]{3})/g;
  return value.match(regexp) ? undefined : "Formato Incorreto";
}




// LIMITAR DATA MAXIMA DATA NASCIMENTO

var dtToday = new Date();
var month = dtToday.getMonth() + 1;     // getMonth() is zero-based
var day = dtToday.getDate();
var year = dtToday.getFullYear();
if(month < 10)
   month = '0' + month.toString();
if(day < 10)
   day = '0' + day.toString();
export var maxDate = year + '-' + month + '-' + day;


// -----------------------------------------------

// Converter string to date object
function parseDate(s) {
  var b = s.split(/\D/);
  return new Date(b[0], --b[1], b[2]);
}

// VERIFY CARTA DE CONDUCAO
var currentDate = new Date().getFullYear();
// const verifyCartaConducao = value => (parseDate(value).getFullYear() -  <= 13 ? "Campo com erros" : undefined)


export const required = value => (value ? undefined : 'Campo Obrigatório')
export const mustBeNumber = value => (isNaN(value) ? 'Must be a number' : undefined)
export const minValue = min => value => isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`
export const twoWords = min => value => (value.match(/(\w+)/g).length >= 2 ? undefined : 'Insira o nome completo')

const normalizeNIF = (value) => {
  if (!value) return value;
  var onlyNums = value.replace(/[^\d]/g, "");
  return onlyNums;
}


// ------------

export const nifSuccess = value => {
  if (!value ) {
    return "Campo obrigatório";
  }
  else if (isNaN(value) || value.length !== 9) {
    return "Formato Inválido";
  }
  else if (value.length === 9) {
    var result = value.charAt(7)*2 +
    value.charAt(6) * 3 +
    value.charAt(5) * 4 +
    value.charAt(4) * 5 +
    value.charAt(3) * 6 +
    value.charAt(2) * 7 +
    value.charAt(1) * 8 +
    value.charAt(0) * 9;

    var mod = result % 11;
    var controlDigit = 0;
    mod === 0 || mod === 1 ? controlDigit = 0 : controlDigit = 11 - mod;

    return controlDigit == value.charAt(8) ? undefined : "NIF incorreto";

  }
};



function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)



class SimulatorSaude extends React.Component {


  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.onStateChange = this.onStateChange.bind(this);
    this.state = {
      data: {},
      localidade:'',
      tarifas:[],
      premio:undefined,
    }
  }



  componentDidMount() {
    axios.get(`https://api.nacionalgest.pt/api/acidentesp/obter_info_seguro`, {
      headers:{
        'Authorization':`Token f386ee39a5e46a13a460709d0beaf4dd0fff1d90`
    }})
    .then(response => this.setState({
      cg_url: `https://api.nacionalgest.pt${response.data.file}`,
      premio: response.data.premio,
    }))
    .catch(function(error) {
      console.log(error);
    });
  }



  onFileChange = event => {
      // Update the state
      this.setState({ [event.target.name]: event.target.files[0] });
    };


  onStateChange = values => {
    this.setState({ data: values });
  };
  handleChange = e => this.setState({ [e.target.name]: e.target.value });



  async getLocalidade(values) {

    let regexp = /(?<fourDigit>[0-9]{4})-(?<threeDigit>[0-9]{3})/g;
    if (values !== undefined) {
      if ((values.match(regexp) !== null) && (!this.state.localidade)){
        var codPostal = values.replace(/-/g, "");
        const response = await axios.get('https://api.duminio.com/ptcp/ptapi60d599d14b73f0.17454259/' + codPostal);
      this.setState({
        localidade: response.data.Localidade,
        concelho: response.data.Concelho
      });
    }
  }
  }


  getMaxInicioSeguro() {
    var dtToday = new Date();
    var threeMontsFromToday = dtToday.setMonth(dtToday.getMonth()+2);     // getMonth() is zero-based
    var day = dtToday.getDate();
    var month = dtToday.getMonth();
    var year = dtToday.getFullYear();
    if(month < 10)
       month = '0' + month.toString();
    if(day < 10)
       day = '0' + day.toString();
    var result = year + '-' + month + '-' + day;
    return result;
  }

  render() {
    const maxInicioSeguro = this.getMaxInicioSeguro();

    return (
      <div id="formulario">
        <WizardSaude
          initialValues={{
            // nomeCompleto: "Luís Silva",
            // morada: "Rua de Teste",
            // nif:'209094362',
            // localidade: "Lagos",
            // dataNascimento: "1990-09-28",
            // telemovel: "935881809",
            // email: "luisandremarreirossilva@gmail.com",
            // // credor:"Nao",
            // // matricula: "83-DG-33",
            // politicaPrivacidade:true,
            // codigoPostal:'8600-281',



            marketing: false,
        }}
        onSubmit={onSubmit}
        onStateChange={this.onStateChange}
        page={this.props.page}
        parentState={this.state}>
        <WizardSaude.Page>
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12">
                <h4 className="mb-2">Dados Pessoais</h4>
                <Field id="nomeCompleto" name="nomeCompleto" validate={composeValidators(required, twoWords(2))}>
                  {({ input, meta }) => (
                    <div className="form-label-group mb-4">
                      <input {...input} className="form-control" type="text" component="input" placeholder="Nome Completo" />
                      <label htmlFor="nomeCompleto">Nome Completo</label>
                      {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                    </div>
                  )}
                </Field>
              </div>
            </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-label-group mb-4">
                <Field id="nifInput" name="nif" validate={composeValidators(required, nifSuccess)} parse={normalizeNIF}>
                  {({ input, meta }) => (
                    <div className="form-label-group mb-4">
                      <input {...input} className="form-control" type="text" component="input" placeholder="NIF" />
                      <label htmlFor="nifInput">NIF</label>
                      {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                    </div>
                  )}
                </Field>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-label-group mb-4">
                <Field name="dataNascimento" validate={composeValidators(required)}>
                  {({ input, meta }) => (
                    <div className="form-label-group mb-4">
                      <input {...input} id="dataNascimentoInput" className="form-control" type="date" component="input" placeholder="Data de Nascimento" max={maxDate}/>
                      <label htmlFor="dataNascimentoInput">Data de Nascimento</label>
                      {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                    </div>
                  )}
                </Field>
              </div>
            </div>
          </div>
          <div className="row">
            <h4 className="mb-2">Contactos</h4>
            <div className="col-md-5">
              <Field name="telemovel" validate={composeValidators(required, verifyPhoneNumber)} parse={normalizePhoneNumber}>
                {({ input, meta }) => (
                  <div className="form-label-group mb-4">
                    <input {...input} id="telemovelInput" className="form-control" type="text" component="input" placeholder="Telemóvel" maxLength="9"/>
                    <label htmlFor="telemovelInput">Telemóvel</label>
                    {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                  </div>
                )}
              </Field>
          </div>
          <div className="col-md-7">
              <Field id="emailInput" name="email" validate={required}>
                {({ input, meta }) => (
                  <div className="form-label-group mb-4">
                    <input {...input} className="form-control" type="email" component="input" placeholder="E-mail" />
                    <label htmlFor="emailInput">E-mail</label>
                    {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                  </div>
                )}
              </Field>
          </div></div>
        </div>
        <div className="col-md-6">
          <h4 className="mb-2">Morada</h4>
            <div className="row">
              <div className="col-md-12">
                <Field id="moradaInput" name="morada" validate={required}>
                  {({ input, meta }) => (
                    <div className="form-label-group mb-4">
                      <input {...input} className="form-control" type="text" component="input" placeholder="Morada" />
                      <label htmlFor="moradaInput">Morada</label>
                      {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                    </div>
                  )}
                </Field>
              </div>
          </div>
          <div className="row">
            <div className="col-md-6">
                <Field id="codigoPostalInput" name="codigoPostal" validate={composeValidators(required, verifyPostalCode)} parse={normalizePostalCode} onChange={this.handleChange}>
                  {({ input, meta }) => (
                    <div className="form-label-group mb-4">
                      <input {...input} className="form-control" type="text" component="input" placeholder="Código Postal" maxLength="8" />
                      <label htmlFor="codigoPostalInput">Código Postal</label>
                      {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                    </div>
                  )}
                </Field>
            </div>
            <div className="col-md-6">
              <div className="form-label-group mb-4">
                <input className="form-control" id="localidade" name="localidade" type="text" placeholder="Localidade" defaultValue={this.state.localidade} onChange={this.handleChange} required/>
                <label htmlFor="localidade">Localidade</label>
              </div>
            </div>
          </div>
        </div>
        </div>
          <Field name="politicaPrivacidade" type="checkbox" validate={verifyPoliticaPrivacidade}>
            {({ input, meta }) => (
              <div className="form-check mb-5">
                <input {...input} id="politicaPrivacidade" className="form-check-input"/>
                <label htmlFor="politicaPrivacidade">Tomei conhecimento e concordo com os <a href="https://www.nacionalgest.pt/termos-e-condicoes/" target="_blank" rel="noreferrer">Termos e Condições</a> e <a href="https://www.nacionalgest.pt/termos-e-condicoes/" target="_blank" rel="noreferrer">Política de Privacidade</a> da NacionalGest Consultores de Seguros, Lda.</label>
                {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
              </div>
            )}
          </Field>
          <FormSpy subscription={{ values: true }}
            onChange={(props) => {
              this.getLocalidade(props.values.codigoPostal);
					}}></FormSpy>
        </WizardSaude.Page>
        <WizardSaude.Page>
          <CoberturasSaude />
        </WizardSaude.Page>
        <WizardSaude.Page>
          <div className="row" style={{display:'flex', alignItems:'center'}}>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-md-8">
                  <h6 className="card-title">Data de Início do Seguro</h6>
                    <Field name="dataInicioSeguro" validate={composeValidators(required)}>
                      {({ input, meta }) => (
                        <div className="form-label-group mb-4">
                          <input {...input} id="dataInicioSeguro" className="form-control" type="date" component="input" placeholder="Data de Início" min={maxDate}/>
                          <label htmlFor="dataInicioSeguro">Data de Início</label>
                          {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                        </div>
                      )}
                    </Field>
                </div>
              </div>
            </div>
            <div className="col-md-4">
                  <div className="row preco-base">
                    <h2 style={{marginBottom:'0px'}}>Prémio Mensal: </h2>
                    <h1 className="text-green">10€</h1>
                  </div>
            </div>
          </div>
            <hr className="my-4" />
              <div className="row">
                <div className="col-lg-12">
                  <Field name="marketing" type="checkbox" >
                    {({ input, meta }) => (
                      <div className="form-check mb-2">
                        <input {...input} id="marketing" className="form-check-input" defaultValue="false"/>
                        <label htmlFor="marketing" style={{ lineHeight: 1.3 }}>Subscreva a nossa newsletter mensal com informações relevantes sobre seguros.</label>
                        {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                      </div>
                    )}
                  </Field>
                </div>
              </div>
        </WizardSaude.Page>
      </WizardSaude></div>
    )
  }
}

  export default SimulatorSaude;
