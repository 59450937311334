import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Footer from '../../../Footer';
import Header from '../../../Header';
import RequestContact from '../../../RequestContact';
import SimuladorFormAPSurf from './SimuladorFormAPSurf';


class SimuladorAPSurf extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
    <div>
    <div className="content-wrapper">
          <Header />
          {/* /header */}
          <section className="wrapper bg-light wrapper-border">
            <div className="container py-14 pt-12 pb-4">
              <h2 className="fs-18 text-uppercase text-green mb-3">SIMULADOR</h2>
              <div className="row gx-lg-8 mb-10 gy-5">
                <div className="col-lg-5">
                  <h3 className="display-5 mb-0">Seguro Acidentes Pessoais Surf</h3>
                </div>
                {/* /column */}
                <div className="col-lg-7">
                  <p className="lead mb-5">Garante os riscos de acidente a todas as Pessoas Seguras utentes das pranchas de Surf do Tomador do Seguro, durante a prática do desporto, nas praias indicadas no contrato de seguro e durante o período de abertura ao público da Escola de Surf.</p>
                </div>
                {/* /column */}
              </div>
            </div>
          </section>
          <SimuladorFormAPSurf />
          <RequestContact />
        </div>
        {/* /.content-wrapper */}
        <Footer />
        <div className="progress-wrap">
          <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
          </svg>
        </div>
        </div>
  )
}
};

export default SimuladorAPSurf;
