import React from 'react';
import { useFormState } from 'react-final-form';
import axios from 'axios';
import ReactDOM from 'react-dom';
import Footer from '../Footer';
import Header from '../Header';
import RequestContact from '../RequestContact';
import { Form, Field, FormSpy } from 'react-final-form';
import {withRouter} from 'react-router-dom'
import {
  Link
} from "react-router-dom";


const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
export const required = value => (value ? undefined : 'Campo Obrigatório')

function Spinner() {
  return (
      <div className="loadingio-spinner-ellipsis-9w2r0d892j full-spinner"><div className="ldio-o8u2rr79tje">
      <div></div><div></div><div></div><div></div><div></div>
      </div></div>
  )
}

class InscricaoEvento extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      formSubmitted: false,
      numInscritos: 0,
      error: null,
      menor_120: true
    }
  }


  async getNumeroInscritos() {
    const dev_token = 'f386ee39a5e46a13a460709d0beaf4dd0fff1d90';
    await axios.get(`https://api.nacionalgest.pt/api/eventos/obter_inscritos/`, {
      headers:{
        'Authorization':`Token ${dev_token}`,
      }
    }).then((response) => {
        if (response.status === 200) {
          // MENOR QUE 120 ??
          console.log(response.data);
          this.setState({
            loading:false,
            menor_120: response.data.contagem
            // formSubmitted: true
          })
      }
    })
}

  componentDidMount() {
    this.getNumeroInscritos()
  }

  onSubmit = async values => {
    await sleep(300)
    const dev_token = 'f386ee39a5e46a13a460709d0beaf4dd0fff1d90';
    this.setState({loading: true});
    const comp = values.comparencia === "Sim" ? true : false;
    const relacao = values.tipo_relacao === "Seguradoras" ? values.seguradora : values.loja;
    await axios.post(`https://api.nacionalgest.pt/api/eventos/teambuilding_inscricao/`, {
      tipo_relacao: values.tipo_relacao,
      nome: values.nome,
      telemovel: values.telemovel,
      email: values.email,
      loja: relacao,
      comparencia: comp,
    }, {
      headers:{
        'Authorization':`Token ${dev_token}`,
  }}).then((response) => {
    console.log(response.data);
    if (response.status === 200) {
      if (response.data.error) {
        this.setState({
          error:response.data.error,
          loading: false
        })
      } else {
      this.setState({
        loading:false,
        formSubmitted: true
      })
    }
  }
  }).catch((error) => {
    this.setState({
      loading: false,
    });
    alert(error);
  });
  }

  handleSubmit = values  => {
      return this.onSubmit(values)
  }

  render() {


    return (
      <div>
      <div className="content-wrapper">
            <Header />
            {/* /header */}
            <section className="wrapper bg-light">
        			<div className="container pt-10 pb-8 pt-md-14 pb-md-12 text-center">
        				<div className="row">
        					<div className="col-lg-8 mx-auto">
        						<h1 className="display-1 mb-3">Team Building 2022</h1>
        					</div>
        				</div>
        			</div>
        		</section>
        		<section className="wrapper bg-light">
            <div className="container">
        			<div className="card shadow-lg mb-15 mb-md-14">
        				<div className="row gx-0">
        					<div className="col-lg-6">
        						<div className="p-10 p-md-11 p-lg-13">
                    {this.state.error === 1 && (
                      <>
                      <h1>Já respondeu a este formulário!</h1>
                      <p>Uma resposta com este e-mail já foi submetida neste formulário. </p>
                      <p>Se acredita que esta situação possa estar errada, entre em contacto connosco. </p>
                      </>
                    )}
                    {this.state.error === 2 || !this.state.menor_120 && (
                      <>
                      <h1>Limite de inscrições atingido!</h1>
                      <p>Todas as vagas disponíveis para este Team Building foram preenchidas. </p>
                      <p>Se acredita que esta situação possa estar errada, entre em contacto connosco. </p>
                      </>
                    )}
                    {this.state.formSubmitted && (
                      <>
                      <h1>Obrigado por responder!</h1>
                      <p>Mais perto da data voltaremos a entrar em contacto com mais pormenores. </p>
                      </>
                    )}
                    {!this.state.formSubmitted && this.state.error === null && this.state.menor_120 && (
                    <Form
                    onSubmit={this.handleSubmit}
                    initialValues={{
                      tipo_relacao: 'Colaborador',
                      loja: 'aljezur',
                      comparencia: 'Sim',
                     }}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-12">
                            <h6 className="mb-3">Tipo de Relação</h6>
                              <div className="form-label-group">
                                <div className="form-check">
                                <label className="form-check-label" htmlFor="tipo_relacao" style={{fontSize: '16px'}}>
                                <Field
                                  className="form-check-input"
                                  name="tipo_relacao"
                                  component="input"
                                  type="radio"
                                  value="Colaborador"
                                  required
                                />{' '}
                                Colaborador
                              </label>
                              </div>
                            </div>
                            <div className="form-label-group">
                              <div className="form-check">
                                <label className="form-check-label" htmlFor="tipo_relacao" style={{fontSize: '16px'}}>
                                <Field
                                  className="form-check-input"
                                  name="tipo_relacao"
                                  component="input"
                                  type="radio"
                                  value="Parceiro"
                                />{' '}
                                Parceiro
                              </label>
                              </div>
                            </div>
                            <div className="form-label-group">
                              <div className="form-check mb-4">
                                <label className="form-check-label" htmlFor="estadoVeiculo" style={{fontSize: '16px'}}>
                                <Field
                                  className="form-check-input"
                                  name="tipo_relacao"
                                  component="input"
                                  type="radio"
                                  value="Seguradoras"
                                />{' '}
                                Seguradoras
                              </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            {values.tipo_relacao === "Seguradoras" && (
                              <>
                            <h6 className="mb-3">Seguradora</h6>
                              <Field id="seguradora" name="seguradora" validate={required}>
                                {({ input, meta }) => (
                                  <div className="form-label-group mb-4">
                                    <input {...input} className="form-control" type="text" component="input" placeholder="Seguradora" />
                                    <label htmlFor="seguradora">Nome da Seguradora</label>
                                    {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                  </div>
                                )}
                              </Field>
                              </>
                            )}
                            {(values.tipo_relacao === "Colaborador" || values.tipo_relacao === "Parceiro") && (
                              <>
                            <h6 className="mb-3">Unidade</h6>
                              <Field id="loja" name="loja">
                                {({ input, meta }) => (
                                  <div className="form-label-group mb-4">
                                    <select {...input} className="form-select" type="text" component="select">
                                        <option value="dep_financeiro">Departamento Financeiro</option>
                                        <option value="aljezur">Aljezur</option>
                                        <option value="barreiro">Barreiro</option>
                                        <option value="campo_maior">Campo Maior</option>
                                        <option value="cantanhede">Cantanhede</option>
                                        <option value="coimbra">Coimbra</option>
                                        <option value="elvas">Elvas</option>
                                        <option value="esposende">Esposende</option>
                                        <option value="estremoz">Estremoz</option>
                                        <option value="faro">Faro</option>
                                        <option value="faro_mercado">Faro Mercado</option>
                                        <option value="figueira_castelo_rodrigo">Figueira de Castelo Rodrigo</option>
                                        <option value="guarda">Guarda</option>
                                        <option value="lagoa">Lagoa</option>
                                        <option value="lagos">Lagos</option>
                                        <option value="loule">Loulé</option>
                                        <option value="odivelas">Odivelas</option>
                                        <option value="oeiras">Oeiras</option>
                                        <option value="olhao">Olhão</option>
                                        <option value="portalegre">Portalegre</option>
                                        <option value="portimao">Portimão</option>
                                        <option value="porto">Porto</option>
                                        <option value="quarteira">Quarteira</option>
                                        <option value="sagres">Sagres</option>
                                        <option value="samora_correia">Samora Correia</option>
                                        <option value="sao_teotonio">São Teotónio</option>
                                        <option value="serpa">Serpa</option>
                                        <option value="vidigueira">Vidigueira</option>
                                        <option value="vila_conde">Vila do Conde</option>
                                        <option value="v_r_s_antonio">Vila Real de Santo António</option>
                                    </select>
                                    {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                  </div>
                                )}
                              </Field>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <Field id="nome" name="nome" validate={required}>
                              {({ input, meta }) => (
                                <div className="form-label-group mb-4">
                                  <input {...input} className="form-control" type="text" component="input" placeholder="nome" />
                                  <label htmlFor="nome">Nome</label>
                                  {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                </div>
                              )}
                            </Field>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <Field id="email" name="email" validate={required}>
                              {({ input, meta }) => (
                                <div className="form-label-group mb-4">
                                  <input {...input} className="form-control" type="email" component="input" placeholder="E-mail" />
                                  <label htmlFor="email">E-mail</label>
                                  {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                </div>
                              )}
                            </Field>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <Field id="telemovel" name="telemovel" validate={required}>
                              {({ input, meta }) => (
                                <div className="form-label-group mb-4">
                                  <input {...input} className="form-control" type="text" component="input" placeholder="Telemóvel" />
                                  <label htmlFor="telemovel">Telemóvel</label>
                                  {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                </div>
                              )}
                            </Field>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-label-group">
                            <div className="form-check">
                              <label className="form-check-label" htmlFor="comparencia" style={{fontSize: '16px'}}>
                              <Field
                                className="form-check-input"
                                name="comparencia"
                                component="input"
                                type="radio"
                                value="Sim"
                              />{' '}
                              Vou ao evento
                            </label>
                            </div>
                            <div className="form-check mb-4">
                              <label className="form-check-label" htmlFor="comparencia" style={{fontSize: '16px'}}>
                              <Field
                                className="form-check-input"
                                name="comparencia"
                                component="input"
                                type="radio"
                                value="Nao"
                              />{' '}
                              Não vou ao evento
                            </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12" style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <button type="submit" style={{width:'100%'}} className="btn btn-primary rounded-0 btn-send mb-3" disabled={submitting || pristine}>
                              Submeter
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  />)}
              </div>
        					</div>
                  {!this.state.formSubmitted && (
        					<div className="col-lg-6">
        						<div className="p-10 p-md-11 p-lg-13">
        							<h2 className="display-4 mb-3">Inscrição</h2>
        							<p className="lead fs-lg">Data: 1 de Outubro de 2022</p>
        							<p>Inscreva-se aqui para o nosso Team Building 2022 que irá ter lugar em Vilamoura no dia 1 de Outubro de 2022.</p>
        							<p>Contamos com todos sem exceção para celebrar os nossos sucessos e conquistas.<br /> A vida não é só trabalho!</p>
        							<p style={{fontSize:'13px'}}>* Responder a todas as perguntas por favor.</p>
        						</div>
        					</div>
                )}
        				</div>
        			</div>
        		</div>
          </section>
            <RequestContact />
          </div>
          {/* /.content-wrapper */}
          <Footer />
            {this.state.loading && (
              <Spinner />
            )}
          <div className="progress-wrap">
            <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
              <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
            </svg>
          </div>
          </div>
  )
}
}

export default withRouter(InscricaoEvento);
