import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import LoginParceiros from '../LoginParceiros';
import HeaderParceiros from '../HeaderParceiros';
import FooterParceiros from '../FooterParceiros';
import ReactDOM from 'react-dom';

import seguroMoto from '../../../assets/img/seguros/seguro-moto.jpg';

import automovel1 from '../../../assets/img/seguros/seguro-automovel.jpeg';
import automovel2 from '../../../assets/img/seguros/seguro-automovel1.jpeg';
import automovel3 from '../../../assets/img/seguros/seguro-automovel2.jpeg';
import seguroAP from '../../../assets/img/seguros/seguro-ap.jpeg';
import motoImage from '../../../assets/img/seguros/seguro-moto.jpg';
import seguroCacador from '../../../assets/img/seguros/seguro-cacador.jpg';
import seguroAPDesp from '../../../assets/img/seguros/acidentes-pessoais-desportivo.jpeg';
import seguroBarco from '../../../assets/img/seguros/seguro-barco.jpeg';
import seguroSaude from '../../../assets/img/seguros/seguro-saude.jpeg';
import rcJunior from '../../../assets/img/seguros/rc-junior.jpeg';
import rcCaes from '../../../assets/img/seguros/seguro-animais.jpg';
import seguroViagem from '../../../assets/img/seguros/seguro-viagem-barato-ferias.jpeg';

import {
  Link
} from "react-router-dom";


class ParticularesParceiros extends Component {

  getToken() {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token
  }
  getNomeParceiro() {
    const nomeParceiro = sessionStorage.getItem('nomeParceiro');
    return nomeParceiro
  }
  getEmailParceiro() {
    const emailParceiro = sessionStorage.getItem('emailParceiro');
    return emailParceiro
  }

  render() {

    const nomeParceiro = this.getNomeParceiro();
    const emailParceiro = this.getEmailParceiro();
    const token = sessionStorage.getItem('token');
    if(!token) {
      return <Redirect to="/parceiros" />
    }
    // OBTER DETALHES PARCEIRO ATRAVES DO TOKEN DE AUTH
    if(token) {
    return (
      <div>
      <HeaderParceiros
        nomeParceiro={nomeParceiro}
        emailParceiro={emailParceiro}
      />
        <section className="wrapper">
    			<div className="container pt-8 pb-10 pt-md-14 pb-md-14 text-center">
    				<div className="row">
    					<div className="col-lg-8 mx-auto">
                <h1 className="display-5 mb-3 text-green">Parceiros</h1>
                <h4>{nomeParceiro} - {emailParceiro}</h4>
                <h1 className="display-2">Seguros Particulares</h1>
    					</div>
    				</div>
    			</div>
    		</section>
      <div className="content-wrapper">
    		<section className="wrapper">
    			<div className="container py-14 pt-md-8 pt-5 pb-md-16">
            <div className="row grid-view gx-md-8 gx-xl-10 gy-8 gy-lg-0 mb-md-10" style={{justifyContent:'center'}}>
    					<div className="col-md-4 col-lg-4">
    						<div className="position-relative">
    							<div className="card">
                    <figure className="card-img-top overlay overlay1 hover-scale"><Link to={{ pathname: `/parceiros/particulares/motos`, state: {token:this.props.token, nomeParceiro: this.props.nomeParceiro, emailParceiro: this.props.emailParceiro}}}><img src={seguroMoto} alt="" /></Link>
                      <figcaption>
                        <h5 className="from-top mb-0">Simular</h5>
                      </figcaption>
                    </figure>
    								<div className="card-body px-6 py-5">
    									<h4 className="mb-1">Seguro Moto</h4>
    									<p className="mb-0">Resp. Civil, complementada com Assistência em Viagem e Proteção Jurídica, assim como os danos no veículo.</p>
    								</div>
                    <div id="simular-button" className="p-0 card-footer">
											<Link to={{ pathname: `/parceiros/particulares/motos`, state: {token:token, nomeParceiro: nomeParceiro, emailParceiro: emailParceiro}}} className="btn btn-sm btn-primary rounded-0">Simular</Link>
										</div>
    							</div>
    						</div>
    					</div>
    					<div className="col-md-4 col-lg-4">
    						<div className="position-relative">
    							<div className="card">
                    <figure className="card-img-top overlay overlay1 hover-scale"><a href="https://nacionalgest.parcerias.tranquilidade.pt/IOS_Auto/"> <img src={automovel2} alt="" /></a>
                      <figcaption>
                        <h5 className="from-top mb-0">Simular</h5>
                      </figcaption>
                    </figure>
    								<div className="card-body px-6 py-5">
    									<h4 className="mb-1">Seguro Automóvel</h4>
    									<p className="mb-0">O Seguro Automóvel garante a segurança e assistência do condutor e dos ocupantes durante toda a viagem.</p>
    								</div>
                    <div id="simular-button" className="p-0 card-footer">
											<a href="https://nacionalgest.parcerias.tranquilidade.pt/IOS_Auto/" target="_blank" className="btn btn-sm btn-primary rounded-0">Simular</a>
										</div>
    							</div>
    						</div>
    					</div>
              <div className="col-md-4 col-lg-4">
                <div className="position-relative">
                  <div className="card">
                    <figure className="card-img-top overlay overlay1 hover-scale"><Link to="/parceiros/particulares/viagem/simulador"> <img src={seguroViagem} alt="Seguro Viagem" /></Link>
                      <figcaption>
                        <h5 className="from-top mb-0">Simular</h5>
                      </figcaption>
                    </figure>
                    <div className="card-body px-6 py-5">
                      <h4 className="mb-1">Seguro de Viagem</h4>
                      <p className="mb-0"> Um <b><i>seguro de viagem barato</i></b>, abrangente e versátil que lhe permitirá ter uma viagem tranquila. Não arrisque!</p>
                    </div>
                    <div id="simular-button" className="p-0 card-footer">
                      <Link to="/parceiros/particulares/viagem/simulador" className="btn btn-sm btn-primary rounded-0">Simular</Link>
                    </div>
                  </div>
                </div>
              </div>
    				</div>
            <div className="row grid-view gx-md-8 gx-xl-10 gy-8 gy-lg-0 mb-md-10 pt-7" style={{justifyContent:'center'}}>
            <div className="col-md-4 col-lg-4">
              <div className="position-relative">
                <div className="card">
                  <figure className="card-img-top overlay overlay1 hover-scale"><Link to="/parceiros/particulares/saude"> <img src={seguroSaude} alt="Seguro Saúde" /></Link>
                    <figcaption>
                      <h5 className="from-top mb-0">Simular</h5>
                    </figcaption>
                  </figure>
                  <div className="card-body px-6 py-5">
                    <h4 className="mb-1">Seguro de Saúde - Acesso à Rede</h4>
                      <p className="mb-0"> O Seguro de saúde que o protege a si e à sua família, em qualquer momento da  sua vida. Acesso à rede hospitalar desde 10€/mês.</p>
                  </div>
                  <div id="simular-button" className="p-0 card-footer">
                    <Link to="/parceiros/particulares/saude" className="btn btn-sm btn-primary rounded-0">Simular</Link>
                  </div>
                </div>
              </div>
            </div>
              <div className="col-md-4 col-lg-4">
                <div className="position-relative">
                  <div className="card">
                    <figure className="card-img-top overlay overlay1 hover-scale"><Link to="/parceiros/particulares/acidentes-pessoais"> <img src={seguroAPDesp} alt="Seguro Acidentes Pessoais" /></Link>
                      <figcaption>
                        <h5 className="from-top mb-0">Simular</h5>
                      </figcaption>
                    </figure>
                    <div className="card-body px-6 py-5">
                      <h4 className="mb-1">Acidentes Pessoais Desportivo</h4>
                        <p className="mb-0">O Seguros de Acidentes Pessoais Desportivo protege-o na prática de atividades desportivas.</p>
                    </div>
                    <div id="simular-button" className="p-0 card-footer">
                      <Link to="/parceiros/particulares/acidentes-pessoais" className="btn btn-sm btn-primary rounded-0">Simular</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4">
                <div className="position-relative">
                  <div className="card">
                    <figure className="card-img-top overlay overlay1 hover-scale"><Link to="/parceiros/particulares/animais"> <img src={rcCaes} alt="Seguro Acidentes Pessoais" /></Link>
                      <figcaption>
                        <h5 className="from-top mb-0">Simular</h5>
                      </figcaption>
                    </figure>
                    <div className="card-body px-6 py-5">
                      <h4 className="mb-1">Seguro Animais de Companhia</h4>
                        <p className="mb-0">Um seguro simples para quem apenas se quer proteger contra possíveis danos causados pelos seus animais de companhia.</p>
                    </div>
                    <div id="simular-button" className="p-0 card-footer">
                      <Link to="/parceiros/particulares/animais" className="btn btn-sm btn-primary rounded-0">Simular</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row grid-view gx-md-8 gx-xl-10 gy-8 gy-lg-0 mb-md-10 pt-7">
            <div className="col-md-4 col-lg-4">
              <div className="position-relative">
                <div className="card">
                  <figure className="card-img-top overlay overlay1 hover-scale"><Link to="/parceiros/particulares/embarcacoes"> <img src={seguroBarco} alt="Seguro Embarcações" /></Link>
                    <figcaption>
                      <h5 className="from-top mb-0">Simular</h5>
                    </figcaption>
                  </figure>
                  <div className="card-body px-6 py-5">
                    <h4 className="mb-1">Seguro de Embarcações</h4>
                    <p className="mb-0">Aprecie ao máximo os seus momentos de lazer, no mar e em terra,  minimizando os imprevistos que possam acontecer.</p>
                  </div>
                  <div id="simular-button" className="p-0 card-footer">
                    <Link to="/parceiros/particulares/embarcacoes" className="btn btn-sm btn-primary rounded-0">Simular</Link>
                  </div>
                </div>
              </div>
            </div>
              <div className="col-md-4 col-lg-4">
                <div className="position-relative">
                  <div className="card">
                    <figure className="card-img-top overlay overlay1 hover-scale"><Link to="/parceiros/particulares/ap-junior"> <img src={rcJunior} alt="Seguro Acidentes Pessoais" /></Link>
                      <figcaption>
                        <h5 className="from-top mb-0">Simular</h5>
                      </figcaption>
                    </figure>
                    <div className="card-body px-6 py-5">
                      <h4 className="mb-1">Acidentes Pessoais Júnior</h4>
                        <p className="mb-0">O Seguro de Acidentes Pessoais para crianças e adolescentes. Protege a criança e também os acidentes causados a terceiros.</p>
                    </div>
                    <div id="simular-button" className="p-0 card-footer">
                      <Link to="/parceiros/particulares/ap-junior" className="btn btn-sm btn-primary rounded-0">Simular</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4">
                <div className="position-relative">
                  <div className="card">
                    <figure className="card-img-top overlay overlay1 hover-scale"><Link to="/parceiros/particulares/rc-cacador"> <img src={seguroCacador} alt="Seguro Acidentes Pessoais" /></Link>
                      <figcaption>
                        <h5 className="from-top mb-0">Simular</h5>
                      </figcaption>
                    </figure>
                    <div className="card-body px-6 py-5">
                      <h4 className="mb-1">Responsabilidade Civil Caçador</h4>
                      <p className="mb-0">As melhores soluções de proteção, com garantias que efetivamente lhe interessam na prática do seu desporto favorito.</p>
                    </div>
                    <div id="simular-button" className="p-0 card-footer">
                      <Link to="/parceiros/particulares/rc-cacador" className="btn btn-sm btn-primary rounded-0">Simular</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
		     </div>
       </section>
     </div>
     <FooterParceiros />
   </div>
    )
  }
  }
}
export default ParticularesParceiros;
