import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Footer from '../Footer';
import Header from '../Header';
import RequestContact from '../RequestContact';

import seguroImage from '../../assets/img/ng/particulares.jpeg';


import surfAP from '../../assets/img/seguros/surf-ap.jpg';
import surfRC from '../../assets/img/seguros/surf-rc.jpg';
import agenciaViagens from '../../assets/img/seguros/agencia-viagens.jpg';
import credito from '../../assets/img/seguros/seguro-credito.jpeg';
import imobiliarias from '../../assets/img/seguros/seguro-imobiliarias.jpeg';

import {
  Link
} from "react-router-dom";


class SegurosEmpresas extends Component {

  render() {
    return (
      <div className="content-wrapper">
        <Header />
          <section className="wrapper bg-gray">
      			<div className="container pt-10 pb-8 pt-md-14 pb-md-12 text-center">
      				<div className="row">
      					<div className="col-lg-8 mx-auto">
      						<h1 className="display-1 mb-3">Seguros Empresas</h1>
      						<nav className="d-inline-block" aria-label="breadcrumb">
      							<ol className="breadcrumb">
      								<li className="breadcrumb-item"><Link to="/">Loja Online</Link></li>
      								<li className="breadcrumb-item active">Seguros Empresas</li>
      							</ol>
      						</nav>
      					</div>
      				</div>
      			</div>
      		</section>
      		<section className="wrapper">
      			<div className="container py-14 pt-md-11 pt-11 pb-md-16">
                <div className="row text-center">
        					<div className="col-lg-9 col-xl-8 col-xxl-7 mx-auto">
        						<p className="mb-6">A Nacionalgest analisa as suas necessidades para lhe apresentar as melhores soluções do mercado, sendo que o nosso objetivo é que se sinta protegido em todos os momentos da sua vida. Somos parceiros de todos os seguradores do mercado e dispomos de uma vasta gama de seguros para a sua proteção pessoal, do seu património e das suas responsabilidades.</p>
        					</div>
        				</div>
                <div className="row grid-view gx-md-8 gx-xl-10 gy-8 gy-lg-0 mb-md-10 pt-7" style={{justifyContent:'center'}}>
        					<div className="col-md-4 col-lg-4">
        						<div className="position-relative">
        							<div className="card">
                        <figure className="card-img-top overlay overlay1 hover-scale"><Link to="seguros-empresas/surf/rc/simulador"> <img src={surfRC} alt="Seguro Surf RC" /></Link>
                          <figcaption>
                            <h5 className="from-top mb-0">Simular</h5>
                          </figcaption>
                        </figure>
        								<div className="card-body px-6 py-5">
        									<h4 className="mb-1">Responsabilidade Civil Surf</h4>
        									<p className="mb-0">Garante os Danos causados pelas pranchas utilizadas, danos causados a terceiros e a embarcações</p>
        								</div>
                        <div id="simular-button" className="p-0 card-footer">
    											<Link to="seguros-empresas/surf/rc/simulador" className="btn btn-sm btn-primary rounded-0">Simular</Link>
    										</div>
        							</div>
        						</div>
        					</div>
        					<div className="col-md-4 col-lg-4">
        						<div className="position-relative">
        							<div className="card">
                        <figure className="card-img-top overlay overlay1 hover-scale"><Link to="seguros-empresas/surf/ap/simulador"> <img src={surfAP} alt="Seguro Surf AP" /></Link>
                          <figcaption>
                            <h5 className="from-top mb-0">Simular</h5>
                          </figcaption>
                        </figure>
        								<div className="card-body px-6 py-5">
        									<h4 className="mb-1">Acidentes Pessoais Surf</h4>
                            <p className="mb-0">Garante os riscos de acidente a todas as Pessoas Seguras utentes das pranchas de Surf do Tomador do Seguro.</p>
        								</div>
                        <div id="simular-button" className="p-0 card-footer">
    											<Link to="seguros-empresas/surf/ap/simulador" className="btn btn-sm btn-primary rounded-0">Simular</Link>
    										</div>
        							</div>
        						</div>
        					</div>
                  <div className="col-md-4 col-lg-4">
        						<div className="position-relative">
        							<div className="card">
                        <figure className="card-img-top overlay overlay1 hover-scale"><Link to="seguros-empresas/rc/agencia-viagens/simulador"> <img src={agenciaViagens} alt="Seguro Moto" /></Link>
                          <figcaption>
                            <h5 className="from-top mb-0">Simular</h5>
                          </figcaption>
                        </figure>
        								<div className="card-body px-6 py-5">
        									<h4 className="mb-1">Responsabilidade Civil - Agências de Viagens</h4>
                            <p className="mb-0">Garante a responsabilidade civil no âmbito da atividade nos termos da legislação aplicável.</p>
        								</div>
                        <div id="simular-button" className="p-0 card-footer">
    											<Link to="seguros-empresas/rc/agencia-viagens/simulador" className="btn btn-sm btn-primary rounded-0">Simular</Link>
    										</div>
        							</div>
        						</div>
        					</div>
        				</div>
                <div className="row grid-view gx-md-8 gx-xl-10 gy-8 gy-lg-0 mb-md-10" style={{justifyContent:'center'}}>
        					<div className="col-md-4 col-lg-4">
        						<div className="position-relative">
        							<div className="card">
                        <figure className="card-img-top overlay overlay1 hover-scale"><Link to={{ pathname: `/seguros-empresas/rc/intermediarios-credito/simulador`, state: {token:this.props.token, nomeParceiro: this.props.nomeParceiro, emailParceiro: this.props.emailParceiro}}}><img src={credito} alt="" /></Link>
                          <figcaption>
                            <h5 className="from-top mb-0">Simular</h5>
                          </figcaption>
                        </figure>
        								<div className="card-body px-6 py-5">
        									<h4 className="mb-1">Intermediários de Crédito</h4>
        									<p className="mb-0">Um seguro adequado a todos aqueles que exercem a atividade como Intermediários de Crédito.</p>
        								</div>
                        <div id="simular-button" className="p-0 card-footer">
    											<Link to={{ pathname: `/seguros-empresas/rc/intermediarios-credito/simulador`, state: {token:this.props.token, nomeParceiro: this.props.nomeParceiro, emailParceiro: this.props.emailParceiro}}} className="btn btn-sm btn-primary rounded-0">Simular</Link>
    										</div>
        							</div>
        						</div>
        					</div>
        					<div className="col-md-4 col-lg-4">
        						<div className="position-relative">
        							<div className="card">
                        <figure className="card-img-top overlay overlay1 hover-scale"><Link to={{ pathname: `/seguros-empresas/rc/imobiliarias/simulador`, state: {token:this.props.token, nomeParceiro: this.props.nomeParceiro, emailParceiro: this.props.emailParceiro}}}><img src={imobiliarias} alt="" /></Link>
                          <figcaption>
                            <h5 className="from-top mb-0">Simular</h5>
                          </figcaption>
                        </figure>
        								<div className="card-body px-6 py-5">
        									<h4 className="mb-1">Responsabilidade Civil Mediadoras Imobiliárias</h4>
        									<p className="mb-0">Um seguro obrigatório para todos os que exercem a atividade como Mediadoras Imobiliárias.</p>
        								</div>
                        <div id="simular-button" className="p-0 card-footer">
    											<Link to={{ pathname: `/seguros-empresas/rc/imobiliarias/simulador`, state: {token:this.props.token, nomeParceiro: this.props.nomeParceiro, emailParceiro: this.props.emailParceiro}}} className="btn btn-sm btn-primary rounded-0">Simular</Link>
    										</div>
        							</div>
        						</div>
        					</div>
        				</div>
    		     </div>
           </section>
          {/*<section className="wrapper bg-light wrapper-border">
            <div className="container py-14 py-md-16">
              <h2 className="fs-15 text-uppercase text-muted text-center mb-8">Companhias com quem trabalhamos</h2>
              <div className="carousel owl-carousel clients" data-margin="30" data-loop="true" data-dots="false" data-autoplay="true" data-autoplay-timeout="3000" data-responsive='{"0":{"items": "2"}, "768":{"items": "4"}, "992":{"items": "5"}, "1200":{"items": "6"}, "1400":{"items": "7"}}'>
                <div className="item px-5"><img src={companhia1} alt="" /></div>
                <div className="item px-5"><img src={companhia2} alt="" /></div>
                <div className="item px-5"><img src={companhia3} alt="" /></div>
                <div className="item px-5"><img src={companhia4} alt="" /></div>
                <div className="item px-5"><img src={companhia5} alt="" /></div>
                <div className="item px-5"><img src={companhia6} alt="" /></div>
                <div className="item px-5"><img src={companhia7} alt="" /></div>
                <div className="item px-5"><img src={companhia8} alt="" /></div>
              </div>
            </div>
          </section>
          */}
        <RequestContact />
      <Footer />
    </div>
    )
  }
}
export default SegurosEmpresas;
