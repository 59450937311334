import React from 'react';
import { useFormState } from 'react-final-form';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import ReactDOM from 'react-dom';
import { Field, FormSpy } from 'react-final-form';
import WizardCertificadosParceiros from '../wizard/WizardCertificadosParceiros';
import Coberturas from '../Coberturas';
import HeaderParceiros from './HeaderParceiros';
import FooterParceiros from './FooterParceiros';
import LoginParceiros from './LoginParceiros';
import {
  Link
} from "react-router-dom";

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";



export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const verifyPoliticaPrivacidade = value => (value === true ? undefined : "Campo Obrigatório")
const verifyAceitacaoTermos = value => (value === true ? undefined : "A aceitação dos termos é obrigatória")

export const onSubmit = async values => {
  await sleep(300)
  window.alert(JSON.stringify(values, 0, 2))
}

export const Error = ({ name }) => (
  <Field
    name={name}
    subscription={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? <span>{error}</span> : null
    }
  />
)
// NORMALIZAR O TELEFONE
export const normalizePhoneNumber = (value) => {
  if (!value) return value;
  var onlyNums = value.replace(/[^\d]/g, "");
  return onlyNums;
};

const verifyPhoneNumber = (value) => {
  return value.length === 9 ? undefined : "Formato Incorreto";
}


// NORMALIZAR O CODIGO POSTAL
export const normalizePostalCode = (value) => {
  if (!value) return value;
  var onlyNums = value.replace(/[^\d\-]/g, "");
  return onlyNums;
};

export const verifyPostalCode = (value) => {
  let regexp = /(?<fourDigit>[0-9]{4})-(?<threeDigit>[0-9]{3})/g;
  return value.match(regexp) ? undefined : "Formato Incorreto";
}

export const verifyCategoriaCarta = (value) => {
  return value === "ND" ? "Seleccione uma categoria" : undefined;
}

// VERIFICAR ERROS LISTAGEM MARCAS, MODELOS E versoes

export const verifyBrands = (value) => {
  return value === "ND" ? "Seleccione uma marca" : undefined;
}
export const verifyModels = (value) => {
  return value === "ND" ? "Seleccione um modelo" : undefined;
}
export const verifyVersions = (value) => {
  return value === "ND" ? "Seleccione uma versao" : undefined;
}


// NORMALIZAR Data

export const verifyDataMatricula = (value) => {
  let regexp = /(?<ano>[0-9]{4})-(?<mes>[0-9]{2})-(?<dia>[0-9]{2})/g;
  return value.match(regexp) ? false : true;
}

// LIMITAR DATA MAXIMA DATA NASCIMENTO

var dtToday = new Date();
var month = dtToday.getMonth() + 1;     // getMonth() is zero-based
var day = dtToday.getDate();
var year = dtToday.getFullYear();
if(month < 10)
   month = '0' + month.toString();
if(day < 10)
   day = '0' + day.toString();
export var maxDate = year + '-' + month + '-' + day;


// -----------------------------------------------

// Converter string to date object
function parseDate(s) {
  var b = s.split(/\D/);
  return new Date(b[0], --b[1], b[2]);
}

// VERIFY CARTA DE CONDUCAO
var currentDate = new Date().getFullYear();
// const verifyCartaConducao = value => (parseDate(value).getFullYear() -  <= 13 ? "Campo com erros" : undefined)


export const required = value => (value ? undefined : 'Campo Obrigatório')
export const mustBeNumber = value => (isNaN(value) ? 'Must be a number' : undefined)
export const minValue = min => value => isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`
export const twoWords = min => value => (value.match(/(\w+)/g).length >= 2 ? undefined : 'Insira o nome completo')

const normalizeNIF = (value) => {
  if (!value) return value;
  var onlyNums = value.replace(/[^\d]/g, "");
  return onlyNums;
}


const Condition = ({ when, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value !== '' ? children : null)}
  </Field>
)
const ConditionVeiculoNaoListado = ({ when, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === true ? children : null)}
  </Field>
)
const ConditionVeiculoListado = ({ when, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value !== true ? children : null)}
  </Field>
)

export const ibanSuccess = value => {
  if (!value ) {
    return "Campo obrigatório";
  }
  else if (value.length !== 24) {
    console.log(value.length);
    return "Formato Inválido"
  }
}


export const nifSuccess = value => {
  if (!value ) {
    return "Campo obrigatório";
  }
  else if (isNaN(value) || value.length !== 9) {
    return "Formato Inválido";
  }
  else if (value.length === 9) {
    var result = value.charAt(7)*2 +
    value.charAt(6) * 3 +
    value.charAt(5) * 4 +
    value.charAt(4) * 5 +
    value.charAt(3) * 6 +
    value.charAt(2) * 7 +
    value.charAt(1) * 8 +
    value.charAt(0) * 9;

    var mod = result % 11;
    var controlDigit = 0;
    mod === 0 || mod === 1 ? controlDigit = 0 : controlDigit = 11 - mod;

    return controlDigit == value.charAt(8) ? undefined : "NIF incorreto";

  }
};

export const simpleMemoize = fn => {
  let lastArg;
  let lastResult;
  return arg => {
    if (arg !== lastArg) {
      lastArg = arg;
      lastResult = fn(arg);
    }
    return lastResult;
  };
};


function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)

// IBAN VALIDATION

const normalizeIBAN = value => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, "");

  if (onlyNums.length <= 4) return onlyNums;
  if (onlyNums.length <= 8)
    return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)}`;
  if (onlyNums.length <= 19)
    return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(
      8,
      19
    )}`;
  if (onlyNums.length <= 21)
    return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(
      8,
      19
    )} ${onlyNums.slice(19, 21)}`;
    return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(
      8,
      19
    )} ${onlyNums.slice(19, 21)}`
};



class SimulatorCertificados extends React.Component {


  constructor(props) {
    super(props);
    this.frenteLivreteRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeVeiculoEletrico = this.handleChangeVeiculoEletrico.bind(this);
    this.handleChangeVeiculoNaoListado = this.handleChangeVeiculoNaoListado.bind(this);
    this.onStateChange = this.onStateChange.bind(this);
    this.state = {
      franquia: '',
      planoCoberturas: 'Base',
      brands: [],
      models: [],
      versions: [],
      vehicleDetails: [],
      vehiclePesoBruto: undefined,
      vehicleValorNovo: '',
      vehicleValorAtual: '',
      vehiclePotencia: '',
      vehicleLugares: '',
      anoMatricula: '',
      data: {},
      localidade:'',
      credor:'Nao',
      tarifas:[],
      estadoVeiculo:'',
      veiculoEletrico:'',
      premio:undefined,
      cilindradaVeiculo:'',
      nomeCredor:'',
      vehicleCategoryDesignation:undefined,
      vehicleCategoryDescription:undefined,
      inserirCapital: false,

      // IBAN
      comprovativoIBAN: null,
      bicSwift:'',
      titularDiferente:false,
      fraccionamento: 'anual',
      capitalEstimadoUtilizador:'',
      veiculoNaoListado: false,
    }
  }
  toggleChange = () => {
    this.setState({
      titularDiferente: !this.state.titularDiferente,
    });
  }



  componentDidMount() {
    this.setState({ loadingTarifas: true}, () => {
      axios.get(`https://api.nacionalgest.pt/api/motos/obter_tarifas/`,{
        headers:{
          'Authorization':`Token f386ee39a5e46a13a460709d0beaf4dd0fff1d90`
      }})
      .then(response => this.setState({
        tarifas: response.data,
        loadingTarifas: false
      }))
      .catch(function(error) {
        console.log(error);
      });
    });
    axios.get(`https://api.nacionalgest.pt/api/motos/obter_condicoes_gerais`, {
      headers:{
        'Authorization':`Token f386ee39a5e46a13a460709d0beaf4dd0fff1d90`
    }})
    .then(response => (
      console.log(response.data),
      this.setState({
      cg_url: `https://api.nacionalgest.pt${response.data.file}`,
    })))
    .catch(function(error) {
      console.log(error);
    });
    axios.post(`https://api.nacionalgest.pt/api/motos/lista_marcas/`, {
        ano: '2021',
        mes: '08'
    }, {
        headers:{
          'Authorization':`Token f386ee39a5e46a13a460709d0beaf4dd0fff1d90`
        }
      })
  }


  onFileChange = event => {
      // Update the state
      this.setState({ [event.target.name]: event.target.files[0] });
    };

  async getIBAN(values) {
    if (values !== undefined) {
      if (values.length === 24 && this.state.banco !== null) {
        if (values.slice(0,4) === '0035') {
          this.setState({banco:'Caixa Geral de Depósitos',bicSwift:'CGDIPTPL'})
        } else if (values.slice(0,4) === '0018') {
        this.setState({banco:'Santander Totta',bicSwift:'TOTAPTPLXXX'});
        } else if (values.slice(0,4) === '0033') {
          this.setState({banco:'Millennium BCP',bicSwift:'BCOMPTPLXXX'});
        } else if (values.slice(0,4) === '0007') {
          this.setState({banco:'Novo Banco',bicSwift:'BESCPTPLXXX'});
        } else if (values.slice(0,4) === '0010') {
          this.setState({banco:'BPI',bicSwift:'BBPIPTPLXXX'});
        } else if (values.slice(0,4) === '0269') {
          this.setState({banco:'Bankinter',bicSwift:'BKBKPTPLXXX'});
        } else if (values.slice(0,4) === '0079') {
          this.setState({banco:'EuroBic',bicSwift:'BPNPPTPLXXX'});
        } else if (values.slice(0,4) === '0046') {
          this.setState({banco:'Banco Popular',bicSwift:'CRBNPTPLXXX'});
        } else if (values.slice(0,4) === '0036') {
          this.setState({banco:'Montepio',bicSwift:'MPIOPTPLXXX'});
        } else if (values.slice(0,4) === '0193') {
          this.setState({banco:'Banco CTT',bicSwift:'CTTVPTPLXXX'});
        } else if (values.slice(0,4) === '0019') {
          this.setState({banco:'BBVA',bicSwift:'BBVAPTPLXXX'});
        } else {
          this.setState({banco:'Não Identificado',bicSwift:'Não Identificado'});
        }
      }
    }
    };

  onStateChange = values => {
    this.setState({ data: values });
  };
  handleChange = e => this.setState({ [e.target.name]: e.target.value });
  handleChangeVeiculoEletrico = e => {
    e.target.value === "Não" ? this.setState({
      [e.target.name]: e.target.value,
      cilindradaVeiculo: '',}) : this.setState({
        [e.target.name]: e.target.value,
        cilindradaVeiculo: '0',});
  }
  handleClickInserirCapital = e => this.setState({inserirCapital : true});
  handleChangeVeiculoNaoListado = e => this.setState({ veiculoNaoListado: e.target.checked });

  calcPremio = (capitalEstimado) => {
    console.log('capital:' + capitalEstimado);
    let franquia = this.state.franquia;
    let planoCoberturas = this.state.planoCoberturas;
    let valorVeiculo = this.state.vehicleValorAtual;
    let cilindrada = this.state.cilindradaVeiculo;
    let tarifas = this.state.tarifas;
    let estadoVeiculo = this.state.estadoVeiculo;
    let mapTarifas = new Map(tarifas.map(i => [i.identificador, i.preco]));

    // MOTAS NOVAS COM PROTECAO PLUS
    if (planoCoberturas === 'Base' && estadoVeiculo === 'Novo') {
      if (0 <= cilindrada && cilindrada < 126) {
        let valorPremio = parseFloat(mapTarifas.get('A1')).toFixed(2);
        return (valorPremio + "€")}
      else if (125 < cilindrada && cilindrada < 500) {
        return parseFloat(mapTarifas.get('A2')).toFixed(2)}
      else if (cilindrada >= 500) {
        return parseFloat(mapTarifas.get('A3')).toFixed(2)}
    }
    else if (planoCoberturas === 'Plus' && estadoVeiculo === 'Novo' && franquia === '10') {
      if (capitalEstimado !== '') {
        if (0 <= cilindrada && cilindrada < 126) {
          return (parseFloat(mapTarifas.get('B1'))*capitalEstimado+parseFloat(mapTarifas.get('A1'))).toFixed(2)}
        else if (125 < cilindrada && cilindrada < 500)  {
          return (parseFloat(mapTarifas.get('B2'))*capitalEstimado+parseFloat(mapTarifas.get('A2'))).toFixed(2)}
        else if (cilindrada >= 500) {
          return (parseFloat(mapTarifas.get('B3'))*capitalEstimado+parseFloat(mapTarifas.get('A3'))).toFixed(2)}
      } else {
        if (0 <= cilindrada && cilindrada < 126) {
          return (parseFloat(mapTarifas.get('B1'))*valorVeiculo+parseFloat(mapTarifas.get('A1'))).toFixed(2)}
        else if (125 < cilindrada && cilindrada < 500)  {
          return (parseFloat(mapTarifas.get('B2'))*valorVeiculo+parseFloat(mapTarifas.get('A2'))).toFixed(2)}
        else if (cilindrada >= 500) {
          return (parseFloat(mapTarifas.get('B3'))*valorVeiculo+parseFloat(mapTarifas.get('A3'))).toFixed(2)}
      }
    }
    else if (planoCoberturas === 'Plus' && estadoVeiculo === 'Novo' && franquia === 'Sem Franquia') {
      if (capitalEstimado !== '') {
        if (0 <= cilindrada && cilindrada < 126) {
          return (parseFloat(mapTarifas.get('C1'))*capitalEstimado+parseFloat(mapTarifas.get('A1'))).toFixed(2)}
        else if (125 < cilindrada && cilindrada < 500)  {
          return (parseFloat(mapTarifas.get('C2'))*capitalEstimado+parseFloat(mapTarifas.get('A2'))).toFixed(2)}
        else if (cilindrada >= 500) {
          console.log('here');
          return (parseFloat(mapTarifas.get('C3'))*capitalEstimado+parseFloat(mapTarifas.get('A3'))).toFixed(2)}
      } else {
        if (0 <= cilindrada && cilindrada < 126) {
          return (parseFloat(mapTarifas.get('C1'))*valorVeiculo+parseFloat(mapTarifas.get('A1'))).toFixed(2)}
        else if (125 < cilindrada && cilindrada < 500)  {
          return (parseFloat(mapTarifas.get('C2'))*valorVeiculo+parseFloat(mapTarifas.get('A2'))).toFixed(2)}
        else if (cilindrada >= 500) {
          return (parseFloat(mapTarifas.get('C3'))*valorVeiculo+parseFloat(mapTarifas.get('A3'))).toFixed(2)}

      }
    }
    else if (planoCoberturas === 'Base' && estadoVeiculo === 'Usado') {
      if (0 <= cilindrada && cilindrada < 126) {
        return parseFloat(mapTarifas.get('D1')).toFixed(2)}
      else if (125 < cilindrada && cilindrada < 500)  {
        return parseFloat(mapTarifas.get('D2')).toFixed(2)}
      else if (cilindrada >= 500) {
        return parseFloat(mapTarifas.get('D3')).toFixed(2)}
    }
    else if (planoCoberturas === 'Plus' && estadoVeiculo === 'Usado' && franquia === '10') {
      if (capitalEstimado !== '') {
        if (0 <= cilindrada && cilindrada < 126) {
          return ((parseFloat(mapTarifas.get('E1'))*capitalEstimado+parseFloat(mapTarifas.get('D1'))).toFixed(2))}
        else if (125 < cilindrada && cilindrada < 500)  {
          return (parseFloat(mapTarifas.get('E2'))*capitalEstimado+parseFloat(mapTarifas.get('D2'))).toFixed(2)}
        else if (cilindrada >= 500) {
          return (parseFloat(mapTarifas.get('E3'))*capitalEstimado+parseFloat(mapTarifas.get('D3'))).toFixed(2)}
      } else {
        if (0 <= cilindrada && cilindrada < 126) {
          return ((parseFloat(mapTarifas.get('E1'))*valorVeiculo+parseFloat(mapTarifas.get('D1'))).toFixed(2))}
        else if (125 < cilindrada && cilindrada < 500)  {
          return (parseFloat(mapTarifas.get('E2'))*valorVeiculo+parseFloat(mapTarifas.get('D2'))).toFixed(2)}
        else if (cilindrada >= 500) {
          return (parseFloat(mapTarifas.get('E3'))*valorVeiculo+parseFloat(mapTarifas.get('D3'))).toFixed(2)}
      }
    }
    else if (planoCoberturas === 'Plus' && estadoVeiculo === 'Usado' && franquia === 'Sem Franquia') {
      console.log('yey');
      if (capitalEstimado !== '') {
        if (0 <= cilindrada && cilindrada < 126) {
          return (parseFloat(mapTarifas.get('F1'))*capitalEstimado+parseFloat(mapTarifas.get('D1'))).toFixed(2)}
        else if (125 < cilindrada && cilindrada < 500)  {
          return (parseFloat(mapTarifas.get('F2'))*capitalEstimado+parseFloat(mapTarifas.get('D2'))).toFixed(2)}
        else if (cilindrada >= 500) {
          return (parseFloat(mapTarifas.get('F3'))*capitalEstimado+parseFloat(mapTarifas.get('D3'))).toFixed(2)}
        } else {
          if (0 <= cilindrada && cilindrada < 126) {
            return (parseFloat(mapTarifas.get('F1'))*valorVeiculo+parseFloat(mapTarifas.get('D1'))).toFixed(2)}
          else if (125 < cilindrada && cilindrada < 500)  {
            return (parseFloat(mapTarifas.get('F2'))*valorVeiculo+parseFloat(mapTarifas.get('D2'))).toFixed(2)}
          else if (cilindrada >= 500) {
            return (parseFloat(mapTarifas.get('F3'))*valorVeiculo+parseFloat(mapTarifas.get('D3'))).toFixed(2)}
        }
    }
    else {
      return "";
    }
  }

  getCalculatedPremio(premio) {
    let fraccionamento = this.state.fraccionamento;
    if (fraccionamento === 'anual') { return ((parseFloat(premio)).toFixed(2) + "€")}
    if (fraccionamento === 'semestral') { return ((parseFloat(premio/2)).toFixed(2) + "€")}
    else if (fraccionamento === 'trimestral') { return ((parseFloat(premio/6)).toFixed(2) + "€")}
    else if (fraccionamento === 'mensal') { return ((parseFloat(premio/12)).toFixed(2) + "€")}
  }

  verifyVehicleDP() {
      let anoVeiculo = new Date(this.state.data.dataMatricula).getFullYear();
      let currentAno = new Date().getFullYear();
      return currentAno - anoVeiculo > 6 ? true : false;
  }

  getToken() {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token
  }


  async getLocalidade(values) {

    let regexp = /(?<fourDigit>[0-9]{4})-(?<threeDigit>[0-9]{3})/g;
    if (values !== undefined) {
      if ((values.match(regexp) !== null) && (!this.state.localidade)){
        var codPostal = values.replace(/-/g, "");
        const response = await axios.get('https://api.duminio.com/ptcp/ptapi60d599d14b73f0.17454259/' + codPostal);
      this.setState({ localidade: response.data.Localidade });
      this.setState({ concelho: response.data.Concelho });
    }
  }
  }

  async getLocalidadeTitular(values) {

    let regexp = /(?<fourDigit>[0-9]{4})-(?<threeDigit>[0-9]{3})/g;
    if (values !== undefined) {
      if (values.match(regexp) !== null){
        var codPostal = values.replace(/-/g, "");
        const response = await axios.get('https://api.duminio.com/ptcp/ptapi60d599d14b73f0.17454259/' + codPostal);
      this.setState({ localidadeTitular: response.data.Localidade });
    }
  }
  }

  async getBrandList(values) {
    let brands = [];
    let date = new Date(values.dataMatricula);
    let month = date.getMonth()
    let year = date.getFullYear()
    if (values.matricula) {
    if ((isValidDate(date)) === true && (year > 1900) && (values.matricula.length === 8 ) ) {
      this.setState({ loadingBrands: true}, () => {
        axios.post(`https://api.nacionalgest.pt/api/motos/lista_marcas/`, {
          ano: year,
          mes: month,
        }, {
          headers:{
            'Authorization':`Token ${this.getToken()}`
          }
        })
        .then(response => this.setState({
          loadingBrands: false,
          brands: response.data,
        }))
        .catch((error) => {
          this.setState({loadingBrands: false});
          alert('Não foi possível obter a listagem de marcas.')
        });
      })
      }
    }
  }

  async getModelList(values) {
    let brand = values.marcaVeiculo;
    let date = new Date(values.dataMatricula)
    let month = date.getMonth()
    let year = date.getFullYear()

    if (isValidDate(date) === true && year > 1900 && brand !== undefined) {
      const response = await axios.post('https://api.nacionalgest.pt/api/motos/lista_modelos/', {
        ano: year,
        mes: month,
        marca: brand,
      }, {
        'headers':{
          'Authorization':`Token ${this.getToken()}`
      }});
      this.setState({ models: response.data })
    }
  }

  async getVersionList(values) {
    let brand = values.marcaVeiculo;
    let model = values.modeloVeiculo;
    let date = new Date(values.dataMatricula)
    let month = date.getMonth()
    let year = date.getFullYear()

    if (isValidDate(date) === true && year > 1900 && brand !== undefined && model !== undefined) {
      const response = await axios.post('https://api.nacionalgest.pt/api/motos/lista_versoes/', {
        ano: year,
        mes: month,
        marca: brand,
        modelo: model,
      }, {
        'headers':{
          'Authorization':`Token ${this.getToken()}`
      }});
      this.setState({ versions: response.data })
    }
  }


  async getVehicleDetails(values) {
    let brand = values.marcaVeiculo;
    let model = values.modeloVeiculo;
    let version = values.versaoVeiculo;
    let date = new Date(values.dataMatricula)
    let month = date.getMonth()
    let year = date.getFullYear()

    if (isValidDate(date) === true && year > 1900 && brand !== undefined && model !== undefined && version !== undefined) {
    const response = await axios.post('https://api.nacionalgest.pt/api/motos/detalhes_veiculo/', {
      ano: year,
      mes: month,
      marca: brand,
      modelo: model,
      versao: version
    }, {
        headers:{
          'Authorization':`Token ${this.getToken()}`
        }
      });
    this.setState({ vehicleDetails: response.data,
                    VehiclePesoBruto: response.data[0].peso_bruto,
                    vehicleValorNovo: response.data[0].valor_novo,
                    vehicleValorAtual: response.data[0].valor_atual,
                    vehiclePotencia: response.data[0].hp,
                    vehicleLugares: response.data[0].lugares,
                    vehicleCategoryDesignation: response.data[0].categoria_designacao,
                    vehicleCategoryDescription: response.data[0].categoria_descricao,
                  })}
  }

  getMaxInicioSeguro() {
    var dtToday = new Date();
    var threeMontsFromToday = dtToday.setMonth(dtToday.getMonth()+2);     // getMonth() is zero-based
    var day = dtToday.getDate();
    var month = dtToday.getMonth();
    var year = dtToday.getFullYear();
    if(month < 10)
       month = '0' + month.toString();
    if(day < 10)
       day = '0' + day.toString();
    var result = year + '-' + month + '-' + day;
    return result;
  }

  parseDate(values) {
    var date_regex = /([1-2]{1}\d{3}\-\d{1,2}\-\d{1,2})/g;
    const data = values.dataMatricula;
    if (data !== undefined) {
      if (data.match(date_regex)) {
        const date = new Date(data);
        const dateToCompare = new Date('01-01-2009');
        const result = date < dateToCompare ? true : false;
        if (result) {
          this.setState({
            veiculoNaoListado: true,
            veiculoAnteriorA2009: true
          })
        } else {
          this.setState({
            veiculoAnteriorA2009: false
          })
        }
      }
    }
  }

  getNomeParceiro() {
    const nomeParceiro = sessionStorage.getItem('nomeParceiro');
    return nomeParceiro
  }
  getEmailParceiro() {
    const emailParceiro = sessionStorage.getItem('emailParceiro');
    return emailParceiro
  }

  render() {
    const premio = this.calcPremio(this.state.capitalEstimadoUtilizador);
    console.log(premio);
    const maxInicioSeguro = this.getMaxInicioSeguro();
    const loadingBrands = this.state.loadingBrands;
    const brandsLength = this.state.brands.length;
    const token = sessionStorage.getItem('token');
    const nomeParceiro = sessionStorage.getItem('nomeParceiro');
    const emailParceiro = sessionStorage.getItem('emailParceiro');
    const veiculoNaoListado = this.state.veiculoNaoListado;
    const veiculoAnteriorA2009 = this.state.veiculoAnteriorA2009;
    const handleKeyDown = e => {
      if (e.key === " ") {
        e.preventDefault();
      }
    };
    if(!token) {
      return <Redirect to="/parceiros" />
    }
    return (
      <div>
      <HeaderParceiros
        nomeParceiro={nomeParceiro}
        emailParceiro={emailParceiro}
      />
        <section className="wrapper">
    			<div className="container pt-10 pb-12 pt-md-14 pb-md-14 text-center">
    				<div className="row">
    					<div className="col-lg-8 mx-auto">
                <h4>{nomeParceiro} - {emailParceiro}</h4>
    						<h1 className="display-1 mb-3">Simulador Motos</h1>
    					</div>
    				</div>
    			</div>
    		</section>
      <div id="formulario">
        <WizardCertificadosParceiros
          initialValues={{
            // nomeCompleto: "Luís Silva",
            // morada: "Rua de Teste",
            // nif:'209094362',
            // localidade: "Lagos",
            // dataNascimento: "1990-09-28",
            // // dataMatricula: "2021-05-28",
            // telemovel: "935881809",
            // email: "luisandremarreirossilva@gmail.com",
            // dataCarta: "2002-12-03",
            // numCartaInput: "Fa-3211233",
            // categoriaCarta: "B",
            // credor:"Nao",
            // matricula: "83-DG-33",
            // politicaPrivacidade:true,
            // codigoPostal:'8600-281',


            // # NAO COMENTAR AS LINHAS ABAIXO
            marketing: false,
            infoPreContratuais: false,
            chassis: '',
            veiculoNaoListado:false,
        }}
        onSubmit={onSubmit}
        onStateChange={this.onStateChange}
        page={this.props.page}
        parentState={this.state}
        token={token}
        nomeParceiro={nomeParceiro}
        emailParceiro={emailParceiro}
        >
        <WizardCertificadosParceiros.Page>
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12">
                <h4 className="mb-2">Dados Pessoais</h4>
                <Field id="nomeCompleto" name="nomeCompleto" validate={composeValidators(required, twoWords(2))}>
                  {({ input, meta }) => (
                    <div className="form-label-group mb-4">
                      <input {...input} className="form-control" type="text" component="input" placeholder="Nome Completo" />
                      <label htmlFor="nomeCompleto">Nome Completo</label>
                      {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                    </div>
                  )}
                </Field>
              </div>
                <div className="col-md-12">
                <Field id="moradaInput" name="morada" validate={required}>
                  {({ input, meta }) => (
                    <div className="form-label-group mb-4">
                      <input {...input} className="form-control" type="text" component="input" placeholder="Morada" />
                      <label htmlFor="moradaInput">Morada</label>
                      {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                    </div>
                  )}
                </Field>
              </div>
            </div>
          <div className="row">
            <div className="col-md-6">
              <Field id="codigoPostalInput" name="codigoPostal" validate={composeValidators(required, verifyPostalCode)} parse={normalizePostalCode} onChange={this.handleChange}>
                {({ input, meta }) => (
                  <div className="form-label-group mb-4">
                    <input {...input} className="form-control" type="text" component="input" placeholder="Código Postal" maxLength="8" />
                    <label htmlFor="codigoPostalInput">Código Postal</label>
                    {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                  </div>
                )}
              </Field>
            </div>
            <div className="col-md-6">
              <div className="form-label-group mb-4">
                <input className="form-control" id="localidade" name="localidade" type="text" placeholder="Localidade" defaultValue={this.state.localidade} onChange={this.handleChange} required/>
                <label htmlFor="localidade">Localidade</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-label-group mb-4">
                <Field id="nifInput" name="nif" validate={composeValidators(required, nifSuccess)} parse={normalizeNIF}>
                  {({ input, meta }) => (
                    <div className="form-label-group mb-4">
                      <input {...input} className="form-control" type="text" component="input" placeholder="NIF" />
                      <label htmlFor="nifInput">NIF</label>
                      {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                    </div>
                  )}
                </Field>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-label-group mb-4">
                <Field name="dataNascimento" validate={composeValidators(required)}>
                  {({ input, meta }) => (
                    <div className="form-label-group mb-4">
                      <input {...input} id="dataNascimentoInput" className="form-control" type="date" component="input" placeholder="Data de Nascimento" max={maxDate}/>
                      <label htmlFor="dataNascimentoInput">Data de Nascimento</label>
                      {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                    </div>
                  )}
                </Field>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <h4 className="mb-2">Contactos</h4>
            <div className="row">
              <div className="col-md-5">
                <Field name="telemovel" validate={composeValidators(required, verifyPhoneNumber)} parse={normalizePhoneNumber}>
                  {({ input, meta }) => (
                    <div className="form-label-group mb-4">
                      <input {...input} id="telemovelInput" className="form-control" type="text" component="input" placeholder="Telemóvel" maxLength="9"/>
                      <label htmlFor="telemovelInput">Telemóvel</label>
                      {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                    </div>
                  )}
                </Field>
            </div>
            <div className="col-md-7">
                <Field id="emailInput" name="email" validate={required}>
                  {({ input, meta }) => (
                    <div className="form-label-group mb-4">
                      <input {...input} className="form-control" type="email" component="input" placeholder="E-mail" />
                      <label htmlFor="emailInput">E-mail</label>
                      {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                    </div>
                  )}
                </Field>
            </div>
          </div>
          <div className="row">
          <h4 className="mb-2" style={{marginTop:'1.67rem'}}>Informação do Condutor</h4>
            <div className="col-md-12">
              <div className="form-label-group mb-4">
                <Field id="dataCartaInput" name="dataCarta" validate={required}>
                  {({ input, meta }) => (
                    <div className="form-label-group mb-4">
                      <input {...input} className="form-control" type="date" component="input" placeholder="Data Carta de Condução" />
                      <label htmlFor="dataCartaInput">Data Carta de Condução</label>
                      {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                    </div>
                  )}
                </Field>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-label-group mb-4">
                <Field id="numCartaInput" name="numCartaInput" validate={required}>
                  {({ input, meta }) => (
                    <div className="form-label-group mb-4">
                      <input {...input} className="form-control" type="text" component="input" placeholder="Nº Carta de Condução" />
                      <label htmlFor="numCartaInput">Nº Carta de Condução</label>
                      {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                    </div>
                  )}
                </Field>
              </div>
            </div>
            <div className="col-md-6">
                <Field id="categoriaCarta" className="form-select" name="categoriaCarta" validate={composeValidators(required, verifyCategoriaCarta)}>
                  {({ input, meta }) => (
                    <div className="form-label-group mb-4">
                      <select {...input} className="form-select" type="text" component="select">
                      <option value={"ND"}>Categoria da Carta</option>
                      <option value={"A"}>A</option>
                      <option value={"A1"}>A1</option>
                      <option value={"A2"}>A2</option>
                      <option value={"B"}>B</option>
                      </select>
                      {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                    </div>
                  )}
                </Field>
              </div>
          </div>
        </div>
        </div>
          <FormSpy subscription={{ values: true }}
            onChange={(props) => {
              this.getLocalidade(props.values.codigoPostal);
					}}></FormSpy>
        </WizardCertificadosParceiros.Page>
        <WizardCertificadosParceiros.Page>
          <div>
            <div className="row">
              <div className="col-lg-6">
              <h6 className="mb-3">Qual o estado do veículo?</h6>
              <div className="form-label-group">
                <div className="form-check">
                <label className="form-check-label" htmlFor="estadoVeiculo" style={{fontSize: '17px'}}>
                <Field
                  className="form-check-input"
                  name="estadoVeiculo"
                  component="input"
                  type="radio"
                  value="Usado"
                  onChange={this.handleChange}
                  checked={this.state.estadoVeiculo === "Usado"}
                  required
                />{' '}
                Usado
              </label>
              </div>
              </div>
              <div className="form-label-group">
              <div className="form-check mb-4">
                <label className="form-check-label" htmlFor="estadoVeiculo" style={{fontSize: '17px'}}>
                <Field
                  className="form-check-input"
                  name="estadoVeiculo"
                  component="input"
                  type="radio"
                  value="Novo"
                  onChange={this.handleChange}
                  checked={this.state.estadoVeiculo === "Novo"}
                />{' '}
                Novo
              </label>
              </div>
            </div>
            </div>
            <div className="col-lg-6">
            <h6 className="mb-3">O veículo é elétrico?</h6>
            <div className="form-label-group">
              <div className="form-check">
              <label className="form-check-label" htmlFor="veiculoEletrico" style={{fontSize: '17px'}}>
              <Field
                className="form-check-input"
                name="veiculoEletrico"
                component="input"
                type="radio"
                value="Sim"
                onChange={this.handleChangeVeiculoEletrico}
                checked={this.state.veiculoEletrico === "Sim"}
                required
              />{' '}
              Sim
            </label>
            </div>
            </div>
            <div className="form-label-group">
            <div className="form-check mb-4">
              <label className="form-check-label" htmlFor="veiculoEletrico" style={{fontSize: '17px'}}>
              <Field
                className="form-check-input"
                name="veiculoEletrico"
                component="input"
                type="radio"
                value="Não"
                onChange={this.handleChangeVeiculoEletrico}
                checked={this.state.veiculoEletrico === "Não"}
              />{' '}
              Não
            </label>
            </div>
          </div>
            </div>
            </div>
            {this.state.estadoVeiculo !== "" && this.state.veiculoEletrico !== "" && (
              <div>
                <div className="row">
                  <div className="col-md-6">
                    <Field id="matricula" name="matricula" validate={required}>
                      {({ input, meta }) => (
                        <div className="form-label-group mb-4">
                          <input {...input} className="form-control" type="text" component="input" placeholder="Matrícula" />
                          <label htmlFor="matricula">Matrícula</label>
                          {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="col-md-6">
                    <Field id="dataMatricula" name="dataMatricula" validate={required}>
                      {({ input, meta }) => (
                        <div className="form-label-group mb-4">
                          <input {...input} className="form-control" type="date" component="input" placeholder="Data da Matrícula" max={maxDate}/>
                          <label htmlFor="dataMatricula">Data da Matrícula</label>
                          {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
                <ConditionVeiculoListado when="veiculoNaoListado">
                  {!veiculoNaoListado && (
                    <>
                <div className="row">
                  <div className="col-md-6">
                    <Field id="marcaVeiculo" className="form-select" name="marcaVeiculo" validate={composeValidators(required, verifyBrands)}>
                      {({ input, meta }) => (
                        <div className="form-label-group mb-4">
                          <select {...input} className="form-select" type="text" component="select">
                            <option value={"ND"}>Marca</option>
                            {this.state.brands.map((option) => (
                            <option value={option.value} key={option.marca}>{option.marca}</option>
                            ))}
                          </select>
                        {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                        </div>
                      )}
                    </Field>
                    </div>
                  <div className="col-md-6">
                    <Field id="modeloVeiculo" className="form-select" name="modeloVeiculo" validate={composeValidators(required, verifyModels)} >
                      {({ input, meta }) => (
                        <div className="form-label-group mb-4">
                          {(!this.state.data.marcaVeiculo || this.state.data.marcaVeiculo === "ND") && (
                          <select {...input} className="form-select" type="text" component="select" disabled>
                            <option value={"ND"}>Modelo</option>
                          </select>
                          )}
                          {(this.state.data.marcaVeiculo && this.state.data.marcaVeiculo !== "ND") && (
                            <select {...input} className="form-select" type="text" component="select">
                            <option value={"ND"}>Modelo</option>
                              {this.state.models.map((option) => (
                              <option value={option.value} key={option.modelo}>{option.modelo}</option>
                              ))}
                            </select>
                          )}
                          {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-4">
                    <div className="form-label-group mb-4">
                      <Field id="versaoVeiculo" className="form-select" name="versaoVeiculo" validate={composeValidators(required, verifyVersions)} >
                        {({ input, meta }) => (
                          <div className="form-label-group mb-4">
                            {(!this.state.data.modeloVeiculo || this.state.data.modeloVeiculo === "ND") && (
                            <select {...input} className="form-select" type="text" component="select" disabled>
                              <option value={"ND"}>Versão</option>
                              </select>
                              )}
                              {(this.state.data.marcaVeiculo && this.state.data.marcaVeiculo !== "ND") && (
                                this.state.data.modeloVeiculo && this.state.data.modeloVeiculo !== "ND") && (
                              <select {...input} className="form-select" type="text" component="select" >
                                <option value={"ND"}>Versão</option>
                              {this.state.versions.map((option) => (
                              <option value={option.value} key={option.versao}>{option.versao}</option>
                              ))}
                            </select>
                            )}
                            {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-label-group">
                      <input className="form-control" name="chassis" id="chassis" type="text" placeholder="Nº Chassis" onChange={this.handleChange} value={this.state.chassis} onKeyDown={handleKeyDown} required/>
                      <label htmlFor="chassis">Nº Chassis</label>
                    </div>
                  </div>
                  {this.state.veiculoEletrico === "Não" && (
                  <div className="col-md-4">
                    <div className="form-label-group">
                      <input className="form-control" name="cilindradaVeiculo" id="cilindradaVeiculo" type="number" placeholder="Cilindrada" onChange={this.handleChange} value={this.state.cilindradaVeiculo} min="0" required/>
                      <label htmlFor="cilindradaVeiculo">Cilindrada</label>
                    </div>
                  </div>
                )}
              </div>
              </>
            )}

              {loadingBrands === true && (
                <div className="loadingio-spinner-ellipsis-9w2r0d892j full-spinner"><div className="ldio-o8u2rr79tje">
                <div></div><div></div><div></div><div></div><div></div>
                </div></div>
              )}
              </ConditionVeiculoListado>
              {veiculoNaoListado && (
                <>
                <div className="row mb-4">
                  <div className="col-md-6">
                    <div className="form-label-group">
                    <input className="form-control" name="marcaVeiculo" id="marcaVeiculo" type="text"  placeholder="Marca" onChange={this.handleChange} value={this.state.marcaVeiculo}/>
                    <label htmlFor="marcaVeiculo">Marca</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-label-group">
                    <input className="form-control" name="modeloVeiculo" id="modeloVeiculo" type="text"  placeholder="Modelo" onChange={this.handleChange} value={this.state.modeloVeiculo}/>
                    <label htmlFor="modeloVeiculo">Modelo</label>
                    </div>
                  </div>
                </div>
                <div className="row mb-6">
                  <div className="col-md-4">
                    <div className="form-label-group mb-4">
                      <input className="form-control" name="versaoVeiculo" id="versaoVeiculo" type="text"  placeholder="Versão" onChange={this.handleChange} value={this.state.versaoVeiculo}/>
                      <label htmlFor="versaoVeiculo">Versão</label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-label-group">
                      <input className="form-control" name="chassis" id="chassis" type="text" placeholder="Nº Chassis" onChange={this.handleChange} value={this.state.chassis}  required/>
                      <label htmlFor="chassis">Nº Chassis</label>
                    </div>
                  </div>
                  {this.state.veiculoEletrico === "Não" && (
                  <div className="col-md-4">
                    <div className="form-label-group">
                      <input className="form-control" name="cilindradaVeiculo" id="cilindradaVeiculo" type="number" placeholder="Cilindrada" onChange={this.handleChange} value={this.state.cilindradaVeiculo} min="0" required/>
                      <label htmlFor="cilindradaVeiculo">Cilindrada</label>
                    </div>
                  </div>
                )}
              </div>
              </>
              )}
              {!veiculoAnteriorA2009 && (
                <div className="row">
                      <div className="col-lg-12">
                        <div className="form-check mb-2">
                          <input id="veiculoNaoListado" type="checkbox" className="form-check-input" defaultValue="false" checked={this.state.veiculoNaoListado}
              onChange={this.handleChangeVeiculoNaoListado}/>
                          <label htmlFor="veiculoNaoListado" style={{ lineHeight: 1.3 }}>A marca ou modelo do seu veículo não se encontra listado? </label>
                        </div>
                      </div>
                    </div>
              )}
                <div className="row">
                  <h6 className="mb-3">O veículo tem credor?</h6>
                  <div className="col-md-4">
                    <div className="form-check">
                      <label className="form-check-label" htmlFor="credorSim">
                      <Field
                        id="credorSim"
                        className="form-check-input"
                        name="credor"
                        component="input"
                        type="radio"
                        value="Sim"
                        onChange={this.handleChange}
                        checked={this.state.credor === "Sim"}
                        />{' '}
                        Sim
                    </label>
                    </div>
                    <div className="form-check mb-4">
                      <label className="form-check-label" htmlFor="credorNao">
                      <Field
                        id="credorNao"
                        className="form-check-input"
                        name="credor"
                        component="input"
                        type="radio"
                        value="Nao"
                        onChange={this.handleChange}
                        checked={this.state.credor === "Nao"}
                        />{' '}
                        Não
                      </label>
                    </div>
                  </div>
                {this.state.credor === 'Sim' && (
                  <div className="col-md-6">
                    <div className="form-label-group mb-4">
                      <input className="form-control" id="nomeCredor" name="nomeCredor" type="text" placeholder="Entidade Credora" onChange={this.handleChange}/>
                      <label htmlFor="nomeCredor">Entidade Credora</label>
                    </div>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <Condition when="marcaVeiculo">
                    <Condition when="modeloVeiculo">
                      <Condition when="versaoVeiculo">
                        {this.state.vehicleValorAtual !== '' &&(
                          <div className="col-md-12">
                            <h6>Valor Atual do veículo: <span className="text-green">{this.state.vehicleValorAtual} €</span></h6>
                            <h6>Valor do veículo em novo: <span className="text-green">{this.state.vehicleValorNovo}€</span></h6>
                            </div>
                        )}
                        {this.state.vehicleValorAtual === '' || (this.state.inserirCapital === true) &&(
                          <div>
                          <p><strong>* Não foi possível estimar o valor do seu veículo, insira abaixo o capital atual a segurar.<br />
                          * O capital a segurar não deve exceder em 15% o valor atribuído pela Eurotax.</strong></p>
                          <div className="col-md-12">
                            {/*<Field id="capitalEstimadoUtilizador" name="capitalEstimadoUtilizador">
                              {({ input, meta }) => (
                                <div className="form-label-group mb-4">
                                  <input {...input} className="form-control" type="number" component="input" placeholder="Capital a Segurar" />
                                  <label htmlFor="capitalEstimadoUtilizador">Capital a Segurar em €</label>
                                  {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                </div>
                              )}
                            </Field>*/}
                            <div className="form-label-group mb-4">
                              <input className="form-control" id="capitalEstimadoUtilizador" name="capitalEstimadoUtilizador" type="number" placeholder="€" onChange={this.handleChange} defaultValue={this.state.capitalEstimadoUtilizador} required/>
                              <label htmlFor="capitalEstimadoUtilizador">Valor Atual em €</label>
                            </div>
                          </div>
                          </div>
                        )}
                        <button
                          className="btn btn-primary rounded-0 btn-send btn-sm mb-3"
                          type="button"
                          onClick={this.handleClickInserirCapital}
                        >Inserir Capital
                      </button>
                      </Condition>
                    </Condition>
                  </Condition>
                </div>
              </div>
              </div>
            )}
          </div>
          <FormSpy
            subscription={{ values: true }}
            onChange={props => {
              this.parseDate(props.values);
              this.getBrandList(props.values);
              this.getModelList(props.values);
              this.getVersionList(props.values);
              this.getVehicleDetails(props.values);
              this.onStateChange(props.values);
            }}
          />
        </WizardCertificadosParceiros.Page>
        <WizardCertificadosParceiros.Page>
          <Coberturas />
          <div className="row">
            <div className="col-md-6 mb-4">
            <h6 className="mb-3">Seleccionar Plano</h6>
              <div className="form-label-group">
                <div className="form-check">
                <label className="form-check-label" htmlFor="planoCoberturasBase">
                <Field
                  className="form-check-input"
                  name="planoCoberturas"
                  component="input"
                  type="radio"
                  value="Base"
                  onChange={this.handleChange}
                  checked={this.state.planoCoberturas === "Base"}
                />{' '}
                Base
              </label>
                </div>
              </div>
              {(this.verifyVehicleDP() !== true || (this.verifyVehicleDP() !== true && (brandsLength > 10))) && (
              <div className="form-label-group">
              <div className="form-check">
                <label className="form-check-label" htmlFor="planoCoberturasBase">
                <Field
                  className="form-check-input"
                  name="planoCoberturas"
                  component="input"
                  type="radio"
                  value="Plus"
                  onChange={this.handleChange}
                  checked={this.state.planoCoberturas === "Plus"}
                />{' '}
                Proteção / Plus
              </label>
            </div>
          </div>
          )}
            </div>
            {this.state.planoCoberturas === "Plus" &&
            <div className="col-md-6 mb-4">
            <h6 className="mb-3">Franquia</h6>
              <div className="form-label-group">
                <div className="form-check">
                  <label className="form-check-label" htmlFor="franquia">
                  <Field
                    className="form-check-input"
                    name="franquia"
                    component="input"
                    type="radio"
                    value="10"
                    onChange={this.handleChange}
                    checked={this.state.franquia === "10"}
                    required
                  />{' '}
                  10%
                </label>
              </div>
              </div>
                <div className="form-label-group">
                  <div className="form-check">
                    <label className="form-check-label" htmlFor="franquia">
                    <Field
                      className="form-check-input"
                      name="franquia"
                      component="input"
                      type="radio"
                      value="Sem Franquia"
                      onChange={this.handleChange}
                      checked={this.state.franquia === "Sem Franquia"}
                    />{' '}
                    Sem Franquia
                  </label>
                </div>
            </div>
            </div>
          }
          </div>
        </WizardCertificadosParceiros.Page>
        <WizardCertificadosParceiros.Page>
          <div className="row" style={{display:'flex', alignItems:'center'}}>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-md-8">
                  <h6 className="card-title">Data de Início do Seguro</h6>
                    <Field name="dataInicioSeguro" validate={composeValidators(required)}>
                      {({ input, meta }) => (
                        <div className="form-label-group mb-4">
                          <input {...input} id="dataInicioSeguro" className="form-control" type="date" component="input" placeholder="Data de Início" min={maxDate}/>
                          <label htmlFor="dataInicioSeguro">Data de Início</label>
                          {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                        </div>
                      )}
                    </Field>
                </div>
              </div>
              <div className="row">
                  <div className="col-lg-6">
                    <h6 className="card-title">Plano de Coberturas Seleccionado:</h6>
                    {this.state.planoCoberturas === 'Base' && (
                      <p className="mb-2"><a className="text-green">Plano Base</a></p>
                    )}
                    {this.state.planoCoberturas === 'Plus' && (
                      <p className="mb-2"><a className="text-green">Proteção / Plus</a></p>
                    )}
                  </div>
                { this.state.planoCoberturas === 'Plus' && this.state.franquia === '10' && (
                    <div className="col-lg-6">
                      <h6 className="card-title">Franquia</h6>
                      <p className="mb-2"><a className="text-green">10%</a></p>
                    </div>
                  )}
                {this.state.planoCoberturas === 'Plus' && this.state.franquia === 'Sem Franquia' && (
                  <div className="col-lg-6">
                    <h6 className="card-title">Franquia</h6>
                    <p className="mb-2"><a className="text-green">Sem Franquia</a></p>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4">
                { this.state.planoCoberturas === 'Base' && (
                  <div className="row preco-base">
                    <h2 style={{marginBottom:'0px'}}>Prémio {this.state.fraccionamento}:</h2>
                    <h1 className="text-green">{parseFloat(this.getCalculatedPremio(premio)).toLocaleString('pt')}€</h1>
                  </div>
                )}
                { this.state.planoCoberturas === 'Plus' && (
                  <div className="row">
                  <h2 style={{marginBottom:'0px'}}>Prémio {this.state.fraccionamento}:</h2>
                  <h1 className="text-green">{parseFloat(this.getCalculatedPremio(premio)).toLocaleString('pt')}€</h1>
                  </div>
                )}
            </div>
          </div>

        <div className="row mb-6">
          <div className="col-md-6">
            <h6 className="card-title">IBAN</h6>
              <Field id="iban" name="iban" parse={normalizeIBAN} validate={required, ibanSuccess} >
                {({ input, meta }) => (
                  <div>
                  <div className="input-group mb-4">
                    <span className="input-group-text" id="basic-addon1">PT50</span>
                    <input {...input} className="form-control" name="iban" type="text" placeholder="IBAN" />

                  </div>
                  {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                  </div>
                )}
              </Field>
              {this.state.bicSwift !== '' && (
                <span>Banco: <a className="text-green">{this.state.banco}</a> <br />BIC SWIFT: <a className="text-green">{this.state.bicSwift}</a></span>
                )}
          </div>
            <div className="col-md-6">
              <h6 className="card-title">Fraccionamento</h6>
              <div className="form-label-group mb-4">
                <select id="fraccionamento" className="form-select" name="fraccionamento" type="text" onChange={this.handleChange}>
                <option defaultValue value={"anual"}>Anual</option>
                {(premio/2) > 60 && (
                <option value={"semestral"}>Semestral</option>
                )}
                {(premio/4) > 60 && (
                <option value={"trimestral"}>Trimestral</option>
                )}
                {(premio/12) > 60 && (
                <option value={"mensal"}>Mensal</option>
                )}
                </select>
              </div>
            </div>
        </div>

  <hr className="my-4" />
    <div className="row">
      <div className="col-lg-12">
        <Field name="infoPreContratuais" type="checkbox" validate={composeValidators(required)}>
          {({ input, meta }) => (
            <div className="form-check mb-2">
              <input {...input} id="infoPreContratuais" className="form-check-input" defaultValue="false"/>
              <label htmlFor="infoPreContratuais" style={{ lineHeight: 1.3 }}>Declaro que entreguei as <a href={this.state.cg_url} target="_blank">Informações Pré-Contratuais do Seguro Automóvel - Motas</a> ao cliente.</label>
              {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
            </div>
          )}
        </Field>
      </div>
      <div className="col-lg-12">
        <Field name="marketing" type="checkbox" >
          {({ input, meta }) => (
            <div className="form-check mb-2">
              <input {...input} id="marketing" className="form-check-input" defaultValue="false"/>
              <label htmlFor="marketing" style={{ lineHeight: 1.3 }}>O cliente deseja subscrever a newsletter mensal? </label>
              {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
            </div>
          )}
        </Field>
      </div>
    </div>
          <FormSpy subscription={{ values: true, valid: true }}
            onChange={(props) => {
              this.getIBAN(props.values.iban);
              this.getLocalidadeTitular(props.values.codigoPostalTitular);
					}}>
        </FormSpy>
      </WizardCertificadosParceiros.Page>
    </WizardCertificadosParceiros>
  </div><FooterParceiros /></div>
    )
  }
}

export default SimulatorCertificados;
