import ReactDOM from 'react-dom';
import { Field, FormSpy } from 'react-final-form';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));



const Error = ({ name }) => (
  <Field
    name={name}
    subscription={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? <div className="help-block with-errors">{error}</div> : null
    }
  />
)

// CONDICOES

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
)

const ConditionWhenNot = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value !== is ? children : null)}
  </Field>
)

const ConditionWhenGreater = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value > is ? children : null)}
  </Field>
)

const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)

const required = value => (value ? undefined : 'Campo Obrigatório')
const mustBeNumber = value => (isNaN(value) ? 'Must be a number' : undefined)
const minValue = min => value => isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`
const twoWords = min => value => (value.match(/(\w+)/g).length >= 2 ? undefined : 'Insira o nome completo')

const validateForm = values => {
  if (typeof values.dataChegada === "undefined" || values.dataChegada.length === 0)
    return { dataChegada: "must choose one" };
};


// NORMALIZAR O CODIGO POSTAL
const normalizePostalCode = (value) => {
  if (!value) return value;
  var onlyNums = value.replace(/[^\d\-]/g, "");
  return onlyNums;
};

const verifyPhoneNumber = (value) => {
  return value.length === 9 ? undefined : "Formato Incorreto";
}

const nifSuccess = value => {
  if (!value ) {
    return "Campo obrigatório";
  }
  else if (isNaN(value) || value.length !== 9) {
    return "Formato Inválido";
  }
  else if (value.length === 9) {
    var result = value.charAt(7)*2 +
    value.charAt(6) * 3 +
    value.charAt(5) * 4 +
    value.charAt(4) * 5 +
    value.charAt(3) * 6 +
    value.charAt(2) * 7 +
    value.charAt(1) * 8 +
    value.charAt(0) * 9;

    var mod = result % 11;
    var controlDigit = 0;
    mod === 0 || mod === 1 ? controlDigit = 0 : controlDigit = 11 - mod;

    return controlDigit == value.charAt(8) ? undefined : "NIF incorreto";

  }
};

const normalizeNIF = (value) => {
  if (!value) return value;
  var onlyNums = value.replace(/[^\d]/g, "");
  return onlyNums;
}

const verifyPostalCode = (value) => {
  let regexp = /(?<fourDigit>[0-9]{4})-(?<threeDigit>[0-9]{3})/g;
  return value.match(regexp) ? undefined : "Formato Incorreto";
}


const verifyPoliticaPrivacidade = value => (value === true ? undefined : "Campo Obrigatório")
const verifyAceitacaoTermos = value => (value === true ? undefined : "A aceitação dos termos é obrigatória")
export {
  verifyPoliticaPrivacidade,
  verifyAceitacaoTermos,
  normalizePostalCode,
  nifSuccess,
  required,
  mustBeNumber,
  minValue,
  twoWords,
  normalizeNIF,
  composeValidators,
  verifyPostalCode,
  verifyPhoneNumber,
  sleep,
  validateForm,
  Error,
  Condition,
  ConditionWhenNot,
  ConditionWhenGreater,

} ;
