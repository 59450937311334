import React, {useState} from 'react';
import { API } from '../API/services';
import PropTypes from 'prop-types';


function Login({ setToken }) {

  const [ username, setUsername] = useState('');
  const [ password, setPassword] = useState('');



  const handleSubmit = async e => {
    e.preventDefault();
    const token = await API.loginUser({
      username,
      password
    });
    setToken(token);
  }


  return(
    <div>
	<div className="content-wrapper">
		<section className="wrapper bg-light angled upper-end">

			<div className="container pt-20">
        <div className="row pb-4">
          <div className="col-lg-12">
            <img style={{display:'block',marginLeft:'auto', marginRight:'auto', paddingBottom:'20px'}} src="https://api.nacionalgest.pt/static/assets/logo/nacionalgest_logo.png" srcSet="https://api.nacionalgest.pt/static/assets/logo/nacionalgest_logo.png 2x" alt="" />
            <h4 className="display-4 mt-4 text-center">Parceiros NacionalGest</h4>
          </div>
        </div>
        <div className="row" style={{display: 'grid', justifyContent: 'center', alignItems:'top',}}>

					<div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
						<h3 className="display-5 mb-3 text-center">Login</h3>
            <form onSubmit={handleSubmit}>
							<div className="controls">
								<div className="row gx-4">
									<div className="col-md-12">
										<div className="form-label-group mb-4">
											<input id="username" type="text" onChange={ evt => setUsername(evt.target.value)} value={username} className="form-control" required="required" />
											<label htmlFor="username">Username</label>
											<div className="help-block with-errors"></div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-label-group mb-4">
											<input id="password" type="password"  onChange={ evt => setPassword(evt.target.value)} value={password} className="form-control" required="required" />
											<label htmlFor="password">Password</label>
											<div className="help-block with-errors"></div>
										</div>
									</div>
									<div className="col-12 text-center">
										<input type="submit" className="btn btn-primary rounded-0 btn-send mb-3" value="Entrar" />
									</div>
								</div>
							</div>
            </form>
					</div>
				</div>
			</div>
		</section>
	</div>
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}

export default Login;
