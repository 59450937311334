import axios from 'axios';

export class API {


  static loginUser(body) {
    return axios.post(`https://api.nacionalgest.pt/api/auth/`, {
    // return axios.post(`http://127.0.01:8000/api/auth/`, {
    username: body.username,
    password: body.password,
  }).then(response => response.data)
}


}
