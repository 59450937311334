import React, { Component, useState, useEffect } from 'react';
import LoginParceiros from './LoginParceiros';
import axios from 'axios';
import useToken from '../useToken';
import FooterParceiros from './FooterParceiros';
import HeaderParceiros from './HeaderParceiros';
import BaseParceiros from './BaseParceiros';
import {
  Link
} from "react-router-dom";


function setToken(userToken) {
  sessionStorage.setItem('token', JSON.stringify(userToken));
  this.setState({token:JSON.stringify(userToken)})
}

function getToken() {
  const tokenString = sessionStorage.getItem('token');
  const userToken = JSON.parse(tokenString);
  return userToken?.token
}


function SimuladoresParceiros() {

  const { token, setToken } = useToken();
  const [isLoading, setLoading ] = useState(true);
  const [ nomeParceiro, setNomeParceiro ] = useState();
  const [ emailParceiro, setEmailParceiro ] = useState();

  function setParceiro(dadosParceiro) {
    sessionStorage.setItem('nomeParceiro', dadosParceiro.nome);
    sessionStorage.setItem('emailParceiro', dadosParceiro.email);
    setNomeParceiro(dadosParceiro.nome);
    setEmailParceiro(dadosParceiro.email);
  }

  if(!token) {
    return <LoginParceiros setToken={setToken} />
  }
  // OBTER DETALHES PARCEIRO ATRAVES DO TOKEN DE AUTH
  if(token) {
    // axios.post(`https://api.nacionalgest.pt/api/obter_nome_user`, {
    axios.post(`https://api.nacionalgest.pt/api/obter_nome_user`, {
      'token': token
    },{
      'headers':{
        'Authorization':`Token ${token}`,
      }
    }).then(response =>  {
      setParceiro(response.data)
    })
    .catch(function(error) {
      console.log(error);
    });
  }

  return (
    <div>
    <HeaderParceiros
      nomeParceiro={nomeParceiro}
      emailParceiro={emailParceiro}
    />
      <section className="wrapper">
  			<div className="container pt-10 pb-12 pt-md-14 pb-md-14 text-center">
  				<div className="row">
  					<div className="col-lg-8 mx-auto">
              <h4>{nomeParceiro} - {emailParceiro}</h4>
  						<h1 className="display-1 mb-3">Parceiros NacionalGest</h1>
  					</div>
  				</div>
  			</div>
  		</section>
      <section className="wrapper bg-light wrapper-border">
        <div className="container py-8 pt-md-2">
          <div className="card bg-soft-primary mb-10">
            <div className="card-body p-12 pb-0">
              <div className="row" style={{display:'flex', justifyContent: 'space-around'}}>
                <div className="col-lg-5 pb-12">
                  <div className="post-category mt-2 mb-3 text-green">Seguros</div>
                  <h3 className="h1 post-title mb-3">Seguros Particulares</h3>
                  <p>Conheça as soluções de seguros particulares que temos para o proteger. </p>
                  <Link to="/parceiros/particulares/" className="more hover link-green">Ver Produtos</Link>
                </div>
                <div className="col-lg-5 offset-lg-1">
                    <div className="post-category mt-2 mb-3 text-green">Seguros</div>
                    <h3 className="h1 post-title mb-3">Seguros Empresas</h3>
                    <p>Conheça as soluções de seguros empresariais que temos para o proteger.</p>
                    <Link to="/parceiros/empresas" className="more hover link-green">Ver Produtos</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterParceiros />
    </div>
  );
}


export default SimuladoresParceiros;
