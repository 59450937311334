import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import HeaderParceiros from './HeaderParceiros';
import Footer from '../Footer';
import RequestContact from '../RequestContact';
import seguroImage from '../../assets/img/photos/seguros-2.jpeg';


class SuccessCertificadosParceiros extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      certificadoLoaded: false,
      propostaDescarregada: false,
      certificadoEmitido: false,
      certificadoDescarregado: false,
      loadingCertificado: false,
      loadingProposta: false,
      condicoesGeraisLoaded:false,
      loadingCondicoes: false,
      error: null,
      num_segurnet: null
    }
  }

  componentDidMount() {
    window.scrollTo(0,0);
    const token = JSON.parse(sessionStorage.getItem('token')).token;
    this.setState({loadingCondicoes:true}, () => {
    axios({
    // url: "https://api.nacionalgest.pt/api/get_certificado_provisorio/",
    url: "https://api.nacionalgest.pt/api/motos/obter_condicoes_gerais/",
    method:'GET',
    headers: {
     'Authorization':`Token ${token}`
    }
  }).then((response) => {
    this.setState({loadingCondicoes: false});
    if (response.status === 200) {
      if (response.data['error'] === 'true') {
        this.setState({
          error: true,
          error_description: response.data['error_description']
        })
      }
      else {
      this.setState({
        condicoesGeraisLoaded: true,
        condicoesGerais: response.data,
        error: false})
      }
    }
    // cg_url: `https://api.nacionalgest.pt${response.data}`,
  })
  .catch((error) => {
    this.setState({loadingCondicoes: false});
    alert(error);
  });
})
  }

  handleGetCertificado = async (values) => {
    const token = JSON.parse(sessionStorage.getItem('token')).token;
    this.setState({loadingCertificado:true})
    await axios({
      // url: "https://api.nacionalgest.pt/api/get_certificado_provisorio/",
      url: "https://api.nacionalgest.pt/api/motos/emitir_certificado/",
      method:'POST',
      data:{
        id: this.props.location.state.id_pedido,
      },
      headers: {
        'Authorization':`Token ${token}`
      }
    }).then((response) => {
      this.setState({
        loadingCertificado: false,
        certificadoEmitido: true
      });
        console.log(response.data)
      if (response.status === 200) {
        if (response.data['error'] === true) {
          let mensagem_erro = response.data['error_description']
          this.setState({
            error: true,
            error_description: mensagem_erro
          })
          let result = mensagem_erro.includes("Segurnet");
          if (result) {
            this.setState({
              num_segurnet:mensagem_erro.split(" ").pop()
            })
          }
        } else {
        this.setState({
          certificadoLoaded: true,
          certificado: response.data.certificado,
          error: false})
        }
      }
      // cg_url: `https://api.nacionalgest.pt${response.data}`,
    })
    .catch((error) => {
      this.setState({loadingCertificado: false});
      alert(error);
    });
}

handleGetPropostaPreenchida = () => {

  let url = 'https://api.nacionalgest.pt' + this.props.location.state.proposta;
  window.open(url,'_blank')
  this.setState({propostaDescarregada:true});
}

handleObterCertificadoSegurnet = (values) => {
  const token = JSON.parse(sessionStorage.getItem('token')).token;
  this.setState({loadingCertificado:true}, () => {
  axios({
  url: "https://api.nacionalgest.pt/api/motos/obter_certificado/",
  method:'POST',
  data:{
    num_segurnet: this.state.num_segurnet,
  },
  headers: {
    'Authorization':`Token ${token}`
 }
}).then((response) => {
  console.log(response.data);
  this.setState({loadingCertificado: false});
  if (response.status === 200) {
    if (response.data['error'] === 'true') {
      this.setState({
        error: true,
        error_description: response.data['error_description']
      })
    }
    else {
    this.setState({
      certificadoLoaded: true,
      certificado: response.data.certificado,
      error: false})
    }
  }
  // cg_url: `https://api.nacionalgest.pt${response.data}`,
})
.catch((error) => {
  this.setState({loadingCertificado: false});
  alert(error);
});
});
}

propostaDescarregada () {
  if (this.state.propostaDescarregada) {
    return (
      <>
      <span className="icon btn btn-circle btn-lg btn-green disabled mb-4"><span className="uil uil-check"></span></span>
        <h4 className="mb-1"><a class="hover" onClick={this.handleGetPropostaPreenchida} style={{cursor:'pointer'}}>Descarregar Proposta</a></h4>
          <p>Clique para descarregar a proposta preenchida.</p>
      </>
    )
  } else {
    return (
      <>
      <span className="icon btn btn-circle btn-lg btn-green disabled mb-4"><span className="uil uil-minus"></span></span>
        <h4 className="mb-1"><a class="hover" onClick={this.handleGetPropostaPreenchida} style={{cursor:'pointer'}}>Descarregar Proposta</a></h4>
          <p>Clique para descarregar a proposta preenchida.</p>
      </>
    )
  }
}
certificadoEmitido () {
  if (this.state.certificadoEmitido) {
    return (
      <>
      <span className="icon btn btn-circle btn-lg btn-green disabled mb-4">
        {this.state.error && (<span className="uil uil-multiply"></span>)}
        {!this.state.error && (<span className="uil uil-check"></span>)}
      </span>
        <h4 className="mb-1">
          <a class="text-secondary">Emitir Certificado</a>
        </h4>
        <p>Clique para emitir o certificado provisório.</p>
      </>
    )
  } else {
    return (
      <>
      {!this.state.propostaDescarregada && (
        <>
        <span className="icon btn btn-circle btn-lg btn-soft-blue disabled mb-4"><span className="uil uil-minus"></span></span>
        <h4 className="mb-1">
          <a class="text-secondary" style={{cursor:'pointer'}}>Emitir Certificado</a>
        </h4>
        <p>Clique para emitir o certificado provisório.</p>
        </>
        )}
      {this.state.propostaDescarregada && (
        <>
        <span className="icon btn btn-circle btn-lg btn-green disabled mb-4"><span className="uil uil-minus"></span></span>
        <h4 className="mb-1">
          <a class="hover" onClick={this.handleGetCertificado} style={{cursor:'pointer'}} disabled>Emitir Certificado</a>
        </h4>
        <p>Clique para emitir o certificado provisório.</p>
        </>
        )}
      </>
    )
  }
}
certificadoDescarregado () {
  if (this.state.certificadoDescarregado) {
    return (
      <>
      <span className="icon btn btn-circle btn-lg btn-green disabled mb-4"><span className="uil uil-check"></span></span>
        <h4 className="mb-1">
          <a class="text-green" style={{cursor:'pointer'}}>Descarregar Certificado</a>
        </h4>
        <p>Clique para descarregar o certificado provisório.</p>
      </>
    )
  } else {
    return (
      <>
      {!this.state.certificadoEmitido && !this.state.certificadoLoaded &&(
        <>
        <span className="icon btn btn-circle btn-lg btn-soft-blue disabled mb-4"><span className="uil uil-minus"></span></span>
        <h4 className="mb-1">
          <a class="text-secondary" style={{cursor:'pointer'}} onClick={this.handleObterCertificadoSegurnet}>Descarregar Certificado</a>
        </h4>
        <p>Clique para descarregar o certificado provisório.</p>
        </>
        )}
      {this.state.certificadoEmitido && this.state.error && this.state.num_segurnet === null && !this.state.certificadoLoaded && (
        <>
        <span className="icon btn btn-circle btn-lg btn-soft-blue disabled mb-4"><span className="uil uil-minus"></span></span>
        <h4 className="mb-1">
          <a class="text-secondary" style={{cursor:'pointer'}}>Descarregar Certificado</a>
        </h4>
        <p>Clique para descarregar o certificado provisório.</p>
        </>
        )}
      {this.state.certificadoEmitido && this.state.error && this.state.num_segurnet !== null && !this.state.certificadoLoaded && (
        <>
        <span className="icon btn btn-circle btn-lg btn-soft-blue disabled mb-4"><span className="uil uil-minus"></span></span>
        <h4 className="mb-1">
          <a class="text-secondary">Descarregar Certificado</a>
        </h4>
        <p>Clique para descarregar o certificado provisório.</p>
        </>
        )}
      {this.state.certificadoEmitido && !this.state.error && (
        <>
        <span className="icon btn btn-circle btn-lg btn-green disabled mb-4"><span className="uil uil-minus"></span></span>
        <h4 className="mb-1">
          <a class="text-green" href={'https://api.nacionalgest.pt' + this.state.certificado} target="_blank">Descarregar Certificado</a>
        </h4>
        <p>Clique para descarregar o certificado provisório.</p>
        </>
        )}
      {this.state.error === true && this.state.certificadoLoaded && this.state.num_segurnet !== null  && (
        <>
        <span className="icon btn btn-circle btn-lg btn-green disabled mb-4"><span className="uil uil-minus"></span></span>
        <h4 className="mb-1">
          <a class="text-green" href={'https://api.nacionalgest.pt' + this.state.certificado} target="_blank">Descarregar Certificado</a>
        </h4>
        <p>Clique para descarregar o certificado provisório.</p>
        </>
      )}
      </>
    )
  }
}


  render() {
    const api_link = "https://api.nacionalgest.pt";

  return (
    <div>
    <div className="content-wrapper">
      <HeaderParceiros />
        <section className="wrapper">
    			<div className="container pt-10 pb-12 pt-md-14 pb-md-14 text-center">
    				<div className="row">
    					<div className="col-lg-8 mx-auto">
                <h4>Parceiros - {this.props.location.state.nome_parceiro}</h4>
    						<h1 className="display-1 mb-3">Emissão Certificados UNA</h1>
    					</div>
    				</div>
    			</div>
    		</section>
        <section className="wrapper bg-gray">
    			<div className="container pt-12 pt-md-14 pb-14 pb-md-16">
    				<div className="row gy-10 gy-md-13 gy-lg-0 align-items-center">
    					<div className="col-lg-12 text-center text-lg-start">
            <h2 className="display-5 mb-7">Informação submetida com sucesso.</h2>
            <div className="row gx-lg-8 gx-xl-12 gy-6 mb-6 process-wrapper line">
              <div className="col-md-6 col-lg-4">
                {this.propostaDescarregada()}
              </div>
              {/*/column */}
              <div className="col-md-6 col-lg-4">
                {this.certificadoEmitido()}
              </div>
              {/*/column */}
              <div className="col-md-6 col-lg-4">
                {this.certificadoDescarregado()}
              </div>
              {/*/column */}
            </div>
    					</div>
    				</div>
            {this.state.loadingCertificado === true && (
              <div className="loadingio-spinner-ellipsis-9w2r0d892j full-spinner"><div className="ldio-o8u2rr79tje">
              <div></div><div></div><div></div><div></div><div></div>
              </div></div>
            )}
    				<div className="row gy-10 gy-md-13 gy-lg-0 align-items-center mt-5">
    					<div className="col-lg-12 offset-lg-1 text-center text-lg-start">
  						<div className="d-flex justify-content-center justify-content-lg-start">
                {this.state.certificadoLoaded === false && this.state.error === true && (
                  <div>
                  <h6><span class="text-red">Erro na emissão do certificado (UNA):</span> {this.state.error_description}</h6>
                  {(this.state.num_segurnet !== null) && (
                    <span><button className="btn btn-lg btn-primary me-2 rounded-0" onClick={this.handleObterCertificadoSegurnet}>Obter Certificado Existente</button></span>
                  )}
                  </div>
                )}
              </div>
              </div>
            </div>
    			</div>
    		</section>
        <RequestContact />
        </div>
        {/* /.content-wrapper */}
        <Footer />
        <div className="progress-wrap">
          <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
          </svg>
        </div>
        </div>
  )
}
}

export default SuccessCertificadosParceiros;
