import React from 'react';
import { useFormState } from 'react-final-form';
import axios from 'axios';
import ReactDOM from 'react-dom';
import Footer from '../Footer';
import Header from '../Header';
import RequestContact from '../RequestContact';
import { Form, Field, FormSpy } from 'react-final-form';
import {withRouter} from 'react-router-dom'
import {
  Link
} from "react-router-dom";
import imagemConvencao from '../../assets/img/convencao_23.png';
import imagemConvencao2024 from '../../assets/img/convencao_24.svg';
import imagemFundo from '../../assets/img/fundo_paper.jpg';


const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
export const required = value => (value ? undefined : 'Campo Obrigatório')

function Spinner() {
  return (
      <div className="loadingio-spinner-ellipsis-9w2r0d892j full-spinner"><div className="ldio-o8u2rr79tje">
      <div></div><div></div><div></div><div></div><div></div>
      </div></div>
  )
}

class InscricaoConvencao extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      formSubmitted: false,
      error: null,
    }
  }


  onSubmit = async values => {
    await sleep(300)
    const dev_token = 'f386ee39a5e46a13a460709d0beaf4dd0fff1d90';
    // const dev_token = '0ffc42cbd9ab17ea97ba8eca77a455ea519cdbb5';
    this.setState({loading: true});
    const comp = values.comparencia === "Sim" ? true : false;
    const restri = values.restricoes === "Sim" ? true : false;
    const hotel_choice = values.hotel === "Sim" ? true : false;
    await axios.post(`https://api.nacionalgest.pt/api/eventos/inscricao_convencao_2023/`, {
    // await axios.post(`http://127.0.0.1:8000/api/eventos/inscricao_convencao_2023/`, {
      nome: values.nome,
      telemovel: values.telemovel,
      email: values.email,
      loja: values.loja,
      comparencia: comp,
      restricoes: restri,
      hotel: hotel_choice,
      tipo_restricoes: values.tipo_restricoes,
    }, {
      headers:{
        'Authorization':`Token ${dev_token}`,
  }}).then((response) => {
    console.log(response.data);
    if (response.status === 200) {
      if (response.data.error) {
        this.setState({
          error:response.data.error,
          loading: false
        })
      } else {
      this.setState({
        loading:false,
        formSubmitted: true
      })
    }
  }
  }).catch((error) => {
    this.setState({
      loading: false,
    });
    alert(error);
  });
  }

  handleSubmit = values  => {
      return this.onSubmit(values)
  }

  render() {


    return (
      <div>
      <div className="content-wrapper">
            <Header />
            {/* /header */}
            <section className="wrapper bg-light">
        			<div className="container pt-10 pb-8 pt-md-14 pb-md-12 text-center">
        				<div className="row">
        					<div className="col-lg-8 mx-auto">
        						<h1 className="display-1 mb-3">Inscrição Convenção Anual 2024</h1>
        					</div>
        				</div>
        			</div>
        		</section>
        		<section className="wrapper bg-light">
            <div className="container">
        			<div className="card shadow-lg mb-15 mb-md-14">
        				<div className="row gx-0">
        					<div className="col-lg-6">
        						<div className="p-10 p-md-11 p-lg-11">
                    {this.state.error === 1 && (
                      <>
                      <h1>Já respondeu a este formulário!</h1>
                      <p>Uma resposta com este e-mail já foi submetida neste formulário. </p>
                      <p>Se acredita que esta situação possa estar errada, entre em contacto connosco. </p>
                      </>
                    )}
                    {this.state.formSubmitted && (
                      <>
                      <h1>Obrigado por responder!</h1>
                      <p>Mais perto da data voltaremos a entrar em contacto com mais pormenores. </p>
                      </>

                    )}
                    {!this.state.formSubmitted && this.state.error === null && (
                    <Form
                    onSubmit={this.handleSubmit}
                    initialValues={{
                     }}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-12">
                            <p style={{fontSize:'20px', textAlign: 'justify'}}>Está oficialmente convidado/a para a Convenção Anual da NacionalGest, que terá lugar no dia 20 de abril, no Monte Real Hotel Termas & SPA, sob o tema "Como é que Equipas de Elite Protegem Pessoas?".</p>
                            <p style={{fontSize:'20px', textAlign: 'justify'}}>Espera-nos um dia inesquecível, preenchido com palestras enriquecedoras e uma noite cheia de surpresas e momentos especiais! Esteja pronto para uma experiência extraordinária que o transportará por uma jornada cultural rica em sabores e descobertas.</p>
                            <h6 className="mb-3">Estrutura</h6>
                              <div className="form-label-group">
                                <div className="form-check">
                                  <label className="form-check-label" htmlFor="tipo_estrutura" style={{fontSize: '18px'}}>
                                  <Field
                                    className="form-check-input"
                                    name="tipo_estrutura"
                                    component="input"
                                    type="radio"
                                    value="interno"
                                    required
                                  />{' '}
                                  Interno
                                </label>
                                </div>
                                <div className="form-check mb-4">
                                  <label className="form-check-label" htmlFor="tipo_estrutura" style={{fontSize: '18px'}}>
                                  <Field
                                    className="form-check-input"
                                    name="tipo_estrutura"
                                    component="input"
                                    type="radio"
                                    value="agente"
                                    required
                                  />{' '}
                                  Agente
                                </label>
                                </div>
                              </div>
                              {values.tipo_estrutura === "interno" && (
                              <Field id="loja" name="loja">
                                {({ input, meta }) => (
                                  <div className="form-label-group mb-4">
                                    <select {...input} className="form-select" type="text" component="select" required>
                                        <option disabled selected value="">Seleccione uma opção</option>
                                        <option value="loja_virtual">Loja Virtual</option>
                                        <option value="norte">Norte</option>
                                        <option value="beira_alta">Beira Alta</option>
                                        <option value="centro">Centro</option>
                                        <option value="ribatejo">Ribatejo</option>
                                        <option value="lisboa">Grande Lisboa</option>
                                        <option value="alentejo">Alentejo</option>
                                        <option value="algarve">Algarve</option>
                                        <option value="sinistros">Sinistros</option>
                                        <option value="dir_corporate">Direção Corporate e Institucionais</option>
                                        <option value="dir_operacoes">Direção de Operações</option>
                                        <option value="dir_financeira">Direção Financeira</option>
                                        <option value="dir_informatica">Direção Informática</option>
                                        <option value="dir_marketing">Direção de Marketing</option>
                                    </select>
                                    {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                  </div>
                                )}
                              </Field>
                            )}
                            {values.tipo_estrutura === "agente" && (
                            <Field id="loja" name="loja">
                              {({ input, meta }) => (
                                <div className="form-label-group mb-4">
                                  <select {...input} className="form-select" type="text" component="select" required>
                                      <option disabled selected value="">Seleccione uma opção</option>
                                      <option value="agente_norte">Norte</option>
                                      <option value="agente_beira_alta">Beira Alta</option>
                                      <option value="agente_centro">Centro</option>
                                      <option value="agente_ribatejo">Ribatejo</option>
                                      <option value="agente_lisboa">Grande Lisboa</option>
                                      <option value="agente_alentejo">Alentejo</option>
                                      <option value="agente_algarve">Algarve</option>
                                  </select>
                                  {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                </div>
                              )}
                            </Field>
                          )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">

                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <Field id="nome" name="nome" validate={required}>
                              {({ input, meta }) => (
                                <div className="form-label-group mb-4">
                                  <input {...input} className="form-control" type="text" component="input" placeholder="nome" />
                                  <label htmlFor="nome">Nome</label>
                                  {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                </div>
                              )}
                            </Field>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <Field id="email" name="email" validate={required}>
                              {({ input, meta }) => (
                                <div className="form-label-group mb-4">
                                  <input {...input} className="form-control" type="email" component="input" placeholder="E-mail" />
                                  <label htmlFor="email">E-mail</label>
                                  {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                </div>
                              )}
                            </Field>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <Field id="telemovel" name="telemovel" validate={required}>
                              {({ input, meta }) => (
                                <div className="form-label-group mb-4">
                                  <input {...input} className="form-control" type="text" component="input" placeholder="Telemóvel" />
                                  <label htmlFor="telemovel">Telemóvel</label>
                                  {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                </div>
                              )}
                            </Field>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-label-group">
                            <div className="form-check">
                              <label className="form-check-label" htmlFor="comparencia" style={{fontSize: '18px'}}>
                              <Field
                                className="form-check-input"
                                name="comparencia"
                                component="input"
                                type="radio"
                                value="Sim"
                                required
                              />{' '}
                              Vou à Convenção
                            </label>
                            </div>
                            <div className="form-check mb-4">
                              <label className="form-check-label" htmlFor="comparencia" style={{fontSize: '18px'}}>
                              <Field
                                className="form-check-input"
                                name="comparencia"
                                component="input"
                                type="radio"
                                value="Nao"
                                required
                              />{' '}
                              Não vou à Convenção
                            </label>
                            </div>
                          </div>
                        </div>
                        {values.comparencia === "Sim" && (
                          <div className="row">
                            <div className="form-label-group">
                              <h6 className="mb-3">Restrições Alimentares</h6>
                              <div className="form-check">
                                <label className="form-check-label" htmlFor="restricoes" style={{fontSize: '18px'}}>
                                <Field
                                  className="form-check-input"
                                  name="restricoes"
                                  component="input"
                                  type="radio"
                                  value="Sim"
                                  required
                                />{' '}
                                Sim
                              </label>
                              </div>
                              <div className="form-check mb-4">
                                <label className="form-check-label" htmlFor="restricoes" style={{fontSize: '18px'}}>
                                <Field
                                  className="form-check-input"
                                  name="restricoes"
                                  component="input"
                                  type="radio"
                                  value="Nao"
                                  required
                                />{' '}
                                Não
                              </label>
                              </div>
                            </div>
                          </div>
                        )}
                        {values.comparencia === "Sim" && values.restricoes === "Sim" && (
                          <div className="row mb-4">
                            <div className="col-md-12">
                            <Field id="tipo_restricoes" name="tipo_restricoes" className="form-control" component="textarea" placeholder="Restrições">
                            </Field>
                          </div>
                          </div>
                        )}
                        {values.comparencia === "Sim" && values.loja && (

                          <div className="row">
                            <div className="form-label-group">
                              <h6 className="mb-3">Estadia</h6>
                              <p>A nossa convenção está a chegar, e como sempre, esperamos que seja um dia memorável para todos. Uma oportunidade para socializar e conhecer melhor os colegas. O sucesso deste evento está dependente da presença de todos, pelo que é imperativo a sua presença até ao final.</p>
                              <p>Dado que o evento irá prolongar-se até tarde (aprox. 01:00h da manhã), e de forma a garantir a sua segurança e o seu conforto, a NacionalGest reservou alojamento para si, o qual não é reembolsável em caso de cancelamento.</p>
                              <p>Contamos consigo ao pequeno almoço! &#128521;</p>
                              <div className="form-check">
                                <label className="form-check-label" htmlFor="hotel" style={{fontSize: '18px'}}>
                                <Field
                                  className="form-check-input"
                                  name="hotel"
                                  component="input"
                                  type="radio"
                                  value="Sim"
                                  required
                                />{' '}
                                Durmo no hotel
                              </label>
                              </div>
                              <div className="form-check mb-4">
                                <label className="form-check-label" htmlFor="hotel" style={{fontSize: '18px'}}>
                                <Field
                                  className="form-check-input"
                                  name="hotel"
                                  component="input"
                                  type="radio"
                                  value="Nao"
                                  required
                                />{' '}
                                Não durmo no hotel
                              </label>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="row">
                          <div className="col-md-12" style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <button type="submit" style={{width:'100%'}} className="btn btn-primary rounded-0 btn-send mb-3" disabled={submitting || pristine}>
                              Submeter
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  />)}
              </div>
        					</div>
                  {!this.state.formSubmitted && (
                    <div className="col-lg-6">
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', backgroundImage: `url(${imagemFundo})`, backgroundPosition: 'center', backgroundSize:'100% 100%'}}>
                        <img src={imagemConvencao2024} alt="Convencao 2024" style={{ maxWidth: '100%', maxHeight: '100%', mixBlendMode:'multiply' }} />
                      </div>
                    </div>

                )}
        				</div>
        			</div>
        		</div>
          </section>
            <RequestContact />
          </div>
          {/* /.content-wrapper */}
          <Footer />
            {this.state.loading && (
              <Spinner />
            )}
          <div className="progress-wrap">
            <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
              <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
            </svg>
          </div>
          </div>
  )
}
}

export default withRouter(InscricaoConvencao);
