import React from 'react';
import PropTypes from 'prop-types'
import DatePicker from "react-datepicker";
import { Redirect } from 'react-router-dom';
import { Form, useFormState, Field, FormSpy } from 'react-final-form';
import {withRouter} from 'react-router-dom'
import axios from 'axios';
import ReactDOM from 'react-dom';
import { DateRange } from 'react-date-range';
import { customStyles } from './customStyles';
import {
  verifyPoliticaPrivacidade,
  verifyAceitacaoTermos,
  normalizePostalCode,
  nifSuccess,
  required,
  mustBeNumber,
  minValue,
  twoWords,
  normalizeNIF,
  composeValidators,
  verifyPostalCode,
  sleep,
  Error,
  Condition,
  ConditionWhenNot} from './Utils';
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import pt from 'date-fns/locale/pt';
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";


function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}



// LIMITAR DATA MAXIMA DATA NASCIMENTO

var dtToday = new Date();
var month = dtToday.getMonth() + 1;     // getMonth() is zero-based
var day = dtToday.getDate();
var year = dtToday.getFullYear();
if(month < 10)
   month = '0' + month.toString();
if(day < 10)
   day = '0' + day.toString();
export var maxDate = year + '-' + month + '-' + day;

// -----------------------------------------------

// Converter string to date object
function parseDate(s) {
  var b = s.split(/\D/);
  return new Date(b[0], --b[1], b[2]);
}

// VERIFY CARTA DE CONDUCAO
var currentDate = new Date().getFullYear();

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}
// const validateForm = values => {
//   if (typeof values.dataChegada !== undefined || values.dataChegada.length === 0)
//     return { dataChegada: "must choose one" };
// };

// CONDICOES FORM Predictable

const ConditionTipoViagem = ({ when, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value !== "ND" ? children : null)}
  </Field>
)

const ReactSelectAdapter = ({ input, ...rest }) => (
  <Select {...input} {...rest} searchable />
)


const ReactDatePickerAdapter = ({ input: { onChange, value }, ...rest }) => (
  <DatePicker
    className="form-control remove-padding"
    placeholderText="Seleccionar data"
    locale={pt}
    dateFormat="dd/MM/yyyy"
    selected={value}
    onChange={date => onChange(date)} {...rest} />
);




class SimulatorFormPredictable extends React.Component {
  // static propTypes = {
  //   onSubmit: PropTypes.func.isRequired
  // }
  async componentDidMount() {
    const dev_token = 'f386ee39a5e46a13a460709d0beaf4dd0fff1d90';
    const prod_token = '';
    const predictable_token = JSON.parse(sessionStorage.getItem('predictable_token'));
    this.setState({ loadingInicial: true});
    await axios.post(`https://api.nacionalgest.pt/api/protocolos/predictable/obter_lista_subtipos_viagem/`, {
      tipo: 'VACACIONES',
    }, {
      headers:{
        'Authorization':`Token ${dev_token}`,
        'Token':`Bearer ${predictable_token}`
      }
    }).then(response => {
      var result = response.data.items;
      var obj = {'code':'', "name":"Avião + Hotel/ Só Avião"}
      var newResult = [...result, obj]

      result.unshift(obj)

      const data = response.data.items;
      console.log(result);
      this.setState({
        subtiposViagem: result,
        loadingInicial: false})
    })
    await axios.get(`https://api.nacionalgest.pt/api/protocolos/predictable/obter_info_detalhada/select/`, {
      headers:{
        'Authorization':`Token ${dev_token}`,
        'Token':`Bearer ${predictable_token}`
    }
    }).then(response => {
    const data = response.data.items;
    this.setState({
      predictable: data,
      loading: false})
    })
}

constructor(props) {
  super(props);
  this.handleChange = this.handleChange.bind(this);
  this.onStateChange = this.onStateChange.bind(this);
  this.state = {
    dataPartida: new Date(),
    dataPartida: new Date(),
    subtiposViagem: [],
    subSubtiposViagem: [],
    dataChegada: null,
    loading: false,
    loadingInicial: false,
    tipoViagem: 'VACACIONES',
    subtipoViagem: '',
    numPessoas: 1,
    valorAlojamento: 0,
    preexistencias: false,
    predictable: {
      warranty: []
    }
  }
}


  onStateChange = values => {
    this.setState({ data: values });
  };
  handleChange = e => this.setState({ [e.target.name]: e.target.value });
  handleDataPartida(date){
    this.setState({
      dataPartida: date
    }) // native Date object
  }
  handleDataChegada(date){
    this.setState({
      dataChegada: date
    }) // native Date object
  }

  async getLocalidade(values) {

    let regexp = /(?<fourDigit>[0-9]{4})-(?<threeDigit>[0-9]{3})/g;
    if (values !== undefined) {
      if ((values.match(regexp) !== null) && (!this.state.localidade)){
        var codPostal = values.replace(/-/g, "");
        const response = await axios.get('https://api.duminio.com/ptcp/ptapi60d599d14b73f0.17454259/' + codPostal);
      this.setState({
        localidade: response.data.Localidade,
        concelho: response.data.Concelho
      });
    }
  }
  }

  getMaxInicioSeguro() {
    var dtToday = new Date();
    var threeMontsFromToday = dtToday.setMonth(dtToday.getMonth()+2);     // getMonth() is zero-based
    var day = dtToday.getDate();
    var month = dtToday.getMonth();
    var year = dtToday.getFullYear();
    if(month < 10)
       month = '0' + month.toString();
    if(day < 10)
       day = '0' + day.toString();
    var result = year + '-' + month + '-' + day;
    return result;
  }

  getToken() {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token
  }

  obterSubtiposViagemLazer() {
    const dev_token = 'f386ee39a5e46a13a460709d0beaf4dd0fff1d90';
    const prod_token = '';
    const predictable_token = JSON.parse(sessionStorage.getItem('predictable_token'));
    this.setState({ loading: true}, () => {
    axios.post(`https://api.nacionalgest.pt/api/protocolos/predictable/obter_lista_subtipos_viagem/`, {
      tipo: 'VACACIONES',
    }, {
      headers:{
        'Authorization':`Token ${dev_token}`,
        'Token':`Bearer ${predictable_token}`
    }
  }).then(response => {
    var result = response.data.items;
    var obj = {'code':'', "name":"Avião + Hotel/ Só Avião"}
    var newResult = [...result, obj]

    result.unshift(obj)

    const data = response.data.items;
    this.setState({
      subtiposViagem: result,
      loading: false})
  })
})
}

// obterSubSubtiposViagem(values) {
//   const dev_token = 'f386ee39a5e46a13a460709d0beaf4dd0fff1d90';
//   const prod_token = '';
//   const predictable_token = JSON.parse(sessionStorage.getItem('predictable_token'));
//   if (values.tipoViagem !== 'ND' && values.subtipoViagem !== 'ND') {
//   this.setState({ loading: true}, () => {
//   axios.post(`https://api.nacionalgest.pt/api/protocolos/predictable/obter_lista_sub_subtipos_viagem/`, {
//     tipo: values.tipoViagem,
//     subtipo: values.subtipoViagem,
//   }, {
//     headers:{
//       'Authorization':`Token ${dev_token}`,
//       'Token':`Bearer ${predictable_token}`
//   }
// }).then(response => {
//   const data = response.data.items;
//   this.setState({
//     subSubtiposViagem: data,
//     loading: false})
// })
// })}
// }
onSubmit = async (values) => {
  await sleep(300)
  const dev_token = 'f386ee39a5e46a13a460709d0beaf4dd0fff1d90';
  const prod_token = '';
  const predictable_token = JSON.parse(sessionStorage.getItem('predictable_token'));
  var lista = []
  values.paises.forEach(function(name){
    lista.push(name.value);
  })
  console.log(lista);
  console.log(typeof lista);
  console.log(typeof values.paises);

  var dataPartidaFormatada = formatDate(values.dataPartida);
  var dataChegadaFormatada = formatDate(values.dataChegada);
  console.log(dataPartidaFormatada);
  this.setState({loading: true});
  await axios.post(`https://api.nacionalgest.pt/api/protocolos/predictable/simulacao_select/`, {
    lista_paises: values.paises,
    subtipo_viagem: values.subtipoViagem,
    num_pessoas: values.numPessoas,
    pais_origem: values.paisOrigem,
    data_partida: dataPartidaFormatada,
    data_chegada: dataChegadaFormatada,
    valor_alojamento: values.valorAlojamento,
    pre_existencias: values.preexistencias,
  }, {
    headers:{
      'Authorization':`Token ${dev_token}`,
      'Token':`Bearer ${predictable_token}`
  }}).then((response) => {
    console.log(response.data);
    console.log(response.status);
    if (response.status === 200) {
      this.setState({loading:false})
      var pre_existencias = values.preexistencias;
      if (values.preexistencias === undefined) {
        pre_existencias = false;
      }
      this.props.history.push({
        state: {
          data: response.data.items,
          terrorismo: pre_existencias,
          subtipo_viagem: values.subtipoViagem,
          listaPaises: this.props.data.listaPaises,
          // subtipo_viagem: values.subtipoViagem,
        },
        pathname:'/parceiros/particulares/viagem/simulacao',
    })
  }
}).catch((error) => {
    this.setState({loading: false});
  alert(error);
});
}

handleSubmit = values  => {
    return this.onSubmit(values)
}

  render() {
    const maxInicioSeguro = this.getMaxInicioSeguro();
    const numPessoasRange = Array.from(Array(10).keys());
    const loading = this.state.loading;
    const loadingInicial = this.state.loadingInicial;
    const token = this.getToken();
    var dataInicio = this.state.dataPartida;
    var month = dataInicio.getMonth() + 1;     // getMonth() is zero-based
    var day = dataInicio.getDate();
    var year = dataInicio.getFullYear();
    if(month < 10)
       month = '0' + month.toString();
    if(day < 10)
       day = '0' + day.toString();
    var minDate = year + '-' + month + '-' + day;

    const selectionRange = {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    }

    var listaPaises = [];
    var listaPaisesState = this.props.data.listaPaises;

    listaPaisesState.forEach((entry) => {
      var singleCountry =  {};
      singleCountry['label'] = entry.name;
      singleCountry['value'] = entry.code;
      listaPaises.push(singleCountry);
    });


    if(!token) {
      return <Redirect to="/parceiros" />
    }
if (token) {
  if (loadingInicial) {
  return (
    <div className="loadingio-spinner-ellipsis-9w2r0d892j full-spinner"><div className="ldio-o8u2rr79tje">
    <div></div><div></div><div></div><div></div><div></div>
    </div></div>
  )
}
    return (
      <div id="formulario">
        <section className="wrapper bg-light wrapper-border">
          <div className="container pb-10 pt-md-0">
            <div className="card bg-soft-blue mb-10">
              <div className="card-body p-12 pb-0">
              <div className="row">
                <div className="col-12">
                    <div className="row">
                      <div className="col-lg-12 offset-lg-1">
                        <h2 className="display-6 mb-4">Seguro de Viagem mais Barato</h2>
                        <div className="row gx-0">
                          <div className="col-md-9 text-justify">
                            <p>Conseguir encontrar o <strong>Seguro de Viagem mais Barato</strong> e ideal para as suas necessidades envolve muito tempo gasto em pesquisa de preços e respetivas coberturas. E a dificuldade não está em apenas encontrar o <strong>Seguro de Viagem Barato</strong> que mais se adequa a si. Temos de ter em conta as apólices de menor valor que apresentam coberturas bastante limitadas, o que nem sempre pode ser adequado aos seus planos ou ao destino da sua viagem.</p>
                            <p>Contratar o <strong>Seguro de Viagem mais Barato</strong>, no entanto, é fundamental a quem viaja com um orçamento apertado. Mas viajar com uma apólice de cobertura reduzida pode ser tão prejudicial como viajar sem seguro, dependendo sobretudo do destino para o qual pretende viajar. Se na Europa a contratação de um Seguro de Viagem pode não se assumir como uma prioridade, já uma viagem com destino a África ou a América do Sul, por exemplo, exige uma maior preocupação, uma vez que convém estar protegido contra eventuais problemas de saúde associados ao próprio clima.</p>
                          </div>
                          {/*/column */}
                        </div>
                        {/*/.row */}
                      </div>
                      {/* /column */}
                    </div>
                    {/*/.row */}
                    <div className="row mt-5 gx-md-6 gy-6 light-gallery-wrapper">
                      <div className="item col-md-6">
                        <figure className="rounded"><img src="https://bo.predictable.pt/filemanager/imagens/AD%20Strategy/23_06_2020/contratar-seguro-viagem-barato.jpg" alt="" /></figure>
                      </div>
                      {/*/column */}
                      <div className="item col-md-6">
                        <figure className="rounded"><img src="https://bo.predictable.pt/filemanager/imagens/AD%20Strategy/23_06_2020/seguro-viagem-bagagem.jpg" alt="" /></figure>
                      </div>
                    </div>
                    {/* /.row */}
                    <div className="row mt-8 mt-md-12">
                      <div className="col-md-10 offset-md-1 text-justify">
                        <h2 className="mb-4">Internacional Select</h2>
                        <p>Por isso apresentamos o Seguro Select - O Seguro de Viagem mais completo do mercado. Um Seguro de Viagem Barato, abrangente e versátil que lhe permitirá ter uma viagem tranquila. Não arrisque!</p>
                        <p>Um Seguro de Viagem é importante para lhe dar todas as garantias necessárias para se deslocar com a segurança de estar protegido em todas e quaisquer circunstâncias.</p>
                        <p>Proteção em caso de acidente ou imprevistos durante a viagem. Cobertura de despesas médicas realizadas durante as férias, em consequência de doença ou de acidente. Assistência personalizada em viagem com vários serviços de apoio ao viajante. Garantia de imprevistos como atrasos, cancelamento ou interrupção de viagem. Salvaguarda de eventuais estragos, perdas, furtos ou roubos de bagagem.
                        </p>
                      </div>
                      <div className="row mt-5 gx-md-6 gy-6 light-gallery-wrapper">
                        <div className="item col-md-6">
                          <figure className="rounded"><img src="https://bo.predictable.pt/filemanager/imagens/AD%20Strategy/23_06_2020/seguro-viagem-barato-ferias.jpg" alt="" /></figure>
                        </div>
                        <div className="item col-md-6">
                          <figure className="rounded"><img src="https://cdn.predictable.pt/imagens/familia_com_babe_surf.jpg" alt="" /></figure>
                        </div>
                      </div>
                      {/* /.row */}
                      <div className="row mt-8 mt-md-12">
                        <div className="col-md-10 offset-md-1 text-justify">
                          <p>O Seguro de Viagem Select é assim, um Seguro de Viagem Barato e de multiassistência perfeito para se deslocar com a máxima tranquilidade em Portugal e no estrangeiro. Inclui coberturas como assistência médica, repatriamento, bagagem, responsabilidade civil ou seguro de cancelamento.</p>
                          <p>Se quer estar despreocupado/a e viajar com a máxima tranquilidade, o Seguro de Viagem Select é o seu seguro. Além disso, com o Seguro de Viagem Select pode personalizar a cobertura que quiser contratar. Adapte a cobertura de assistência ou de despesas de cancelamento em função do seu tipo de viagem. Amplie os seus limites quando necessitar e viaje com um seguro totalmente à medida.</p>
                          <p><strong>NÃO DISPENSA A CONSULTA DA INFORMAÇÃO PRÉ-CONTRATUAL E CONTRATUAL LEGALMENTE EXIGIDA.</strong></p><p><strong>PARA CONSULTA DAS EXCLUSÕES E CLÁUSULAS LIMITATIVAS A ESTE CONTRATO, DEVE CONSULTAR AS CONDIÇÕES GERAIS NO CAPÍTULO EXCLUSÕES.</strong></p>
                          <p><a href="#">FICHA DE PRODUTO</a></p>
                        </div>
                        {/* /column */}
                      </div>
                      {/* /.row */}
                    </div>
                  {/* /.project */}
                </div>
                {/* /column */}
              </div>
              <div className="row mt-10">
                <div className="col-lg-12">
                  <div id="accordion-1" className="accordion-wrapper">
                    <div className="card">
                      <div className="card-header" id="accordion-heading-1-1">
                        <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#accordion-collapse-1-1" aria-expanded="true" aria-controls="accordion-collapse-1-1">Porquê o <strong>Seguro de Viagem</strong> Internacional Select?</button>
                      </div>
                      {/* /.card-header */}
                      <div id="accordion-collapse-1-1" className="collapse" aria-labelledby="accordion-heading-1-1" data-bs-target="#accordion-1">
                        <div className="card-body">
                          <p className="text-green">O QUE A GARANTIA DE ASSISTÊNCIA MÉDICA DO SEU SEGURO DE VIAGEM COBRE?</p>
                          <p>Esta garantia cobre as despesas derivadas de qualquer acidente ou doença que sofra durante a viagem e até ao limite que tiver contratado na sua apólice. Assumimos os custos de uma consulta médica, hospitalização, intervenção cirúrgica, tratamento médico e até mesmo de tratamentos odontológicos de urgência. Além disso, terá à sua disposição a melhor rede de assistência médica internacional em qualquer parte do mundo. A nossa equipa está disponível 24 horas por dia, 7 dias por semana e 365 dias por ano para o/a atender se tiver qualquer problema de saúde durante as suas férias.</p>
                          <p className="text-green">REPATRIAMENTO ILIMITADO</p>
                          <p>Pagamos todos os custos de repatriamento de feridos, doentes e falecidos de forma ilimitada. Isto significa que organizamos o seu regresso a casa e colocamos à sua disposição transporte sanitário adaptado, se for necessário. Também pagamos os custos de repatriamento do seu acompanhante e dos menores que viagem consigo e trataremos de o/a trazer de volta à origem por falecimento ou hospitalização de um familiar, entre outros motivos.</p>
                          <p className="text-green">COBERTURA DE ACIDENTES</p>
                          <p>Esta cobertura garante a obtenção de uma indemnização em caso de morte ou invalidez, devido a um acidente durante a sua viagem. Os custos de medidas de socorro também estão cobertos até ao limite previsto no seu seguro.</p>
                          <p>O SEU SEGURO COBRE AS DESPESAS EM CASO DE PERDA, ROUBO OU ATRASO DE BAGAGEM.</p>
                          <p>Se sofrer um roubo, perda ou atraso de bagagem garantimos-lhe a indemnização da perda dos seus materiais e bens pessoais até ao limite que tiver escolhido. Além disso, ajudá-lo/a-emos a gerir a reclamação da sua mala e a descobrir a localização desta, assim como na gestão da perda dos seus documentos de viagem.</p>
                          <p className="text-green">PORQUÊ UMA COBERTURA DE CANCELAMENTO NO SEU SEGURO DE VIAGEM SELECT?</p>
                          <p>Porque se surgir um imprevisto que o/a impeça de fazer a viagem, no último momento, recuperará o dinheiro que tiver adiantado para a sua viagem. A garantia de cancelamento do seguro de viagem Select cobrirá até 30 causas diferentes, incluindo em caso de impossibilidade de viajar devido a um acidente, falecimento ou doença do segurado ou de um familiar, causas laborais como despedimento profissional ou integração num novo posto de trabalho ou legais como citação por um tribunal, convocação para uma mesa eleitoral ou apresentação a concursos, entre muitas outras causas. Consulte as 30 causas abrangidas no capítulo relativo à cobertura. Esta garantia só será válida quando tiver sido subscrita no momento da inscrição ou confirmação da viagem.</p>
                          <p className="text-green">Lembre-se</p>
                          <p>Esta garantía deve ser contratada no momento da reserva da viagem, ou até à confirmação da mesma. Ainda assim, poderá contratar o seguro depois deste período, em cujo caso será aplicado um período de carência de 72 horas após a contratação do seguro.</p>
                        </div>
                        {/* /.card-body */}
                      </div>
                      {/* /.collapse */}
                    </div>
                    {/* /.card */}
                    <div className="card">
                      <div className="card-header" id="accordion-heading-1-2">
                        <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#accordion-collapse-1-2" aria-expanded="false" aria-controls="accordion-collapse-1-2">Ver todas as garantias</button>
                      </div>
                      {/* /.card-header */}
                      <div id="accordion-collapse-1-2" className="collapse" aria-labelledby="accordion-heading-1-2" data-bs-target="#accordion-1">
                        <div className="card-body">
                          {this.state.predictable.warranty.map((option) =>
                            <>
                            <h4 className="h4 mt-8 mb-4 text-green">{option.warrantyGroupName}</h4>
                            {option.items.map((option_2) => (
                              <>
                              <div className="d-flex flex-row-coberturas">
                  							<div className="flex-column">
                  								<span style={{fontSize:'14px'}}>{option_2.name} </span>
                  							</div>
                  							<div className="flex-column">
      								            <span style={{fontSize:'14px'}}>€ {option_2.value} </span>
                  							</div>
                  						</div>
                              <hr className="mt-2 mb-2"/>
                              </>
                            ))}
                        </>)}
                        </div>
                        {/* /.card-body */}
                      </div>
                      {/* /.collapse */}
                    </div>
                    {/* /.card */}
                    <div className="card">
                      <div className="card-header" id="accordion-heading-1-5">
                        <a className="collapsed" href={'https:' + this.state.predictable.informationPdf} style={{fontWeight:'700',color:'#1b647c'}} target="_blank" aria-expanded="false">Informação Prévia <i className="uil uil-file-download-alt text-green" /></a>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="accordion-heading-1-3">
                        <a className="collapsed" href={'https:' + this.state.predictable.generalConditionsPdf} target="_blank" style={{fontWeight:'700',color:'#1b647c'}} aria-expanded="false">Consultar condições gerais do Seguro de Viagem Internacional Select <i className="uil uil-file-download-alt text-green" /></a>
                      </div>
                    </div>
                    {/* /.card */}
                  </div>
                </div>
              </div>
              <div className="row mt-15">
                <div className="col-lg-12 text-center">
                  <h1 className="display-2">Faça agora a sua simulação</h1>
                  <p className="lead">Utilize o simulador abaixo para calcular o prémio para o seu seguro</p>
                </div>
              </div>
              {/* /.row */}
              <Form
                initialValues={{
                  subtipoViagem: ''
                }}
              onSubmit={this.handleSubmit}
              render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit}>
                <div className="row mt-2">
                  <div className="col-md-6">
                    <h6 className="mb-2">País de Origem</h6>
                    <Field id="paisOrigem" name="paisOrigem" validate={composeValidators(required)}>
                      {({ input, meta }) => (
                        <div className="form-label-group mb-4">
                          <select {...input} className="form-select" type="text" component="select">
                            <option value={"ND"}>País</option>
                              {this.props.data.listaPaises.map((option) => (
                              <option value={option.code} key={option.code}>{option.name}</option>
                              ))}
                          </select>
                        {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                        </div>
                      )}
                    </Field>
                  </div>
                <div className="col-md-6">
                  <h6 className="mb-2">País de destino</h6>
                  <div className="form-label-group mb-4">
                    <Field
                      component={ReactSelectAdapter}
                        closeMenuOnSelect={false}
                        isMulti
                        name="paises"
                        styles={customStyles}
                        options={listaPaises}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Seleccionar países"
                        validate={required}/>
                      <Error name="paises" />
                    </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <h6 className="mb-2">Data de Partida</h6>
                  <div className="form-label-group mb-4">
                    <Field
                      component={ReactDatePickerAdapter}
                      name="dataPartida"
                      minDate={this.state.dataPartida}
                      validade={required}
                      />
                  </div>
                </div>
                <div className="col-md-6">

                    <h6 className="mb-2">Data de Chegada</h6>
                      <div className="form-label-group mb-4">
                        <Field
                          component={ReactDatePickerAdapter}
                          name="dataChegada"
                          minDate={values.dataPartida}
                          validate={required}
                          />
                      </div>
                  </div>
                </div>
              <div className="row">
                <div className="col-md-6">
                  <h6 className="mb-2">Tipo de Viagem</h6>
                      <h5 className="mb-2"></h5>
                      <Field id="subtipoViagem" name="subtipoViagem" defaultValue={this.state.subtiposViagem[0]}>
                        {({ input, meta }) => (
                          <div className="form-label-group mb-4">
                            <select {...input} className="form-select" type="text" component="select">
                                {this.state.subtiposViagem.map((option) => (
                                <option value={option.code} key={option.name}>{option.name}</option>
                                ))}
                            </select>
                            {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                          </div>
                        )}
                      </Field>
                      {/*<Condition when="subtipoViagem" is="PRACTICADEPORTIVA">
                        <h5 className="mb-2">Desporto</h5>
                        <Field id="subSubtipoViagem" name="subSubtipoViagem">
                          {({ input, meta }) => (
                            <div className="form-label-group mb-4">
                              <select {...input} className="form-select" type="text" component="select">
                                <option value={"ND"}>Não Especificar (opcional)</option>
                                  {this.state.subSubtiposViagem.map((option) => (
                                  <option value={option.code} key={option.name}>{option.name}</option>
                                  ))}
                              </select>
                              {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                            </div>
                          )}
                        </Field>
                      </Condition>*/}
                      <Condition when="subtipoViagem" is="ESTANCIAS">
                        <div className="col-md-6">
                          <h6 className="mb-2">Valor do Alojamento</h6>
                          <Field id="valorAlojamento" className="form-control" name="valorAlojamento" validate={composeValidators(required)} type="number">
                            {({ input, meta }) => (
                              <div className="form-label-group mb-4" id="currency-inline">
                                <input {...input} className="form-control" type="number" component="input" style={{'padding':'0.6rem 1rem'}}  min="0"/>
                                  <span className="input-group-append" style={{position: 'inherit', left: '-30px'}}>
                                      <i className="uil uil-euro"></i>
                                  </span>
                                {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                              </div>
                            )}
                          </Field>
                        </div>
                      </Condition>
                  </div>
                <div className="col-md-6">
                  <div className="row">
                  <Condition when="tipoViagem" is="NEGOCIOS">
                    <>
                    <div className="col-md-6">
                      <h5 className="mb-2">Grau de Risco 1</h5>
                      <Field id="grauRisco1" className="form-select" name="grauRisco1" validate={composeValidators(required)}>
                        {({ input, meta }) => (
                          <div className="form-label-group mb-4">
                            <div className="form-label-group mb-4">
                              <input {...input} className="form-control" type="number" component="input" style={{'padding':'0.6rem 1rem'}}  min="1" max="99"/>
                              {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                            </div>
                            {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col-md-6">
                      <h5 className="mb-2">Grau de Risco 2</h5>
                      <Field id="grauRisco2" className="form-select" name="grauRisco2" validate={composeValidators(required)}>
                        {({ input, meta }) => (
                          <div className="form-label-group mb-4">
                            <div className="form-label-group mb-4">
                              <input {...input} className="form-control" type="number" component="input" style={{'padding':'0.6rem 1rem'}}  min="1" max="99"/>
                              {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                            </div>
                            {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                          </div>
                        )}
                      </Field>
                    </div>
                  </>
                </Condition>
                  </div>
                  <ConditionWhenNot when="tipoViagem" is="NEGOCIOS">
                    <div className="col-md-12">
                      <h6 className="mb-2">Nº de Pessoas</h6>
                      <Field id="numPessoas" className="form-control" name="numPessoas" validate={composeValidators(required)} type="number">
                        {({ input, meta }) => (
                          <div className="form-label-group mb-4">
                            <input {...input} className="form-control" type="number" component="input" style={{'padding':'0.6rem 1rem'}}  min="1" max="99"/>
                            {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                          </div>
                        )}
                      </Field>
                    </div>
                  </ConditionWhenNot>
                </div>
              </div>
            <div className="row">
              <div className="col-lg-12">
                <Field name="preexistencias" type="checkbox" >
                  {({ input, meta }) => (
                    <div className="form-check mb-2">
                      <input {...input} id="preexistencias" className="form-check-input" defaultValue="false"/>
                      <label htmlFor="preexistencias" style={{ lineHeight: 1.3 }}>Contratar pacote opcional para Cobertura de pré-existências médica e terrorismo?</label>
                      {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                    </div>
                  )}
                </Field>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12" style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                <button type="submit" style={{width:'100%'}} className="btn btn-primary rounded-0 btn-send mb-3">
                  Calcular
                </button>
              </div>
            </div>
            {/*<FormSpy subscription={{ values: true, pristine: true }}
              onChange={(props) => {
              this.obterSubSubtiposViagem(props.values);
            }}></FormSpy>*/}
          {loading === true && (
            <div className="loadingio-spinner-ellipsis-9w2r0d892j full-spinner"><div className="ldio-o8u2rr79tje">
            <div></div><div></div><div></div><div></div><div></div>
            </div></div>
          )}
      </form>
    )}
    />
  </div>
</div>
</div>

</section>

    </div>
  )}
  }
}

  export default withRouter(SimulatorFormPredictable);
