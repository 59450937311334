import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios';
import { Form } from 'react-final-form'
import {withRouter} from 'react-router-dom'

class Wizard extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired
  }
  static Page = ({ children }) => children

  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      values: props.initialValues || {},
      parentState: props.parentState || {},
      visitedStepZero: false,
      premio: props.premio || '',
      loadingBrands: false
    }
  }

  componentDidMount () {
    axios.get(`https://api.nacionalgest.pt/api/motos/obter_condicoes_gerais`, {
      headers:{
        'Authorization':`Token f386ee39a5e46a13a460709d0beaf4dd0fff1d90`
    }})
    .then(response => this.setState({
      cg_url: `https://api.nacionalgest.pt${response.data}`,
    }))
    .catch(function(error) {
      console.log(error);
    });
  }




  next = values => {
    this.setState(state => ({
      page: Math.min(state.page + 1, this.props.children.length - 1),
      values,
      parentState: this.props.parentState
    }))
    console.log(this.props.premio);

    // AWAITS FOR SYNC setState to be updated
    if (this.state.page === 0 && this.state.visitedStepZero === false) {
    this.setState({ nifLoaded: true }, function () {

        axios.post(`https://api.nacionalgest.pt/api/criar_lead/`, {
          name: this.state.values.nomeCompleto,
          address: this.state.values.morada,
          cod_postal:this.state.values.codigoPostal,
          localidade:this.state.parentState.localidade,
          nif:this.state.values.nif,
          data_nascimento:this.state.values.dataNascimento,
          telemovel:this.state.values.telemovel,
          email:this.state.values.email,
          produto_interesse:'MOTO'
        }, {
        headers:{
          'Authorization':`Token f386ee39a5e46a13a460709d0beaf4dd0fff1d90`
        }});
        this.setState(state => ({
          visitedStepZero: true,
        }))
      });
      }

    if (window.matchMedia("(min-width: 771px)").matches) {
    window.scrollTo(0, 400)
    } else {
    window.scrollTo(0, 650)
  }
  }

  previous = () => {
    this.setState(state => ({
      page: Math.max(state.page - 1, 0)
    }))
    if (window.matchMedia("(min-width: 771px)").matches) {
    window.scrollTo(0, 400)
    } else {
    window.scrollTo(0, 650)
  }
  }

  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */

  validate = values => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ]
    return activePage.props.validate ? activePage.props.validate(values) : {}
  }

  onSubmit = async (values) => {

    let vehicleDesignation = () => {
      if (this.props.parentState.veiculoNaoListado || values.veiculoNaoListado) {
        return "2";
      } else {
        return this.props.parentState.vehicleCategoryDesignation;
      }
    }
    let vehicleDescription = () => {
      if (this.props.parentState.veiculoNaoListado || values.veiculoNaoListado) {
        return "MOTOCICLO";
      } else {
        return this.props.parentState.vehicleCategoryDescription;
      }
    }

    let concelhoObj = this.props.parentState.concelho === undefined ? this.props.parentState.localidade : this.props.parentState.concelho;
    let marcaVeiculo = () => {
      if (this.props.parentState.veiculoNaoListado === true) {
        return this.props.parentState.marcaVeiculo;
      } else if (values.veiculoNaoListado === true) {
        return this.props.parentState.marcaVeiculo;
      } else {
        return values.marcaVeiculo;
      }
    }
    let modeloVeiculo = () => {
      if (this.props.parentState.veiculoNaoListado === true) {
        return this.props.parentState.modeloVeiculo;
      } else if (values.veiculoNaoListado === true) {
        return this.props.parentState.modeloVeiculo;
      } else {
        return values.modeloVeiculo;
      }
    }
    let versaoVeiculo = () => {
      if (this.props.parentState.veiculoNaoListado === true) {
        return this.props.parentState.versaoVeiculo;
      } else if (values.veiculoNaoListado === true) {
        return this.props.parentState.versaoVeiculo;
      } else {
        return values.versaoVeiculo;
      }
    }
    // Request made to the backend api
    // Send formData object
    this.setState({ loadingBrands: true}, () => {
    axios( {
      url: "https://api.nacionalgest.pt/api/motos/solicitar_emissao/",
      method: 'POST',
      data: {cliente: {
        nome: values.nomeCompleto,
        morada: values.morada,
        cod_postal: values.codigoPostal,
        localidade: this.props.parentState.localidade,
        concelho: concelhoObj,
        nif: values.nif,
        data_nascimento: values.dataNascimento,
        telemovel: values.telemovel,
        email: values.email,
        data_carta_conducao: values.dataCarta,
        num_carta_conducao: values.numCartaInput,
        cat_carta: values.categoriaCarta,
      },
      veiculo: {
        matricula: values.matricula,
        data_matricula: values.dataMatricula,
        marca: marcaVeiculo(),
        modelo: modeloVeiculo(),
        versao: versaoVeiculo(),
        valor_novo: this.props.parentState.vehicleValorNovo,
        valor_atual: this.props.parentState.vehicleValorAtual,
        cilindrada: this.props.parentState.cilindradaVeiculo,
        chassis: this.props.parentState.chassis,
        categoria_veiculo: vehicleDesignation(),
        desc_categoria_veiculo: vehicleDescription(),
      },
      estado_veiculo: this.props.parentState.estadoVeiculo,
      plano_coberturas: this.props.parentState.planoCoberturas,
      franquia: this.props.parentState.franquia,
      data_inicio_seguro: values.dataInicioSeguro,
      marketing: values.marketing,
      valor_estimado_cliente: this.props.parentState.capitalEstimadoUtilizador,
    },
      headers: {
        'Authorization':`Token f386ee39a5e46a13a460709d0beaf4dd0fff1d90`
       },

    }).then((response) => {
      this.setState({loadingBrands: false});
      if (response.status === 201) {
        this.props.history.push({
          pathname:'/seguros-particulares/motos/obrigado',
      })
    }
  }).catch((error) => {
    this.setState({loadingBrands: false});
    alert('Neste momento não é possível submeter o seu pedido.')
  });
})
  }

  handleSubmit = values  => {
    const { children, onSubmit } = this.props
    const { page } = this.state
    const isLastPage = page === React.Children.count(children) - 1
    if (isLastPage) {
      this.props.onStateChange(values);
      return this.onSubmit(values)
    } else {
      this.next(values)
    }
    this.props.onStateChange(values);
  }



  render() {
    const { children } = this.props
    const { parentState } = this.props.parentState
    const { page, values, loadingBrands } = this.state
    const activePage = React.Children.toArray(children)[page]
    const isLastPage = page === React.Children.count(children) - 1

    var title = "Vamos começar!"

    if (page===1) {
      title = "Preencha os dados da viatura"
    } else if (page===2) {
      title = "As coberturas para a sua moto"
    } else if (page===3) {
      title = "Quanto vai pagar pelo seu seguro"
    }

    return (
      <Form
        initialValues={values}
        parentState={parentState}
        validate={this.validate}
        onSubmit={this.handleSubmit}
      >
        {({ handleSubmit, submitting, values }) => (
          <section className="wrapper bg-light wrapper-border">
            <div className="container pb-10 pt-md-0">
              <div className="card bg-soft-blue mb-10">
                <div className="card-body p-12 pb-0">
                  <div className="row">
                    <div className="col-lg-12 pb-6 align-self-top">
                      <div className="post-category mb-3 text-green">Seguro Motos</div>
                      <h3 className="h1 post-title mb-3">{title}</h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 align-self-end">
          <form onSubmit={handleSubmit}>
            {activePage}
            <div className="row">
              <div className="col-md-12" style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
              {page === 0 && (
                <button type="submit" style={{width:'100%'}} className="btn btn-primary rounded-0 btn-send mb-3">
                  Avançar
                </button>
              )}
              {page > 0 && (
                <button type="button" style={{width:'45%'}} className="btn btn-back rounded-0 btn-send mb-3" onClick={this.previous}>
                  Recuar
                </button>
              )}
              {!isLastPage && (page != 0) && <button type="submit" style={{width:'45%'}} className="btn btn-primary rounded-0 btn-send mb-3">Avançar </button>}
              {isLastPage && (
                <button type="submit" style={{width:'45%'}} className="btn btn-primary rounded-0 btn-send mb-3" disabled={submitting}>
                  Emitir
                </button>
              )}
            </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
{loadingBrands === true && (
  <div className="loadingio-spinner-ellipsis-9w2r0d892j full-spinner"><div className="ldio-o8u2rr79tje">
  <div></div><div></div><div></div><div></div><div></div>
  </div></div>
)}
</section>
        )}

      </Form>
    )
  }
}

export default withRouter(Wizard);
