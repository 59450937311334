import React from 'react';
import ReactDOM from 'react-dom';
import MoreInfoAnimais from './MoreInfoAnimais';


class CoberturasAnimais extends React.Component {
  render() {
    return (
      <>
      <div className="row mb-6">
        <div className="col-md-12">
          <div className="pricing card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover" style={{fontSize:"14px"}}>
                  <thead className="table-light">
                    <tr>
                      <th>Coberturas</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Responsabilidade Civil</th>
                      <td style={{padding:'0.1rem 0.1rem', borderLeft:'1px solid rgb(222 226 230)', textAlign:'center', verticalAlign:'middle'}}>50.000,00€</td>
                    </tr>
                    <tr>
                      <th scope="row">Proteção Jurídica</th>
                      <td style={{padding:'0.1rem 0.1rem', borderLeft:'1px solid rgb(222 226 230)', textAlign:'center', verticalAlign:'middle'}}>3.000,00€</td>
                    </tr>
                    <tr>
                      <th scope="row">Assistência Animal<a data-bs-toggle="modal" data-bs-target="#modal3" style={{paddingLeft: '10px',fontSize: '18px'}}><i className="uil uil-info-circle"></i></a></th>
                      <td style={{padding:'0.1rem 0.1rem', borderLeft:'1px solid rgb(222 226 230)', textAlign:'center', verticalAlign:'middle'}}>Incluído</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="swipe-left-d-none">
                <div className="swipe-left-table">
                  <div className="indicator" style={{textAlign:'center'}}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <p className="mb-0" style={{textAlign:'center'}}>Arraste para o lado para saber mais.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MoreInfoAnimais />
      </>
    )
  }
}

export default CoberturasAnimais;
