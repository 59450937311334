import React from 'react';
import { useFormState } from 'react-final-form';
import axios from 'axios';
import ReactDOM from 'react-dom';
import Footer from '../Footer';
import Header from '../Header';
import RequestContact from '../RequestContact';
import { Form, Field, FormSpy } from 'react-final-form';
import {withRouter} from 'react-router-dom'
import {
  Link
} from "react-router-dom";
import imagemNatal from '../../assets/img/ng-natal.png';
import imagemNatal2 from '../../assets/img/imagem_natal_2023.png';


const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
export const required = value => (value ? undefined : 'Campo Obrigatório')

function Spinner() {
  return (
      <div className="loadingio-spinner-ellipsis-9w2r0d892j full-spinner"><div className="ldio-o8u2rr79tje">
      <div></div><div></div><div></div><div></div><div></div>
      </div></div>
  )
}

class InscricaoJantarNatal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      formSubmitted: false,
      error: null,
    }
  }


  onSubmit = async values => {
    await sleep(300)
    const dev_token = 'f386ee39a5e46a13a460709d0beaf4dd0fff1d90';
    this.setState({loading: true});
    const comp = values.comparencia === "Sim" ? true : false;
    const restri = values.restricoes === "Sim" ? true : false;
    await axios.post(`https://api.nacionalgest.pt/api/eventos/inscricao_jantar_natal/`, {
      nome: values.nome,
      telemovel: values.telemovel,
      email: values.email,
      restricoes: restri,
      tipo_restricoes: values.tipo_restricoes,
      loja: values.loja,
      comparencia: comp,
      pergunta: values.pergunta
    }, {
      headers:{
        'Authorization':`Token ${dev_token}`,
  }}).then((response) => {
    console.log(response.data);
    if (response.status === 200) {
      if (response.data.error) {
        this.setState({
          error:response.data.error,
          loading: false
        })
      } else {
      this.setState({
        loading:false,
        formSubmitted: true
      })
    }
  }
  }).catch((error) => {
    this.setState({
      loading: false,
    });
    alert(error);
  });
  }

  handleSubmit = values  => {
      return this.onSubmit(values)
  }

  render() {


    return (
      <div>
      <div className="content-wrapper">
            <Header />
            {/* /header */}
        		<section className="wrapper bg-light">
              <div className="container">
          			<div className="card shadow-lg mt-5 mb-15 mb-md-14">
          				<div className="row gx-0">
                    <div class="col-12">
                      <figure class="rounded"><img src={imagemNatal2} srcset={`${imagemNatal2} 2x`} alt="" /></figure>
                    <div class="row">
                      <div class="col-xl-10 mx-auto">

                </div>
              </div>
            </div>
        					<div className="col-lg-12">
        						<div className="p-10 p-md-11 p-lg-11">
                    {this.state.error === 1 && (
                      <>
                      <h1>Já respondeu a este formulário!</h1>
                      <p>Uma resposta com este e-mail já foi submetida neste formulário. </p>
                      <p>Se acredita que esta situação possa estar errada, entre em contacto connosco. </p>
                      </>
                    )}
                    {this.state.formSubmitted && (
                      <>
                      <h1>Obrigado por responder!</h1>
                      <p>Mais perto da data voltaremos a entrar em contacto com mais pormenores. </p>
                      </>

                    )}
                    {!this.state.formSubmitted && this.state.error === null && (
                    <Form
                    onSubmit={this.handleSubmit}
                    initialValues={{
                      tipo_relacao: 'Colaborador',
                     }}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-12">
                							<p style={{fontSize:'20px'}} class="text-center">A NacionalGest tem o prazer de o(a) convidar para o jantar de Natal, que se realizará no dia <strong>15 de dezembro, às 19H30, no Convento do Espinheiro, em Évora.</strong></p>
                							<p style={{fontSize:'20px'}} class="text-center">Temos um toque extra de diversão para esta noite! Estamos a organizar um concurso para eleger o melhor Outfit de Natal. É a oportunidade perfeita para mostrarem toda a vossa criatividade e estilo festivo! Portanto, tragam as vossas melhores indumentárias natalícias e deixem a imaginação voar.</p>
                							<p style={{fontSize:'20px'}} class="text-center">A NacionalGest deseja-lhe as Boas Festas!</p>
                            <h6 className="mb-3">Estrutura</h6>
                              <Field id="loja" name="loja">
                                {({ input, meta }) => (
                                  <div className="form-label-group mb-4">
                                    <select {...input} className="form-select" type="text" component="select" required>
                                        <option disabled selected value="">Seleccione uma opção</option>
                                        <option value="dir_corporate">Corporate e Institucionais</option>
                                        <option value="dir_operacoes">Administrativa e Operações</option>
                                        <option value="dir_financeira">Direção Financeira</option>
                                        <option value="dir_informatica">Informática</option>
                                        <option value="dir_marketing">Marketing e Distribuição</option>
                                        <option value="loja_virtual">Loja Virtual e Apoio Interno</option>
                                        <option value="norte">Norte</option>
                                        <option value="beira_alta">Beira Alta</option>
                                        <option value="centro">Centro</option>
                                        <option value="ribatejo">Ribatejo</option>
                                        <option value="lisboa">Grande Lisboa</option>
                                        <option value="alentejo">Alentejo</option>
                                        <option value="algarve">Algarve</option>
                                        <option value="sinistros">Sinistros</option>
                                    </select>
                                    {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                  </div>
                                )}
                              </Field>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <Field id="nome" name="nome" validate={required}>
                              {({ input, meta }) => (
                                <div className="form-label-group mb-4">
                                  <input {...input} className="form-control" type="text" component="input" placeholder="nome" />
                                  <label htmlFor="nome">Nome</label>
                                  {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                </div>
                              )}
                            </Field>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <Field id="email" name="email" validate={required}>
                              {({ input, meta }) => (
                                <div className="form-label-group mb-4">
                                  <input {...input} className="form-control" type="email" component="input" placeholder="E-mail" />
                                  <label htmlFor="email">E-mail</label>
                                  {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                </div>
                              )}
                            </Field>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <Field id="telemovel" name="telemovel" validate={required}>
                              {({ input, meta }) => (
                                <div className="form-label-group mb-4">
                                  <input {...input} className="form-control" type="text" component="input" placeholder="Telemóvel" />
                                  <label htmlFor="telemovel">Telemóvel</label>
                                  {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                </div>
                              )}
                            </Field>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-label-group">
                            <div className="form-check">
                              <label className="form-check-label" htmlFor="comparencia" style={{fontSize: '18px'}}>
                              <Field
                                className="form-check-input"
                                name="comparencia"
                                component="input"
                                type="radio"
                                value="Sim"
                                required
                              />{' '}
                              Vou ao jantar
                            </label>
                            </div>
                            <div className="form-check mb-4">
                              <label className="form-check-label" htmlFor="comparencia" style={{fontSize: '18px'}}>
                              <Field
                                className="form-check-input"
                                name="comparencia"
                                component="input"
                                type="radio"
                                value="Nao"
                                required
                              />{' '}
                              Não vou ao jantar
                            </label>
                            </div>
                          </div>
                        </div>
                        {values.comparencia === "Sim" && (
                          <>
                          <div className="row">
                            <div className="col-md-12">
                              <Field id="pergunta" name="pergunta" validate={required}>
                                {({ input, meta }) => (
                                  <div className="form-label-group mb-4">
                                    <h6 className="mb-3">Se “ganhasse o Euromilhões”, qual seria o sonho que concretizaria no prazo de 24 meses?</h6>
                                    <input {...input} className="form-control" type="text" component="input" placeholder="" />
                                    {meta.error && meta.touched && <div className="help-block with-errors">{meta.error}</div>}
                                  </div>
                                )}
                              </Field>
                            </div>
                          </div>
                        <div className="row">
                          <div className="form-label-group">
                            <h6 className="mb-3">Restrições Alimentares</h6>
                            <div className="form-check">
                              <label className="form-check-label" htmlFor="restricoes" style={{fontSize: '18px'}}>
                              <Field
                                className="form-check-input"
                                name="restricoes"
                                component="input"
                                type="radio"
                                value="Sim"
                                required
                              />{' '}
                              Sim
                            </label>
                            </div>
                            <div className="form-check mb-4">
                              <label className="form-check-label" htmlFor="restricoes" style={{fontSize: '18px'}}>
                              <Field
                                className="form-check-input"
                                name="restricoes"
                                component="input"
                                type="radio"
                                value="Nao"
                                required
                              />{' '}
                              Não
                            </label>
                            </div>
                          </div>
                        </div>
                      </>
                      )}
                      {values.comparencia === "Sim" && values.restricoes === "Sim" && (
                        <div className="row mb-4">
                          <div className="col-md-12">
                          <Field id="tipo_restricoes" name="tipo_restricoes" className="form-control" component="textarea" placeholder="Restrições">
                          </Field>
                        </div>
                        </div>
                      )}
                        <div className="row">
                          <div className="col-md-12" style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <button type="submit" style={{width:'100%'}} className="btn btn-primary rounded-0 btn-send mb-3" disabled={submitting || pristine}>
                              Submeter
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  />)}
              </div>
        					</div>

        				</div>
        			</div>
        		</div>
          </section>
            <RequestContact />
          </div>
          {/* /.content-wrapper */}
          <Footer />
            {this.state.loading && (
              <Spinner />
            )}
          <div className="progress-wrap">
            <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
              <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
            </svg>
          </div>
          </div>
  )
}
}

export default withRouter(InscricaoJantarNatal);
