import React from 'react';
import ReactDOM from 'react-dom';


class Coberturas extends React.Component {
  render() {
    return (
      <div className="row mb-6">
        <div className="col-md-12">
          <div className="pricing card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover" style={{fontSize:"14px"}}>
                  <thead className="table-light">
                    <tr>
                      <th>Coberturas</th>
                      <th>Capital</th>
                      <th>Franquias</th>
                      <th style={{borderLeft:'1px solid'}}>Plano Base</th>
                      <th>Plano Proteção/Plus</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Responsabilidade Civil</th>
                      <td>7.290.000€</td>
                      <td>N.A.</td>
                      <td style={{padding:'0.1rem 0.1rem', borderLeft:'1px solid rgb(222 226 230)', textAlign:'center'}}><i style={{fontSize:'23px'}} className="text-green uil uil-check"></i></td>
                      <td style={{padding:'0.1rem 0.1rem', textAlign:'center'}}><i style={{fontSize:'23px'}} className="text-green uil uil-check"></i></td>
                    </tr>
                    <tr>
                      <th scope="row">Assistência em Viagem</th>
                      <td>Incluída</td>
                      <td>Sem franquia</td>
                      <td style={{padding:'0.1rem 0.1rem', borderLeft:'1px solid rgb(222 226 230)', textAlign:'center'}}><i style={{fontSize:'23px'}} className="text-green uil uil-check"></i></td>
                      <td style={{padding:'0.1rem 0.1rem', textAlign:'center'}}><i style={{fontSize:'23px'}} className="text-green uil uil-check"></i></td>
                    </tr>
                    <tr>
                      <th scope="row">Proteção Jurídica</th>
                      <td>Incluída</td>
                      <td>Sem franquia</td>
                      <td style={{padding:'0.1rem 0.1rem', borderLeft:'1px solid rgb(222 226 230)', textAlign:'center'}}><i style={{fontSize:'23px'}} className="text-green uil uil-check"></i></td>
                      <td style={{padding:'0.1rem 0.1rem', textAlign:'center'}}><i style={{fontSize:'23px'}} className="text-green uil uil-check"></i></td>
                    </tr>
                    <tr>
                      <th scope="row">Choque, Colisão, Capotamento</th>
                      <td>Capital Seguro</td>
                      <td>Sem Franquia ou 10%</td>
                      <td style={{padding:'0.1rem 0.1rem', borderLeft:'1px solid rgb(222 226 230)', textAlign:'center'}}><i style={{fontSize:'23px', color:'gainsboro'}} className="uil uil-minus"></i></td>
                      <td style={{padding:'0.1rem 0.1rem', textAlign:'center'}}><i style={{fontSize:'23px'}} className="text-green uil uil-check"></i></td>
                    </tr>
                    <tr>
                      <th scope="row">Incêndio, Raio ou Explosão</th>
                      <td>Capital Seguro</td>
                      <td>Sem Franquia ou 10%</td>
                      <td style={{padding:'0.1rem 0.1rem', borderLeft:'1px solid rgb(222 226 230)', textAlign:'center'}}><i style={{fontSize:'23px', color:'gainsboro'}} className="uil uil-minus"></i></td>
                      <td style={{padding:'0.1rem 0.1rem', textAlign:'center'}}><i style={{fontSize:'23px'}} className="text-green uil uil-check"></i></td>
                    </tr>
                    <tr>
                      <th scope="row">Furto ou Roubo</th>
                      <td>Capital Seguro</td>
                      <td>Sem Franquia ou 10%</td>
                      <td style={{padding:'0.1rem 0.1rem', borderLeft:'1px solid rgb(222 226 230)', textAlign:'center'}}><i style={{fontSize:'23px', color:'gainsboro'}} className="uil uil-minus"></i></td>
                      <td style={{padding:'0.1rem 0.1rem', textAlign:'center'}}><i style={{fontSize:'23px'}} className="text-green uil uil-check"></i></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="swipe-left-d-none">
                <div className="swipe-left-table">
                  <div className="indicator" style={{textAlign:'center'}}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <p className="mb-0" style={{textAlign:'center'}}>Arraste para o lado para saber mais.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Coberturas;
