import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Footer from '../Footer';
import Header from '../Header';

import seguroImage from '../../assets/img/ng/particulares.jpeg';
import seguroImageEmpresa from '../../assets/img/ng/empresas.jpeg';
import {
  Link
} from "react-router-dom";

class BaseParceiros extends Component {

  render() {
    return (
    <div>
        <section className="wrapper bg-light wrapper-border">
    			<div className="container py-8 pt-md-2">
    				<div className="card bg-soft-primary mb-10">
    					<div className="card-body p-12 pb-0">
    						<div className="row" style={{display:'flex', justifyContent: 'space-around'}}>
    							<div className="col-lg-5 pb-12">
    								<div className="post-category mt-2 mb-3 text-green">Seguros</div>
    								<h3 className="h1 post-title mb-3">Simuladores</h3>
    								<p>Conheça as soluções de seguros particulares que temos para o proteger. </p>
    								<Link to="parceiros/simuladores/" className="more hover link-green">Ver Produtos</Link>
    							</div>
                  <div className="col-lg-5 offset-lg-1">
                      <div className="post-category mt-2 mb-3 text-green">Clientes</div>
      								<h3 className="h1 post-title mb-3">Notificar Clientes</h3>
      								<p>Enviar email RGPD, Segmentação ou NPS diretamente para o seu cliente.</p>
      								<Link to="parceiros/notificacoes/" className="more hover link-green">Ver Mais</Link>
    							</div>
    						</div>
    					</div>
    				</div>
    			</div>
    		</section>
    </div>
    )
  }
}

export default BaseParceiros;
