import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import LoginParceiros from '../LoginParceiros';
import HeaderParceiros from '../HeaderParceiros';
import FooterParceiros from '../FooterParceiros';
import ReactDOM from 'react-dom';


import surfAP from '../../../assets/img/seguros/surf-ap.jpg';
import surfRC from '../../../assets/img/seguros/surf-rc.jpg';
import agenciaViagens from '../../../assets/img/seguros/agencia-viagens.jpg';
import credito from '../../../assets/img/seguros/seguro-credito.jpeg';
import imobiliarias from '../../../assets/img/seguros/seguro-imobiliarias.jpeg';

import {
  Link
} from "react-router-dom";


class SegurosEmpresasParceiros extends Component {

  getToken() {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token
  }
  getNomeParceiro() {
    const nomeParceiro = sessionStorage.getItem('nomeParceiro');
    return nomeParceiro
  }
  getEmailParceiro() {
    const emailParceiro = sessionStorage.getItem('emailParceiro');
    return emailParceiro
  }

  render() {

    const nomeParceiro = this.getNomeParceiro();
    const emailParceiro = this.getEmailParceiro();
    const token = sessionStorage.getItem('token');
    if(!token) {
      return <Redirect to="/parceiros" />
    }
    // OBTER DETALHES PARCEIRO ATRAVES DO TOKEN DE AUTH
    if(token) {
    return (
      <div>
      <HeaderParceiros
        nomeParceiro={nomeParceiro}
        emailParceiro={emailParceiro}
      />
        <section className="wrapper">
    			<div className="container pt-8 pb-10 pt-md-14 pb-md-14 text-center">
    				<div className="row">
    					<div className="col-lg-8 mx-auto">
                <h1 className="display-5 mb-3 text-green">Parceiros</h1>
                <h4>{nomeParceiro} - {emailParceiro}</h4>
                <h1 className="display-2">Seguros Empresas</h1>
    					</div>
    				</div>
    			</div>
    		</section>
      <div className="content-wrapper">
    		<section className="wrapper">
    			<div className="container py-14 pt-md-8 pt-5 pb-md-16">
            <div className="row grid-view gx-md-8 gx-xl-10 gy-8 gy-lg-0 mb-md-10" style={{justifyContent:'center'}}>
    					<div className="col-md-4 col-lg-4">
    						<div className="position-relative">
    							<div className="card">
                    <figure className="card-img-top overlay overlay1 hover-scale"><Link to={{ pathname: `/parceiros/empresas/surf/ap`, state: {token:this.props.token, nomeParceiro: this.props.nomeParceiro, emailParceiro: this.props.emailParceiro}}}><img src={surfAP} alt="" /></Link>
                      <figcaption>
                        <h5 className="from-top mb-0">Simular</h5>
                      </figcaption>
                    </figure>
    								<div className="card-body px-6 py-5">
    									<h4 className="mb-1">Acidentes Pessoais Escolas de Surf</h4>
    									<p className="mb-0">Garante os riscos de acidente a todas as Pessoas Seguras utentes das pranchas de Surf do Tomador do Seguro.</p>
    								</div>
                    <div id="simular-button" className="p-0 card-footer">
											<Link to={{ pathname: `/parceiros/empresas/surf/ap`, state: {token:this.props.token, nomeParceiro: this.props.nomeParceiro, emailParceiro: this.props.emailParceiro}}} className="btn btn-sm btn-primary rounded-0">Simular</Link>
										</div>
    							</div>
    						</div>
    					</div>
    					<div className="col-md-4 col-lg-4">
    						<div className="position-relative">
    							<div className="card">
                    <figure className="card-img-top overlay overlay1 hover-scale"><Link to={{ pathname: `/parceiros/empresas/surf/rc`, state: {token:this.props.token, nomeParceiro: this.props.nomeParceiro, emailParceiro: this.props.emailParceiro}}}><img src={surfRC} alt="" /></Link>
                      <figcaption>
                        <h5 className="from-top mb-0">Simular</h5>
                      </figcaption>
                    </figure>
    								<div className="card-body px-6 py-5">
    									<h4 className="mb-1">Responsabilidade Civil Escolas de Surf</h4>
    									<p className="mb-0">Garante os Danos causados pelas pranchas utilizadas, danos causados a terceiros e a embarcações.</p>
    								</div>
                    <div id="simular-button" className="p-0 card-footer">
											<Link to={{ pathname: `/parceiros/empresas/surf/rc`, state: {token:this.props.token, nomeParceiro: this.props.nomeParceiro, emailParceiro: this.props.emailParceiro}}} className="btn btn-sm btn-primary rounded-0">Simular</Link>
										</div>
    							</div>
    						</div>
    					</div>
              <div className="col-md-4 col-lg-4">
                <div className="position-relative">
                  <div className="card">
                    <figure className="card-img-top overlay overlay1 hover-scale"><Link to="/parceiros/empresas/rc/agencia-viagens"> <img src={agenciaViagens} alt="Seguro Saúde" /></Link>
                      <figcaption>
                        <h5 className="from-top mb-0">Simular</h5>
                      </figcaption>
                    </figure>
                    <div className="card-body px-6 py-5">
                      <h4 className="mb-1">Responsabilidade Civil Agências de Viagens</h4>
                        <p className="mb-0">Garante a responsabilidade civil no âmbito da atividade nos termos da legislação aplicável.</p>
                    </div>
                    <div id="simular-button" className="p-0 card-footer">
                      <Link to="/parceiros/empresas/rc/agencia-viagens" className="btn btn-sm btn-primary rounded-0">Simular</Link>
                    </div>
                  </div>
                </div>
              </div>
    				</div>
            <div className="row grid-view gx-md-8 gx-xl-10 gy-8 gy-lg-0 mb-md-10" style={{justifyContent:'center'}}>
    					<div className="col-md-4 col-lg-4">
    						<div className="position-relative">
    							<div className="card">
                    <figure className="card-img-top overlay overlay1 hover-scale"><Link to={{ pathname: `/parceiros/empresas/rc/intermediarios-credito`, state: {token:this.props.token, nomeParceiro: this.props.nomeParceiro, emailParceiro: this.props.emailParceiro}}}><img src={credito} alt="" /></Link>
                      <figcaption>
                        <h5 className="from-top mb-0">Simular</h5>
                      </figcaption>
                    </figure>
    								<div className="card-body px-6 py-5">
    									<h4 className="mb-1">Intermediários de Crédito</h4>
    									<p className="mb-0">Um seguro adequado a todos aqueles que exercem a atividade como Intermediários de Crédito.</p>
    								</div>
                    <div id="simular-button" className="p-0 card-footer">
											<Link to={{ pathname: `/parceiros/empresas/rc/intermediarios-credito`, state: {token:this.props.token, nomeParceiro: this.props.nomeParceiro, emailParceiro: this.props.emailParceiro}}} className="btn btn-sm btn-primary rounded-0">Simular</Link>
										</div>
    							</div>
    						</div>
    					</div>
    					<div className="col-md-4 col-lg-4">
    						<div className="position-relative">
    							<div className="card">
                    <figure className="card-img-top overlay overlay1 hover-scale"><Link to={{ pathname: `/parceiros/empresas/rc/imobiliarias`, state: {token:this.props.token, nomeParceiro: this.props.nomeParceiro, emailParceiro: this.props.emailParceiro}}}><img src={imobiliarias} alt="" /></Link>
                      <figcaption>
                        <h5 className="from-top mb-0">Simular</h5>
                      </figcaption>
                    </figure>
    								<div className="card-body px-6 py-5">
    									<h4 className="mb-1">Responsabilidade Civil Mediadoras Imobiliárias</h4>
    									<p className="mb-0">Um seguro obrigatório para todos os que exercem a atividade como Mediadoras Imobiliárias.</p>
    								</div>
                    <div id="simular-button" className="p-0 card-footer">
											<Link to={{ pathname: `/parceiros/empresas/rc/imobiliarias`, state: {token:this.props.token, nomeParceiro: this.props.nomeParceiro, emailParceiro: this.props.emailParceiro}}} className="btn btn-sm btn-primary rounded-0">Simular</Link>
										</div>
    							</div>
    						</div>
    					</div>
    				</div>
		     </div>
       </section>
     </div>
     <FooterParceiros />
   </div>
    )
  }
  }
}
export default SegurosEmpresasParceiros;
