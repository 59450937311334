import React from 'react';
import ReactDOM from 'react-dom';
import MoreInfo from './MoreInfo';


class CoberturasAPSurf extends React.Component {
  render() {
    return (
      <>
      <div className="row mb-6">
        <div className="col-md-12">
          <div className="pricing card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover" style={{fontSize:"14px"}}>
                  <thead className="table-light">
                    <tr>
                      <th>Coberturas</th>
                      <th>Capital</th>
                      <th>Franquia</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Morte e Invalidez Permanente</th>
                      <td style={{padding:'0.1rem 0.1rem', verticalAlign:'middle', textAlign:'center'}}>28.034,76€</td>
                      <td style={{padding:'0.1rem 0.1rem', verticalAlign:'middle', textAlign:'center'}}><i style={{fontSize:'23px'}} className="text-green uil uil-minus"></i></td>
                    </tr>
                    <tr>
                      <th scope="row">Despesas de Tratamento</th>
                      <td style={{padding:'0.1rem 0.1rem', verticalAlign:'middle', textAlign:'center'}}>3.385,05€</td>
                      <td style={{padding:'0.1rem 0.1rem', verticalAlign:'middle', textAlign:'center'}}>60€</td>
                    </tr>
                    <tr>
                      <th scope="row">Despesas de Funeral</th>
                      <td style={{padding:'0.1rem 0.1rem', verticalAlign:'middle', textAlign:'center'}}>5.000€</td>
                      <td style={{padding:'0.1rem 0.1rem', verticalAlign:'middle', textAlign:'center'}}><i style={{fontSize:'23px'}} className="text-green uil uil-minus"></i></td>
                    </tr>
                    <tr>
                      <th scope="row">Despesas com Operações de Salvação, Busca e Transporte</th>
                      <td style={{padding:'0.1rem 0.1rem', verticalAlign:'middle', textAlign:'center'}}>1.000€</td>
                      <td style={{padding:'0.1rem 0.1rem', verticalAlign:'middle', textAlign:'center'}}><i style={{fontSize:'23px'}} className="text-green uil uil-minus"></i></td>
                    </tr>
                    <tr>
                      <th scope="row">Morte Simultânea da Pessoa Segura e Cônjuge</th>
                      <td style={{padding:'0.1rem 0.1rem', verticalAlign:'middle', textAlign:'center'}}>15.000€</td>
                      <td style={{padding:'0.1rem 0.1rem', verticalAlign:'middle', textAlign:'center'}}><i style={{fontSize:'23px'}} className="text-green uil uil-minus"></i></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="swipe-left-d-none">
                <div className="swipe-left-table">
                  <div className="indicator" style={{textAlign:'center'}}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <p className="mb-0" style={{textAlign:'center'}}>Arraste para o lado para saber mais.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MoreInfo />
      </>
    )
  }
}

export default CoberturasAPSurf;
