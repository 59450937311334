import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import HeaderParceiros from '../../HeaderParceiros';
import FooterParceiros from '../../FooterParceiros';


class ParceirosCacadorSucesso extends React.Component {

  componentDidMount() {
    window.scrollTo(0,0);
  }

  getNomeParceiro() {
    const nomeParceiro = sessionStorage.getItem('nomeParceiro');
    return nomeParceiro
  }

  render() {
  const nomeParceiro = this.getNomeParceiro();
  return (
    <div>
    <div className="content-wrapper">
        <HeaderParceiros />
          <section className="wrapper bg-gray">
  			<div className="container pt-12 pt-md-14 pb-14 pb-md-16">
  				<div className="row gy-10 gy-md-13 gy-lg-0 align-items-center">
  					<div className="col-md-8 col-lg-5 d-flex position-relative mx-auto">
              <div className="img-blob">
              <div className="post-category mb-3 text-green">PARCEIROS - {nomeParceiro}</div>
                <h3 className="mb-0 text-nowrap">Responsabilidade Civil Caçador</h3>
            </div>
  					</div>
            <div className="col-lg-6 offset-lg-1 col-xxl-5 text-center text-lg-start">
  						<h1 className="display-2 mb-5">Obrigado pelo seu pedido de simulação.</h1>
  						<p className="lh-sm mb-7 px-md-10 px-lg-0">Estamos atentos ao seu pedido.<br />Entraremos em contacto brevemente.</p>
  					</div>
  				</div>
  			</div>
  		</section>
    </div>
    {/* /.content-wrapper */}
    <FooterParceiros />
    </div>
  )
}
}

export default ParceirosCacadorSucesso;
