import React from 'react';
import ReactDOM from 'react-dom';


class CoberturasAP extends React.Component {
  render() {
    return (
      <div className="row mb-6">
        <div className="col-md-12">
          <div className="pricing card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover" style={{fontSize:"14px"}}>
                  <thead className="table-light">
                    <tr>
                      <th>Coberturas</th>
                      <th>Capital</th>
                      <th>Franquias</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Morte ou Invalidez Permanente</th>
                      <td>30.000,00€</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <th scope="row">Despesas de Tratamento</th>
                      <td>5.000,00€</td>
                      <td>100€</td>
                    </tr>
                    <tr>
                      <th scope="row">Despesas de Funeral</th>
                      <td>2.500,00€</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <th scope="row">Responsabilidade Civil Familiar</th>
                      <td>10.000,00€</td>
                      <td>50€</td>
                    </tr>
                    <tr>
                      <th scope="row">Assistência Atividades Desportivas</th>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="swipe-left-d-none">
                <div className="swipe-left-table">
                  <div className="indicator" style={{textAlign:'center'}}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <p className="mb-0" style={{textAlign:'center'}}>Arraste para o lado para saber mais.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CoberturasAP;
