import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import HeaderParceiros from '../../HeaderParceiros';
import FooterParceiros from '../../FooterParceiros';
import RequestContact from '../../../RequestContact';
import seguroImage from '../../../../assets/img/seguros/viagem-obrigado.jpeg';


class ParceirosThanksPredictable extends React.Component {

  componentDidMount() {
    window.scrollTo(0,0);
  }

  render() {
  return (
    <div>
    <div className="content-wrapper">
        <HeaderParceiros />
          <section className="wrapper bg-gray">
  			<div className="container pt-12 pt-md-14 pb-14 pb-md-16">
  				<div className="row gy-10 gy-md-13 gy-lg-0 align-items-center">
  					<div className="col-md-8 col-lg-5 d-flex position-relative mx-auto">
  						<div className="img-blob"><img src={seguroImage} srcSet={{seguroImage} + " 2x"} alt="" /></div>
  						<div className="card shadow-lg position-absolute" style={{bottom: "10%", right: "2%"}}>
  							<div className="card-body py-3 px-4">
  								<div className="d-flex flex-row align-items-center">
  									<div>
  										<i className="uil uil-check-circle svg-inject icon-svg icon-svg-sm text-primary mx-auto me-3" style={{fontSize:"25px"}} alt="" />
  									</div>
  									<div>
  										<h4 className="mb-0 text-nowrap">Seguro de Viagem</h4>
  										<p className="fs-14 lh-sm mb-0 text-nowrap">Loja Online</p>
  									</div>
  								</div>
  							</div>
  						</div>
  					</div>
  					<div className="col-lg-6 offset-lg-1 col-xxl-5 text-center text-lg-start">
  						<h1 className="display-1 mb-5">Obrigado por confiar na NacionalGest.</h1>
  						<p className="lh-sm mb-7 px-md-10 px-lg-0">A sua apólice foi emitida e receberá em breve toda a documentação no seu email. <br />Visite o nosso website para conhecer outras soluções que temos para si.</p>
  						<div className="d-flex justify-content-center justify-content-lg-start">
  							<span><a href="https://www.nacionalgest.pt" className="btn btn-lg btn-primary me-2 rounded-0">Visitar Website</a></span>
  						</div>
  					</div>
  				</div>
  			</div>
  		</section>
      <RequestContact />
    </div>
    {/* /.content-wrapper */}
    <FooterParceiros />
    <div className="progress-wrap">
      <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
      </svg>
    </div>
    </div>
  )
}
}

export default ParceirosThanksPredictable;
