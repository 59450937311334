import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import Header from '../../HeaderParceiros';
import Footer from '../../FooterParceiros';
import RequestContact from '../../../RequestContact';
import seguroImage from '../../../../assets/img/seguros/viagem-obrigado.jpeg';


class FailPredictable extends React.Component {

  componentDidMount() {
    window.scrollTo(0,0);
  }

  render() {
  return (
    <div>
    <div className="content-wrapper">
        <Header />
          <section className="wrapper bg-gray">
  			<div className="container pt-12 pt-md-14 pb-14 pb-md-16">
          <div className="container pt-10 pt-md-14 text-center">
        <div className="row">
          <div className="col-xl-6 mx-auto">
            <h1 className="display-1 mb-4">Oops, não foi possível processar o seu pagamento.</h1>
          </div>
          <div className="col-xl-8 mx-auto">
            <p className="lead fs-lg mb-0">Não se preocupe. Se teve dificuldade em emitir o seu seguro de viagem, iremos <nobr>ajudá-lo(a)</nobr> neste processo.</p>
            <p className="lead fs-lg mb-0">Pode contactar-nos através de um dos contactos abaixo ou solicitar contacto, <a href="" data-bs-toggle="modal" data-bs-target="#modal-03">aqui.</a></p>
          </div>
          {/* /column */}
        </div>
        <div className="row mt-12">
          <div className="col-xl-8 mx-auto">
            <div className="row">
          <div className="col-lg-6">
            <div class="d-flex flex-row" style={{justifyContent: 'center'}}>
							<div>
								<div class="icon text-primary fs-28 me-4 mt-n1"> <i class="uil uil-phone-volume"></i> </div>
							</div>
							<div>
								<h5 class="mb-1">Telefone</h5>
								<p><a class="link-body" href="tel:+351211341202">+351 211 341 202</a><br class="d-none d-md-block" /><a class="link-body" href="tel:+351913337160">+351 913 337 160</a></p>
							</div>
						</div>
          </div>
          <div className="col-lg-6">
            <div class="d-flex flex-row" style={{justifyContent: 'center'}}>
              <div>
                <div class="icon text-primary fs-28 me-4 mt-n1"> <i class="uil uil-envelope"></i> </div>
              </div>
              <div>
                <h5 class="mb-1">E-mail</h5>
                <p class="mb-0"><a href="mailto:apoio.cliente@nacionalgest.pt" class="link-body">apoio.cliente@nacionalgest.pt</a></p>
              </div>
            </div>
          </div>
          </div>
          </div>
        </div>
        {/* /.row */}
      </div>
  			</div>
  		</section>
      <RequestContact />
    </div>
    {/* /.content-wrapper */}
    <Footer />
    <div className="progress-wrap">
      <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
      </svg>
    </div>
    </div>
  )
}
}

export default FailPredictable;
